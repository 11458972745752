<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-title>Transactions</v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedCampaignId"
              :items="campaigns"
              item-text="title"
              item-value="id"
              item-color="donorAccent1"
              label="Select a campaign"
              color="donorAccent1"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="selectedCollectionPointId"
              :items="campaignsCollectionPoints"
              item-text="title"
              item-value="id"
              item-color="donorAccent1"
              label="Select a collection point"
              color="donorAccent1"
              :disabled="!selectedCampaignId"
            />
          </v-col>
          <v-col>
            <v-menu
              v-model="isCalendarDisplayed"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeTextInPicker"
                  label="Select a date range"
                  prepend-icon="event"
                  color="donorAccent1"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateRangeInPicker"
                range
                :max="latestDateAllowed"
                color="donorAccent1"
              >
                <v-spacer />
                <v-btn
                  text
                  color="donorAccent1"
                  @click="isCalendarDisplayed = false"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :items-per-page="10"
              item-key="campainId"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-btn
                    color="donorAccent1 white--text"
                    @click="fetchTransactions"
                  >
                    Fetch
                  </v-btn>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <div
                    v-if="transactions.length"
                    class="d-flex flex-row"
                  >
                    <v-toolbar-title>
                      Totals
                    </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-toolbar-title> Gross: ${{ gross }} </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-toolbar-title>
                      Stripe Fee: ${{ stripeFee }}
                    </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-toolbar-title>
                      Giv2 Fee: ${{ giv2Fee }}
                    </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-toolbar-title> Net: ${{ net }} </v-toolbar-title>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.displayName="{ item }">
                <div>{{ decodeHtml(item.displayName) }}</div>
              </template>
            </v-data-table>
            <v-btn
              v-if="transactions.length"
              :href="encodedUri"
              :download="dateRangeTextInPicker"
              color="donorAccent1 white--text"
              class="mt-6"
            >
              Download CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "Reports",
  data() {
    return {
      loading: false,
      isCalendarDisplayed: false,
      latestDateAllowed: new Date(new Date().getTime() + 60 * 60 * 48 * 1000)
        .toISOString()
        .substr(0, 10),
      dateRangeInPicker: [],
      campaigns: [],
      selectedCampaignId: "",
      collectionPoints: [],
      selectedCollectionPointId: "",
      transactions: [],
      headers: [
        { text: "Campaign", align: "start", value: "campaignName" },
        {
          text: "Collection Point",
          align: "start",
          value: "collectionPointName",
        },
        { text: "Int. ID", align: "start", value: "internalIdentifier" },
        { text: "Gross/$", align: "start", value: "amountPaid" },
        { text: "Stripe Fee/$", align: "start", value: "transactionFee" },
        { text: "Giv2 Fee/$", align: "start", value: "giv2TransactionFee" },
        { text: "Net/$", align: "start", value: "amountDonated" },
        { text: "Type", align: "start", value: "paymentType" },
        { text: "Date", align: "start", value: "date" },
        { text: "Time", align: "start", value: "time" },
        { text: "Giver", align: "start", value: "displayName" },
        { text: "Donor Number", align: "start", value: "donorNumber" },
        { text: "Email", align: "start", value: "email" },
      ],
      gross: 0,
      stripeFee: 0,
      giv2Fee: 0,
      net: 0,
    };
  },
  computed: {
    campaignsCollectionPoints() {
      const collectionPoints = [{ id: "", title: "All" }];
      this.collectionPoints.forEach((cp) => {
        if (cp.campaignId === this.selectedCampaignId)
          collectionPoints.push(cp);
      });
      if (collectionPoints.length === 1)
        collectionPoints[0].title = "No collection points";
      return collectionPoints;
    },
    dateRangeTextInPicker() {
      const range = this.dateRangeInPicker;
      range.sort((first, second) => new Date(first) - new Date(second));
      return range.join(" – ");
    },
    encodedUri() {
      const transactionTable = [
        [
          "Campaign",
          "Collection Point",
          "Int. ID",
          "Gross/$",
          "Stripe Fee/$",
          "Giv2 Fee/$",
          "Net/$",
          "Type",
          "Date",
          "Time",
          "Giver",
          "Donor Number",
          "Email",
        ],
      ];
      this.transactions.forEach((transaction) => {
        const record = [
          transaction.campaignName,
          transaction.collectionPointName,
          transaction.internalIdentifier,
          transaction.amountPaid,
          transaction.transactionFee,
          transaction.giv2TransactionFee,
          transaction.amountDonated,
          transaction.paymentType,
          transaction.date,
          transaction.time,
          this.decodeHtml(transaction.displayName),
          transaction.donorNumber,
          transaction.email,
        ];
        transactionTable.push(record);
      });
      const metadata = "data:text/csv;charset=utf-8,";
      const records = transactionTable.map((e) => e.join(",")).join("\n");
      const totals = `\nTOTAL,,,${this.gross},${this.stripeFee},${this.giv2Fee},${this.net}`;
      const csvContent = metadata + records + totals;
      return encodeURI(csvContent);
    },
  },
  created() {
    this.fetchCampaignsAndCollectionPoints();
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    fetchCampaignsAndCollectionPoints() {
      this.loading = true;
      const getCampaignsAndCollectionPoints = firebase
        .functions()
        .httpsCallable("client-getCampaignsAndCollectionPoints");
      getCampaignsAndCollectionPoints({})
        .then((result) => {
          if (result.data) {
            this.campaigns = [{ id: "", title: "All" }].concat(
              result.data.campaigns
            );
            this.collectionPoints = result.data.collectionPoints;
          }
        })
        .catch((error) =>
          console.log(
            "--Error fetching client's campaigns including collection points--",
            error
          )
        )
        .finally(() => (this.loading = false));
    },
    fetchTransactions() {
      let start, end; // the starting and ending timestamps in seconds
      if (this.dateRangeInPicker.length < 2) {
        return alert(
          "Please select two dates for a range or a date twice for a specific date."
        );
      } else {
        const range = this.dateRangeInPicker.map((date) => {
          const dateLocal = new Date(date);
          const utc =
            dateLocal.getTime() / 1000 + dateLocal.getTimezoneOffset() * 60; // UTC time in seconds
          return utc;
        });
        range.sort((a, b) => a - b);
        start = range[0];
        end = range[1] + 60 * 60 * 24;
      }
      const campaignId = this.selectedCampaignId;
      const collectionPointId = campaignId && this.selectedCollectionPointId; // make sure no CP ID when no campaign is selected
      this.loading = true;
      const getTransactions = firebase
        .functions()
        .httpsCallable("client-getClientsOwnTransactions");
      getTransactions({ campaignId, collectionPointId, start, end })
        .then((result) => {
          console.log(JSON.stringify(result.data));
          if (result && result.data) {
            let gross = 0;
            let stripeFee = 0;
            let giv2Fee = 0;
            let net = 0;
            const transactions = result.data.map((transaction) => {
              const {
                amountPaid,
                transactionFee,
                giv2TransactionFee,
                amountDonated,
              } = transaction;
              transaction.amountPaid = (amountPaid / 100).toFixed(2);
              transaction.transactionFee = (transactionFee / 100).toFixed(2);
              transaction.giv2TransactionFee = giv2TransactionFee
                ? (giv2TransactionFee / 100).toFixed(2)
                : "0";
              transaction.amountDonated = (amountDonated / 100).toFixed(2);
              gross += amountPaid / 100;
              stripeFee += transactionFee / 100;
              giv2Fee += giv2TransactionFee ? giv2TransactionFee / 100 : 0;
              net += amountDonated / 100;
              return transaction;
            });
            this.transactions = transactions;
            this.gross = gross.toFixed(2);
            this.stripeFee = stripeFee.toFixed(2);
            this.giv2Fee = giv2Fee.toFixed(2);
            this.net = net.toFixed(2);
          }
        })
        .catch((error) =>
          console.log("--Error fetching client's transactions--", error)
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
