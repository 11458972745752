<template>
  <v-row justify="center">
    <v-dialog v-model="isShowDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="donorAccent1 white--text">
          <v-btn icon dark @click="isShowDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cropping</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click=cropImage>Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div>
          <div class="upload-example" >
            <Cropper
              ref="cropper"
              class="upload-example-cropper"
              :src="image"
              :stencil-props="{
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 2,
              }"
              image-restriction="stencil"
              @change="onCropperChange"/>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { Cropper } from "vue-advanced-cropper";

  export default {
    name: "ImageCropperPopup",
    components: {
      Cropper
    },
    data() {
      return {
        isShowDialog: false,
        image: null,
        imageName: "",
        canvas: "",
      };
    },
    methods: {
      onCropperChange({canvas}) {
        this.canvas = canvas
      },
      cropImage() {
        let self = this
        if(this.canvas) {
          this.canvas.toBlob(blob => {
            let data = {
              blob: blob,
              imageName: self.imageName
            }
            self.$emit('cropImage', data);
          }, 'image/jpeg');
        }
      },
      show(image, imageName, isShowDialog) {
        this.image = image
        this.imageName = imageName
        this.isShowDialog = isShowDialog
      },
    }
  };
</script>

<style>
  .upload-example-cropper {
    border: solid 1px #EEE;
    min-height: 300px;
    width: 100%
  }

  .button input {
    display: none;
  }
</style>

