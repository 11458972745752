import firebase from 'firebase/app';
import 'firebase/storage';

let fileStorage = {
  uploadImage(data, callback) {
    if(!data) {
      callback({message:"Please select an image file"}, "")
      return
    }
    const storageRef = firebase.storage().ref(`images/${data.name}`).put(data);
    storageRef.on(firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            break;
          case firebase.storage.TaskState.RUNNING:
            break;
        }
      }, error => {
        // https://firebase.google.com/docs/storage/web/handle-errors
        callback(error, "")
      }, () => {
        storageRef.snapshot.ref.getDownloadURL().then(downloadURL => {
          callback(null, downloadURL)
        });
      });
  },
  uploadImageByBlobUrl(blob, imageName, callback) {
    console.log("this is uploadImageByBlobUrl")
    imageName = imageName || Date.now() + ""
    const storageRef = firebase.storage().ref(`images/${imageName}`).put(blob)
    storageRef.then(() => {
      storageRef.snapshot.ref.getDownloadURL().then(downloadURL => {
        callback(null, downloadURL)
      }).catch(error=>{
        callback(error, "error occur during getDownloadUrl")
      });
    }).catch(error=> {
      callback(error, "error occur during upload image")
    })
  },
  removeImage(url, callback) {
    firebase.storage().refFromURL(url).delete().then(() => {
      // File deleted successfully
      callback(null, {message : "remove image successfully"})
    }).catch( error => {
      // Uh-oh, an error occurred!
      callback(error, null)
    });
  }
}
export default fileStorage