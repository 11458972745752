<template>
  <v-container class="pa-0">

    <div class="image_container">
      <v-img
        class="background"
        :src="stripeImage"
      />
    </div>
    <v-divider/>
    <v-subheader>My Credit Card</v-subheader>

    <StripeUI
      ref="stripeUI"
    />

    <v-card-text class="pt-0 mb-10">
      <v-btn
        class="float-right"
        color="donorAccent1 white--text"
        :disabled="buttonDisabled"
        @click="onClick"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-text>
    <div class="image_container">
      <v-img
        class="background"
        :src="powerByStripeImage"
      />
    </div>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="donorAccent1 white--text"
      />
    </v-overlay>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import cloudFunction from '../../helpers/cloudFunction.js'
  import cardControl from "../../store/firebase/cardControl";
  import {snackbarConstant} from "../../constant/snackbarConstant";
  import StripeUI from "../../components/StripeUI";
  import {paymentMethodsFields} from "../../constant/firebaseConstants"

  export default {
    components : {
      StripeUI
    },
    data() {
      return {
        isEditing: null,
        imageUrl: null,
        picture: '',
        orangeColorStyle: 'orange accent-4 white--text',
        buttonDisabled: false,
        stripeOptions: {},
        buttonText: '',
        ADD_CARD: 'Add Card',
        DELETE_CARD: 'Delete Card',
        SAVE_CARD: 'Save Card',
        overlay: true,
        unAuthenticatedInfo : null,
        powerByStripeImage: require('@/assets/stripe_black.png'),
        stripeImage: require('@/assets/stripe_blurple.svg')
      }
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
        userProfile: 'userProfile'
      })
    },
    mounted() {
      this.getClientSecret()
      this.cardSetupInit()
    },
    beforeDestroy() {
      try {
        this.user.data.navGoBack = false
        this.paymentsList = []
      } catch (e) {
        console.log('beforeDestory e ' + e)
      }
    },
    methods: {
      onClick() {
        if (this.loading) {
          return
        }
        if (this.buttonText === this.SAVE_CARD) {
          this.saveCard()
          this.$analytics.logEvent("select_item", {item_list_name : "save_card"})
        }

        if (this.buttonText === this.ADD_CARD) {
          this.cardSetupInit()
          this.$analytics.logEvent("select_item", {item_list_name : "add_card"})
        }

        if (this.buttonText === this.DELETE_CARD) {
          this.removeCard()
          this.$analytics.logEvent("select_item", {item_list_name : "delete_card"})
        }
        this.getClientSecret()
      },
      saveCardInfoToFirebase(payment_method, last4, exp_month, exp_year) {
        let self = this
        let cardData = {}
        cardData[paymentMethodsFields.ID] = payment_method
        cardData[paymentMethodsFields.CARD_NUMBER] = "************" + last4
        cardData[paymentMethodsFields.EXPIRY_MONTH] = exp_month
        cardData[paymentMethodsFields.EXPIRY_YEAR] = exp_year
        cardData[paymentMethodsFields.PLATFORM_ID] = 0

        cardControl.saveCardForStripe(cardData, self.user.data.id, () => {
          self.$store.dispatch('pushSnackbarInfo', {text: snackbarConstant.message.CARD_SETUP_SUCCESS, color: snackbarConstant.color.SUCCESS, isShow: true})
          if(self.unAuthenticatedInfo) {
            self.$router.push(`/donor/result/${self.unAuthenticatedInfo.qrCode}`)
          } else {
            if (self.user.data.navGoBack) {
              self.$router.back()
            } else {
              self.changeLoadingState(false)
              self.$router.go(0)
            }
          }
        })
      },
      callSavePaymentMethodId(payment_method) {
        let self = this
        self.$refs.stripeUI.createToken((last4, exp_month, exp_year)=>{
          self.saveCardInfoToFirebase(payment_method, last4, exp_month, exp_year)
        })
      },
      getClientSecret() {
        let self = this
        cloudFunction.getClientSecretForCardSetup((client_secret) => {
          self.$refs.stripeUI.getClientSecret(client_secret)
          self.changeLoadingState(false)
        })
      },
      changeLoadingState(isLoading) {
        this.overlay = isLoading
      },
      saveCard() {
        let self = this
        self.changeLoadingState(true)
        self.$refs.stripeUI.confirmCardSetup((result)=>{
          if (result.error) {//result.error.code and result.error.message
            self.changeLoadingState(false)
            self.$store.dispatch('pushSnackbarInfo', {text: snackbarConstant.message.CARD_SETUP_ERROR, color: snackbarConstant.color.ERROR, isShow: true})
            self.$analytics.logEvent("failed_payment_info", { payment_type: "credit_card", fatal: true, description: result.error.message })
          } else {
            self.callSavePaymentMethodId(result.setupIntent.payment_method)
            self.$analytics.logEvent("add_payment_info", { payment_type: "credit_card" })
          }
        })
      },
      removeCard() {
        let self = this
        self.changeLoadingState(true)
        cardControl.removeCard(this.user.data.id,() => {
          self.changeLoadingState(false)
          self.noCardSetupInit()
          self.$store.dispatch('pushSnackbarInfo', {text: snackbarConstant.message.CARD_DELETE, color: snackbarConstant.color.SUCCESS, isShow: true})
          self.$analytics.logEvent("delete_payment_info", { payment_type: "credit_card" })
        })
      },
      prefillNumExpCvc(last4, exp_month, exp_year, cvc) {
        if (exp_month.length < 2) {
          exp_month = '0' + exp_month
        }
        exp_year = exp_year.substring(exp_year.length - 2, exp_year.length)
        this.$refs.stripeUI.prefillNumExpCvc(last4, exp_month, exp_year, cvc)
      },
      noCardSetupInit() {
        this.buttonText = this.ADD_CARD
        this.$refs.stripeUI.noCardSetupInit()
      },
      cardSetupInit() {
        this.buttonText = this.SAVE_CARD
        this.$refs.stripeUI.cardSetupInit()
      },
      prefillCardInit(last4, exp_month, exp_year, cvc) {
        this.$refs.stripeUI.unmountCardElements()
        this.$refs.stripeUI.prefillCardInit(last4, exp_month, exp_year, cvc)
        this.buttonText = this.DELETE_CARD
      },
    }
  }
</script>
<style scoped>
  .image_container {
  }
  .background {
    z-index: 0;
    width: 25%;
    margin: 0 auto;
  }
  .savebtnColor.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    background-color:#26C6DA !important;
    color:white!important;
  }
  .wrap-text {
    -webkit-line-clamp: unset !important;
  }
</style>
