import firebase from 'firebase/app';
import {collections, operation, transactionsFields} from "@/constant/firebaseConstants";
import {punctuation} from "@/constant/punctuationConstant";
// import {query} from "vue-gtag";

let firebaseQuery = {
  queryTransactions : {
    queryLastFiveTransactionsByUserId(userId, callback) {
      firebase.firestore()
        .collection(collections.TRANSACTIONS)
        .where(transactionsFields.UID, punctuation.EQUAL_EQUAL, userId)
        .orderBy(transactionsFields.PAID_ON, operation.DESCENDING)
        .limit(5)
        .get().then(function (querySnapshots) {
          let promiseArray = []
          querySnapshots.forEach(documentSnapshot => {
            let payment = documentSnapshot.data();
            let dateStamp = payment[transactionsFields.PAID_ON] * 1000
            let paidDate = new Date(dateStamp);
            let clientTitle = ""
            let promiseQuery = new Promise(function (resolve, reject) {
              firebase.firestore().collection(collections.CLIENT_PROFILES).doc(payment[transactionsFields.CLIENT_ID])
                .get().then((clientData) => {
                let client = clientData.data() || {}
                clientTitle = client.title || ""
              }).then(() => {
                let strMonth = ""
                let month = paidDate.getMonth() + 1
                if (month < 10) {
                  strMonth += "0" + month
                } else {
                  strMonth += month
                }
                let amount = (payment[transactionsFields.AMOUNT_PAID] / 100).toFixed(2)
                let paymentRecord = {
                  paidDate: paidDate.getDate() + punctuation.SLASH + strMonth + punctuation.SLASH + paidDate.getFullYear(),
                  clientTitle: clientTitle,
                  amountPaid: punctuation.DOLLAR + amount,
                }
                resolve(paymentRecord)
              }).catch((error)=>{
                reject(error)
              })
            })
            promiseArray.push(promiseQuery)
          })
          Promise.all(promiseArray).then(function (results) {
            callback(results)
          })
        })
    }
  },
  queryCollectionPoint : {
    queryByShortCodeOrQrCode: (fieldName, fieldValue, callback) => {
      firebase.firestore()
        .collectionGroup(collections.COLLECTION_POINTS)
        .where(fieldName, punctuation.EQUAL_EQUAL, fieldValue)
        .get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          callback(null)
        } else {
          let result = {}
          querySnapshot.forEach(documentSnapshot => {
            let IDs = documentSnapshot.ref.path.split(punctuation.SLASH)
            let clientID = IDs[1]
            let campaignID = IDs[3]
            let collectionPointID = IDs[5]
            let collectionPoint = documentSnapshot.data();
            collectionPoint.id = collectionPointID;
            let client = null
            firebase.firestore().collection(collections.CLIENT_PROFILES).doc(clientID)
              .get().then((data) => {
              client = data.data()
            }).then(() => {
              firebase.firestore().collection(collections.CLIENT_PROFILES).doc(clientID)
                .collection(collections.CAMPAIGNS).doc(campaignID).get().then((data) => {
                let campaign = data.data();
                campaign.id = campaignID;
                result.client = client
                result.campaign = campaign
                result.collectionPoint = collectionPoint
                result.qrCode = clientID + punctuation.HYPHEN + campaignID + punctuation.HYPHEN + collectionPointID
                callback(result)
              })
            })
          });
        }
      });
    },
    queryByUserIdAndCampaignId(userId, campaignId, callback) {
      firebase.firestore()
        .collection(collections.CLIENT_PROFILES).doc(userId)
        .collection(collections.CAMPAIGNS).doc(campaignId)
        .collection(collections.COLLECTION_POINTS)
        .onSnapshot(function (querySnapshot) {
          let collectionPoints = [];
          querySnapshot.forEach(function (point) {
            const data = {
              ...point.data(),
              id: point.id,
            };
            if (!data.isArchived) {
              collectionPoints.push(data)
            }
          });
          callback({campaignId : campaignId, collectionPoints : collectionPoints})
        });
    },
    queryByCheckingShortCode : (fieldValue, callback) => {
      firebase.firestore()
        .collectionGroup(collections.COLLECTION_POINTS)
        .where("shortCode", punctuation.EQUAL_EQUAL, fieldValue)
        .get().then((querySnapshot) => {
          if (querySnapshot.empty) {
            callback(false)
          } else {
            callback(true)
          }
        });
    },
    queryAllShortCode : (callback) => {
      firebase.firestore()
        .collectionGroup(collections.COLLECTION_POINTS)
        .get().then((querySnapshot) => {
          let shortCodes = [];
          for(let i = 0; i < querySnapshot.docs.length; i++) {
            let dSnapShot = querySnapshot.docs[i]
            shortCodes.push(dSnapShot.data().shortCode)
          }
          callback(shortCodes)
      })
    },
  },
  queryUserProfile : {
    queryAcceptTermsAndCondition(userId, callback) {
      firebase.firestore().collection(collections.USER_PROFILES).doc(userId).get().then(doc => {
        const {termsAndConditionsInfo} =  doc.data();
        callback(termsAndConditionsInfo)
      })
    },
    queryIsTransactionReceiptEnabled(userId, callback) {
      firebase.firestore().collection(collections.USER_PROFILES).doc(userId).get().then(doc => {
        const {isTransactionReceiptEnabled} =  doc.data();
        callback(isTransactionReceiptEnabled)
      })
    },
    queryIsMarketingOptIn(userId, callback) {
      firebase.firestore().collection(collections.USER_PROFILES).doc(userId).get().then(doc => {
        const {isMarketingOptIn} =  doc.data();
        callback(isMarketingOptIn)
      })
    },
    queryPaymentMethods(userId, callback) {
      firebase.firestore().collection(collections.USER_PROFILES).doc(userId).get().then(doc => {
        const {paymentMethods} = doc.data();
        callback(paymentMethods)
      })
    },
  },
  queryClientProfile : {
    hasMerchantId(clientId, callback) {
      firebase.firestore().collection(collections.CLIENT_PROFILES).doc(clientId).get().then(doc => {
        const { merchantId } = doc.data()
        if (merchantId) {
          callback(true)
        } else {
          callback(false)
        }
      })
    },
    queryClientImage (userId, callback) {
      firebase.firestore().collection(collections.CLIENT_PROFILES).doc(userId).get().then(doc => {
        callback(doc.data())
      })
    },
    queryEmailAndCrownForResultBadge(clientId, callback) {
      firebase.firestore().collection(collections.CLIENT_PROFILES).doc(clientId).get().then(doc => {
        const {isDonorTransactionReceiptOverridden,irdNumber,charityNumber} =  doc.data();
        callback({isDonorTransactionReceiptOverridden,irdNumber,charityNumber})
      })
    },
    queryFavourite(userId, organisations, callback) {
      firebase.firestore().collection('userProfiles').doc(userId)
        .collection("favouriteClients")
        .get().then(function (querySnapshot) {
          if (querySnapshot.empty) {
            callback([])
            return
          }
          let favData = [];
          let myDate = new Date();
          let currentMonth = myDate.getMonth();
          let currentYear = myDate.getFullYear();
          if (currentMonth<4) {
            currentYear--
          }
          let beginDate = new Date(currentYear + "-04-01")
          beginDate.setHours(0);
          let timestampThreshold = Date.parse(beginDate)/1000;
          let transactionRef = firebase.firestore().collection('transactions');
          let itemsProcessed = 0;

          querySnapshot.forEach(function(doc) {
            let clientId = doc.data().clientId;
            let favouriteId = doc.id;
            let clientData = organisations.find(o => o.clientId === clientId);

            let promiseTransactionArray = []
            let promiseQueryTransaction = new Promise(function (resolveTransaction) {
              if(!clientData) {
                resolveTransaction({})
              } else {
                transactionRef
                  .where('clientId', '==', clientId)
                  .where('paidOn', '>=', timestampThreshold)
                  .where('uid', '==', userId)
                  .get()
                  .then(function(queryS){
                    let ytdTotal = 0;
                    queryS.forEach(function(transactionDoc) {
                      ytdTotal += transactionDoc.data().amountPaid;
                    });
                    resolveTransaction({
                      donorNumber: doc.data().donorNumber,
                      ytdTotal : ytdTotal,
                      title : clientData.title,
                      clientId : clientId,
                      favouriteId : favouriteId
                    })
                  })
              }
            })
            promiseTransactionArray.push(promiseQueryTransaction)
            Promise.all(promiseTransactionArray).then(function (resultArray) {
              resultArray.forEach(async function(result){
                let organizationTitle = result.title || ""
                if (organizationTitle && organizationTitle.trim().length > 0) {
                  let clientRef = firebase.firestore().collection('clientProfiles').doc(result.clientId);
                  var donateNowQrCode;
                  await clientRef.get().then((doc) => {
                    donateNowQrCode = doc.data().donateNowQrCode;
                  });
                  const eachData = {
                    clientTitle: organizationTitle,
                    ytdTotal: "$" + (parseFloat(result.ytdTotal)/100).toFixed(2),
                    donorNumber: result.donorNumber,
                    clientId: result.clientId,
                    favouriteId: result.favouriteId,
                    donateNowQrCode: donateNowQrCode
                  };
                  favData.push(eachData);
                }
              })
              itemsProcessed++;
              if(itemsProcessed === querySnapshot.size) {
                callback(favData);
              }
            })
          });
        },
      )
    },
  }
}

export default firebaseQuery
