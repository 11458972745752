<template>
  <div>
    <v-card-title
      dark
      class="header"
    >
      Campaign
      <v-spacer />

      <v-icon
        color="red"
        @click="deleteCampaign"
      >
        mdi-delete
      </v-icon>
    </v-card-title>
    <div class="flex-campaign">
      <div class="row">
        <div class="column">
          <v-text-field
            v-model="campaign.title"
            rows="1"
            label="Campaign Name"
            class="lengthBox"
            clearable
            counter="50"
            :maxlength="50"
            :rules="[v => v.length <= 50 || 'Max 50 characters']"
            outlined
            color="donorAccent1"
          />

          <v-textarea
            v-model="campaign.description"
            rows="1"
            auto-grow
            clearable
            counter="500"
            :maxlength="500"
            :rules="[v => v.length <= 500 || 'Max 500 characters']"
            label="Description"
            class="lengthBox"
            outlined
            color="donorAccent1"
          />

          <v-textarea
            v-model="campaign.donorReceiptInfo"
            rows="1"
            auto-grow
            clearable
            :maxlength="500"
            counter="500"
            label="Donor Receipt Info"
            class="lengthBox"
            outlined
            color="donorAccent1"
          />
        </div>
        <div class="column">
          <div>
            <div
              class="subheading mb-1"
            >
              Campaign Logo
            </div>
            <img
              :src="campaign.imageUrl"
            >

            <v-text-field
              v-model="uploadFileName"
              label="Upload Image"
              prepend-icon="mdi-camera"
              color="donorAccent1"
              @click="pickFile"
            />
            <input
              ref="image"
              type="file"
              style="display: none"
              accept="image/*"
              @change="onFileSelect"
            >

            <v-btn
              :disabled="uploadFileName===null"
              :loading="loaderUpload"
              color="donorAccent1 white--text"
              @click="updateImage"
            >
              Upload
            </v-btn>
            <v-btn
              :disabled="campaign.imageUrl===''"
              class="ma-2"
              color="donorAccent1 white--text"
              :loading="loaderRemove"
              @click="deleteImage"
            >
              Remove
            </v-btn>
            <ImageCropper
              ref="cropper"
              @cropImage="cropImage"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <!-- <div class="second-row"> -->
          
          <v-menu
            v-model="menu_cam_start"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-textarea
                v-model="campaign.startdate"
                rows="1"
                prepend-inner-icon="event"
                readonly
                label="Start Date"
                color="donorAccent1"
                outlined
                class="lengthBox"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="campaign.startdate"
              color="donorAccent1"
              @input="menu_cam_start=false"
            />
          </v-menu>
        </div>
            
        <div class="column">
          <v-menu
            v-model="menu_cam_end"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="150px"
          >
            <template v-slot:activator="{ on }">
              <v-textarea
                v-model="campaign.endDate"
                rows="1"
                label="End Date"
                outlined
                color="donorAccent1"
                prepend-inner-icon="event"
                readonly
                class="lengthBox"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="campaign.endDate"
              color="donorAccent1"
              @input="menu_cam_end = false"
            />
          </v-menu>
        </div>
      </div>
    </div>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="donorAccent1"
            :loading="loaderSave"
            :disabled="loaderSave"
            dark
            @click="saveCampaign"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ImageCropper from "./ImageCropperPopup";
  import fileStorage from "../store/firebase/fileStorage";
  import firebaseUpdate from "../store/firebase/firebaseUpdate";
  import {mapGetters} from "vuex";
  export default {
    name: "EditCampaign",
    components: {
      ImageCropper
    },
    
    props: ["initialCampaign"],
    data: function () {
      return {
        loaderUpload: false,
        loaderSave: false,
        loaderRemove: false,
        loaderUpFile: false,

        menu_cam_start: false,
        menu_cam_end: false,

        imageUrl: null,
        oldDeleteImg: "",
        uploadFileName: null,
      };
    },

    computed: {
      ...mapGetters({
        user: "user",
      }),
      campaign: function () {
        let donorReceiptInfo = null;
        if (this.initialCampaign.campaign.hasOwnProperty('donorReceiptInfo')) {
          donorReceiptInfo = this.initialCampaign.campaign.donorReceiptInfo;
        }
        let tempCampaign = {
          "title":this.initialCampaign.campaign.title,
          "imageUrl": this.initialCampaign.campaign.imageUrl,
          "description": this.initialCampaign.campaign.description,
          "startdate":this.initialCampaign.campaign.startdate,
          "endDate":this.initialCampaign.campaign.endDate,
          "donorReceiptInfo": donorReceiptInfo,
        }
        return tempCampaign;
      },
      campaignId: function () {
        return this.initialCampaign.id;
      },
      campaignCp: function () {
        return this.initialCampaign.collection;
      }
    },

    methods: {
      showSnackbar(text, color){
        this.$store.dispatch('pushSnackbarInfo', {text: text, color: color, isShow: true})
      },
      deleteCampaign () {
        if(confirm("Do you really want to delete?")) {
          let self = this
          firebaseUpdate.updateCampaign.setCampaignIsArchived(this.user.data.id, this.initialCampaign.campaign, this.campaignId,()=>{
            self.$store.dispatch("campaigns")
          })
        }
      },
      pickFile () {
        this.$refs.image.click ()
      },
      onFileSelect(event) {
        // this.imageUrl = event;
        this.oldDeleteImg = this.campaign.imageUrl;
        let input = event.target
        if (input.files && input.files[0]) {
          let reader = new FileReader()
          reader.onload = e => {
            let self = this
            let uploadedImage = new Image();
            uploadedImage.src = e.target.result;
            uploadedImage.onload = () => {
              self.$refs.cropper.show(e.target.result, input.files[0].name, true)
            }
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      cropImage(data) {
        let self = this
        self.loader = !self.loader;
        fileStorage.uploadImageByBlobUrl(data.blob, data.imageName, function (error, result) {
          if(error){
            self.showSnackbar(error.message, "error")
          } else {
            self.campaign.imageUrl = result;
            self.updateImgUrl(result);
            self.loaderUpFile =!self.loaderUpFile;
            self.$refs.cropper.show(null, "", false)
            if (self.oldDeleteImg.length > 0){
              self.removeImage2(self.oldDeleteImg);
            }
          }
        })
      },
      updateFatherImg(val) {
        this.initialCampaign.campaign.imageUrl = val;
      },
      updateImage() {
        if (this.imageUrl.length === 0) {
          return;
        }
        this.loaderUpFile =!this.loaderUpFile;
        let self = this;
        fileStorage.uploadImage(self.imageUrl, (error, url)=>{
          if(error) {
            console.log(error.message);
          } else {
            self.campaign.imageUrl = url;
            self.updateImgUrl(url);
            self.loaderUpFile =!this.loaderUpFile;
            if (self.oldDeleteImg.length > 0){
              self.removeImage2(self.oldDeleteImg);
            }
          }
        })
      },
      updateImgUrl(url) {
        let self = this
        const data = {imgUrl: url, id: this.campaignId};
        this.$store.dispatch("updateCampaignLogo", data)
          .then(() => {
            if (url.length > 0) {
              console.log("Success. You've uploaded your image")
              self.showSnackbar("Success. You've uploaded your image", "success")
            } else {
              console.log("Success. You've deleted your image")
              self.showSnackbar("Success. You've deleted your image", "success")
            }
          })
          .catch((error) => {
            console.log(error.message);
            self.showSnackbar(error.message, "error")
          })

      },
      deleteImage() {

        this.loaderRemove =! this.loaderRemove;
        this.removeImage(this.campaign.imageUrl);

        this.campaign.imageUrl = "";
        this.imageUrl = null;
        this.uploadFileName = null;

        this.loaderRemove =! this.loaderRemove;
        this.updateFatherImg("")
      },
      removeImage(url) {
        let self = this
        fileStorage.removeImage(url,(error,result)=>{
          if(error){
            console.log(`this is removeImage image delete error occurred: ${error.message}`)
            self.showSnackbar("image delete error occurred: " + error.message, "error")
          } else {
            console.log("this is remove Image result:" + result.message)
            self.updateImgUrl("");
          }
        })
      },
      removeImage2(url) {
        fileStorage.removeImage(url, (error, result)=>{
          if (error) {
            console.log("this is removeImage2 error " + JSON.stringify(error))
          } else {
            console.log("this is removeImage2 result " + JSON.stringify(result))
          }
        })
      },
      saveCampaign() {
        this.loader2 = !this.loader2;
        if (this.campaign.donorReceiptInfo != null && this.campaign.donorReceiptInfo.length === 0){
          this.campaign.donorReceiptInfo = null;
        }
        let camp = {
          "campaign": this.campaign,
          "id": this.campaignId,
          "collection": this.campaignCp};

        this.$store
            .dispatch("updateCampaign", camp)
            .then(() => {

              this.loader2 = !this.loader2;
              this.$emit('updateCampaign')
              this.showSnackbar("Success. You've saved the campaign.", "success")
            })
            .catch(() => {
              this.loader2 = !this.loader2;
            });

      },
    },
  }
</script>
<style scoped>
  img {
    height: 100px;
  }
  .lengthBox{
    max-width: 86%;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

  }
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding-left:10px;
    margin-left:15px;
  }
  .second-row {
    height: 100px;
  }

  @media screen and (min-width: 800px) {
    .column {
      flex: 1
    }
  }
</style>