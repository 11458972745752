import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/remote-config';
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

function initUser(user) {
  if (user) {
    const userWithAdminStatus = { ...user, isAdmin: false };
    firebase.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        const isAdmin = idTokenResult.claims.admin;
        userWithAdminStatus.isAdmin = !!isAdmin;
      })
      .catch((error) => {
        console.log('--Failed to get admin status--', error.message);
      }).finally(() => {
        store.dispatch("fetchUser", userWithAdminStatus);
      });
  } else {
    store.dispatch("fetchUser", user);
  }
}

async function initializeFirebaseLocal() {
  const firebaseConfig = {
    apiKey: "AIzaSyBEX-eULY8PxRTBlo7FXiSyDgyp6DNOR04",
    authDomain: "giv2dvl.firebaseapp.com",
    databaseURL: "https://giv2dvl.firebaseio.com",
    projectId: "giv2dvl",
    storageBucket: "giv2dvl.appspot.com",
    messagingSenderId: "1096659094950",
    appId: "1:1096659094950:web:3bf7d657daf450f34b5367",
    measurementId: "G-CPJCH9HMSG"
  }
  firebase.initializeApp(firebaseConfig);
  firebase.auth().onAuthStateChanged(initUser);
  firebase.analytics();
  initVue();
  initFirebaseRemoteConfig();

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js');
    });
  }

  logRemoteConfig('isSiteLive', 'Before fetch ');
}

function logRemoteConfig(remoteConfigVariable = undefined, prefix = undefined, postfix = undefined) {
  if (remoteConfigVariable === undefined) console.log('remoteConfig = undefined');

  console.log(
    (prefix === undefined ? '' : prefix.toString()) 
    + 'remoteConfig.' + remoteConfigVariable + ' = ' 
    + firebase.remoteConfig().getBoolean(remoteConfigVariable)
    + (postfix === undefined ? '' : postfix.toString()));
}

function initFirebaseRemoteConfig() {
  // Get reference to remote config
  const remoteConfig = firebase.remoteConfig();

  // Set remote config cache timing in milliseconds
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 60000
  };
  
  // Set up default values for remote config keys
  remoteConfig.defaultConfig = ({
    'isSiteLive': false
  });

  // Fetch and log latest value of remote config
  remoteConfig.fetchAndActivate()
  .then(() => {
    logRemoteConfig('isSiteLive', 'After fetch ');
  })
  .catch((err) => {
    console.error(err);
  });
}

function initVue() {
  Vue.prototype.$analytics = firebase.analytics();
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
}

async function init() {
  if (location.hostname === "localhost" || location.hostname === "giv2dvl.firebaseapp.com") {
    initializeFirebaseLocal();
  } else {
    fetch('/__/firebase/init.json').then(async response => {
      return await response.json();
    }).then(config => {
      config.authDomain = location.hostname;
      firebase.initializeApp(config);
      firebase.auth().onAuthStateChanged(initUser);
      initVue();
    })
  }
}

init();
