<template>
  <v-card class="overflow-hidden">
    <v-toolbar flat>
      <v-icon>mdi-card</v-icon>
      <v-toolbar-title class="font-weight-light">
        My Transcation
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="mb-6 justify-space-between">
        <v-col cols="3">
          <v-card-title dark>
            Donate to
          </v-card-title>
          <v-card-subtitle />
        </v-col>
        <v-col cols="3">
          <v-card-title dark>
            Amount
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-card-title dark>
            Date
          </v-card-title>
          <v-card-subtitle />
        </v-col>
        <v-col cols="3">
          <v-card-title dark>
            Payment Mode
          </v-card-title>
          <v-card-subtitle />
        </v-col>
        <v-col cols="3">
          <v-card-title dark />
          <v-card-subtitle />
        </v-col>
      </v-row>
      <v-row>
        <v-card-title />
      </v-row>
      <v-spacer />
      <v-divider />
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  methods: {}
};
</script>
