<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShowDialog"
      persistent
      class="d-flex"
    >
      <v-card class="mt-1">
        <v-card-title
          style="word-break: normal"
          class="headline justify-center text-center"
        >
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text
          id="popupTextId"
          style="word-break: normal"
          class="justify-center text-center"
        >
          {{ dialogText }}
        </v-card-text>
        <v-card-actions
          :class="`d-flex justify-space-around flex-${buttonLayout}`"
        >
          <v-btn
            id="popupLeftBtnId"
            v-if="isShowLeftButton"
            color="donorAccent1 white--text"
            @click="onLeftBtnClick"
          >
            {{ leftBtnText }}
          </v-btn>
          <v-btn
            id="popupRightBtnId"
            class="ma-2"
            color="donorAccent1 white--text"
            @click="onRightBtnClick"
          >
            {{ rightBthText }}
          </v-btn>
          <v-btn
            v-if="isShowExitButton"
            class="ma-2"
            color="donorAccent1 white--text"
            @click="onExitBtnClick"
          >
            {{ exitBtnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import popupConstant from "../constant/popupConstant";

export default {
  data() {
    return {
      isShowDialog : false,
      buttonLayout: "row",
      dialogTitle: '',
      dialogText: '',
      isShowLeftButton: false,
      isShowExitButton: false,
      leftBtnText: '',
      rightBthText: '',
      exitBtnText: '',
      currentDialogState: -1,
      SHORT_CODE: 1,
      UNAUTHENTICATED_NOTIFICATION: 2,
      PAYMENT_CONFIRM: 3,
      PAYMENT_FEEDBACK_CONFIRM: 4,
    }
  },
  methods: {
    showCannotFindShortCode() {
      this.currentDialogState = this.SHORT_CODE
      this.dialogTitle = popupConstant.CANNOT_FIND_SHORTCODE_TITLE
      this.dialogText  = popupConstant.CANNOT_FIND_SHORTCODE_TEXT
      this.isShowDialog = true
      this.isShowLeftButton = false
      this.leftBtnText = popupConstant.BUTTON_TEXT_NO
      this.rightBthText = popupConstant.BUTTON_TEXT_TRY_AGAIN
    },
    showPayConfirm(amount) {
      this.currentDialogState = this.PAYMENT_CONFIRM
      this.dialogText = popupConstant.UNAUTHENTICATED_PAYMENT_CONFIRM_PREFIX + (amount / 100).toFixed(2) + popupConstant.UNAUTHENTICATED_PAYMENT_CONFIRM_SUFFIX
      this.isShowDialog = true
      this.isShowLeftButton = true
      this.leftBtnText = popupConstant.BUTTON_TEXT_NO
      this.rightBthText = popupConstant.BUTTON_TEXT_YES
    },
    showUnAuthResultNotification() {
      this.currentDialogState = this.UNAUTHENTICATED_NOTIFICATION
      this.dialogText = popupConstant.UNAUTHENTICATED_NOTIFICATION
      this.isShowDialog = true
      this.isShowLeftButton = false
      this.rightBthText = popupConstant.BUTTON_TEXT_OK
    },
    thanksPopupResult() {
      this.buttonLayout = "column"
      this.currentDialogState = this.PAYMENT_FEEDBACK_CONFIRM
      this.dialogText = popupConstant.THANKS_TEXT
      this.leftBtnText = popupConstant.THANKS_LEFT
      this.rightBthText = popupConstant.THANKS_RIGHT
      this.exitBtnText = popupConstant.THANKS_EXIT
      this.isShowLeftButton = true
      this.isShowExitButton = true
      this.isShowDialog = true
    },
    hideDialog() {
      this.dialogTitle = ''
      this.dialogText  = ''
      this.isShowDialog = false
      this.isShowLeftButton = false
      this.leftBtnText = ""
      this.rightBthText = ""
    },
    onLeftBtnClick() {
      this.hideDialog()
      switch (this.currentDialogState) {
        case this.PAYMENT_CONFIRM:
          this.$emit('refusePayment', "");
          break
        case this.PAYMENT_FEEDBACK_CONFIRM:
          this.$emit('goToQrScannerPage', "");
          break
      }
    },
    onRightBtnClick() {
      this.hideDialog()
      switch (this.currentDialogState) {
        case this.PAYMENT_CONFIRM:
          this.$emit('acceptPayment', "");
          break
        case this.PAYMENT_FEEDBACK_CONFIRM:
          this.$emit('goToProfilePage', "");
          break
      }
    },
    onExitBtnClick() {
      this.hideDialog()
      switch (this.currentDialogState) {
        case this.PAYMENT_FEEDBACK_CONFIRM:
          this.$emit('goToMainPage', "");
          break
      }
    }
  }
}
</script>