import firebase from 'firebase/app'

let cloudFunction = {
  give : function (data, callback) {
    let ref = firebase.functions().httpsCallable("give")
    ref(data).then(function (result) {
      // if (!result.data.success && result.data.errorCode === 'NO_PAYMENT_SETUP') {
      if (result.data && result.data.success) {
        callback(null, result.data)
      } else {
        callback({message : result.data.errorCode}, null)
      }
    })
  },
  getClientSecretForCardSetup : function (callback) {
    let ref = firebase.functions().httpsCallable("getClientSecretForCardSetup")
    ref({}).then(function (result) {
      callback(result.data)
    })
  },
  getClientTitlesQuery : function (data, callback) {
    let ref = firebase.functions().httpsCallable("favouriteClientTitlesQuery")
    ref(data).then(function (result) {
      if (result.data && result.data.success) {
        callback(null, result.data.list)
      } else {
        callback({message : result.data.errorCode}, null)
      }
    })
  },
  getPayMarkClickSaveCardUrl : function(data, callback) {
    let ref = firebase.functions().httpsCallable("getPaymarkClickSaveCardUrl")
    ref(data).then(function (result) {
      callback(result.data)
    })
  },
  deletePaymentMethod: function(data, callback) {
    let ref = firebase.functions().httpsCallable("deletePaymentMethod")
    ref(data).then(function (result) {
      callback(result.data)
    })
  }
}
export default cloudFunction