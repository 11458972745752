let stripe = {
  getIntance: function (callback) {
    let e = document.createElement('script')
    e.src = `https://js.stripe.com/v3`
    e.type = 'text/javascript'
    document.getElementsByTagName('head')[0].appendChild(e)
    e.addEventListener('load', ()=> {
      const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY
      try {
        callback(window.Stripe(STRIPE_PUBLIC_KEY))
      } catch (e) {
        console.log('e ' + e)
        callback(null)
      }
    })
  },
}
export default stripe