<template>
  <div>
    <navbar />
    <div class=" my-12 py-12">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar";
import { mapGetters } from "vuex";
export default {
  components: {
    navbar,
    //profile
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
};
</script>