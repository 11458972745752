<template>
  <v-layout
    id="login-page"
    class="client-ui"
  >
    <div class="row">
      <div class="column justify-center align-center ">
        <div class="second-row">
          <div class="column">
            <div class="first-row pa-4">
              <div class="logo-card logo-position centre">
                <img
                  src="./../assets/logo.png"
                  class="center pa-3"
                >
              </div>
            </div>
          </div>
          <div
            class="pt-2 ma-6"
          >
            <section
              id="firebaseui-auth-container"
              class="py-12 my-2"
            />
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { isMobile } from "mobile-device-detect";
export default {
  name: "AdminLogin",
  data() {
    return {
      success: false,
      isMobile: isMobile,
      error: "",
         };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    }),
    
  },
  created() {
    if (this.isSiteLive) return;
    firebase
      .remoteConfig()
      .fetchAndActivate()
      .then(() => {
        this.isSiteLive = firebase.remoteConfig().getBoolean('isSiteLive');
      })
      .catch(err => {
        console.error(err);
      });
  },
  mounted() {
    if (this.user.loggedIn === true) {
      if (isMobile) {
        this.$router.push("/donor/qrScanner");
      } else {
        this.$router.push("/dashboard/clientProfile");
      }
    } else {
      if (isMobile) {
        var uiConfigMobile = {
          signInSuccessUrl: "/donor/qrScanner",

          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
          ]
        };
        var uiMobile = new firebaseui.auth.AuthUI(firebase.auth());
        uiMobile.start("#firebaseui-auth-container", uiConfigMobile);
      } else {
        var uiConfig = {
          signInSuccessUrl: "/dashboard/clientProfile",
          signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
          ]
        };
        var ui = new firebaseui.auth.AuthUI(firebase.auth());
     ui.start("#firebaseui-auth-container", uiConfig);
      }
    }
  },
};
</script>

<style scoped>
#login-page {
  background-color: white;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: auto;
}

#firebaseui-auth-container {
  margin-left: 50px;
  margin-right: 70px;
  min-width: 200px;
display: flex;
align-items: center;
justify-content: center;
}


.stripe {
  border-color: white transparent transparent transparent;
  background: linear-gradient(15deg, white 45%, #26c6da 30%);
}
.client-ui {
  background: linear-gradient(to top, #e0f7fa 40%, #26c6da 90%);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: auto;
  position: relative;
}

.first-row {
  height: 100px;
}

.second-row {
  height: 600px;
}

@media screen and (min-width: 800px) {
  .column {
    flex: 1;
  }
}

.logo-card {
  border-radius: 1em;
  background-color: white;
  min-width: 100px;
  max-width: 100px;
  width: 23%;
}

.logo-position{
  padding: auto;
  margin: auto;
}
</style>