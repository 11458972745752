<template>
  <div :style="{ height: `${pageHeight}px` }">
    <section :style="{ height: `${scannerHeight}px` }">
      <qrcode-stream
        @decode="onDecode"
        @init="onInit"
      >
        <v-alert
          v-if="error"
          text
          prominent
          type="error"
        >
          <h3 class="headline">
            Camera access permission
          </h3>
          <div>
            Giv2 requires the use of your device camera to scan QR codes and donate to your favourite charitable organisation. Please refresh the page to provide camera access.
          </div>
        </v-alert>
      </qrcode-stream>
    </section>
    <v-container ref="shortCodeSection">
      <v-row>
        <v-col>
          <p
            class="subtitle-2 text-center"
            style="margin: 0"
          >
            OR
          </p>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="8">
          <v-text-field
            v-model="string"
            label="Enter short code"
            outlined
            color="donorAccent1"
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-btn
            color="donorAccent1 white--text"
            @click="onSearch"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Popup ref="shortCodePopup" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { QrcodeStream } from 'vue-qrcode-reader'
import Popup from '../../components/Popup'
import router from '../../router/index'
import analytics from "../../store/firebase/analytics";
import {collectionPointFields} from "../../constant/firebaseConstants";
import firebaseQuery from "../../store/firebase/firebaseQuery";

export default {
  components: {
    QrcodeStream,
    Popup
  },
  data() {
    return {
      error: '',
      string: '',
      pageHeight: 0,
      scannerHeight: 0,
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.setHeight);
    });
    
    this.setHeight()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight);
  },
  methods: {
    onDecode(qrCode) {
      if (qrCode) {
        let arr = qrCode.split("/")
        let ids = arr[arr.length - 1]
        this.jumpToResultPage(collectionPointFields.QR_CODE, ids);
        this.$analytics.logEvent("scan_success", {key : qrCode})
        let self = this
        analytics.isClientIdValidInQrCode(ids, (result) => {
          if(result.isValid) self.$analytics.logEvent("scan_client", {key : result.clientId})
        })
        analytics.isCampaignIdValidInQrCode(ids, (result) => {
          if(result.isValid) self.$analytics.logEvent("scan_campaign", {key : result.campaignId})
        })
        analytics.isCollectionPointIdValidInQrCode(ids, (result) => {
          if(result.isValid) self.$analytics.logEvent("scan_collection_point", {key : result.collectionPointId})
        })
      }
      else {
        this.$analytics.logEvent("scan_failure")
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    async onInit(promise) {
      try {
        await promise
        this.$analytics.logEvent("camera_permission_granted");
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
        this.$analytics.logEvent("camera_permission_denied");
      }
    },
    jumpToResultPage(fieldName, fieldValue){
      let self = this;
      firebaseQuery.queryCollectionPoint.queryByShortCodeOrQrCode(fieldName,fieldValue,(queriedInfo) => {
        if (queriedInfo) {
          if(fieldName === collectionPointFields.SHORT_CODE)
          {
            self.$analytics.logEvent("search_shortcode_success", {key : fieldValue})
          }
          self.$store.dispatch('queryCollectionPointByShortcodeOrQrCode', queriedInfo)
          self.$analytics.logEvent("search", {search_term : fieldValue})
          router.push(`/donor/result/${queriedInfo.qrCode}`)
        } else {
          if(fieldName === collectionPointFields.SHORT_CODE)
          {
            self.$analytics.logEvent("search_shortcode_failure", {key : fieldValue})
          }
          self.$refs.shortCodePopup.showCannotFindShortCode()
        }
      })

    },
    onSearch() {
      this.jumpToResultPage(collectionPointFields.SHORT_CODE,this.string)
    },
    setHeight() {
      const browserHeight = window.innerHeight;
      const headerHeight = document.getElementsByTagName("HEADER")[0].offsetHeight;
      const pageHeight = browserHeight - headerHeight;
      const shortCodeSectionHeight = this.$refs.shortCodeSection.clientHeight;
      const scannerHeight = pageHeight - shortCodeSectionHeight;
      this.pageHeight = browserHeight - headerHeight;
      this.scannerHeight = scannerHeight;
    }
  }
};
</script>
