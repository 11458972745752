<template>
  <div
    id="example-2"
    class="cell example example2"
    :style="stripeUIHideStyle"
  >
    <v-container class="py-0 pa-0">
      <v-row>
        <v-col col="12" class="py-0 pa-0">
          <v-row no-gutters class="my-0 py-0">
            <div class="field-card-number card-number">
              <div
                :class="stripeCardInfoStyle"
                id="example2-card-number"
                class="input empty"
              />
              <label
                :class="stripeLabelMarginStyle"
                for="example2-card-number"
                data-tid="elements_examples.form.card_number_label"
              >{{cardNumberLabelText}}</label>
              <div class="baseline" />
            </div>
          </v-row>
          <v-row no-gutters class="my-0 py-0">
            <v-col cols="6">
              <div class="field-card-expiry half-width card-expiry">
                <div
                  :class="stripeCardInfoStyle"
                  id="example2-card-expiry"
                  class="input empty"
                />
                <label
                  :class="stripeLabelMarginStyle"
                  for="example2-card-expiry"
                  data-tid="elements_examples.form.card_expiry_label"
                >{{cardExpiryLabelText}}</label>
                <div class="baseline" />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="field-card-cvc half-width card-cvc">
                <div
                  :class="stripeCardInfoStyle"
                  id="example2-card-cvc"
                  class="input empty"
                />
                <label
                  :class="stripeLabelMarginStyle"
                  for="example2-card-cvc"
                  data-tid="elements_examples.form.card_cvc_label"
                >{{cardCvcLabelText}}</label>
                <div class="baseline" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import stripeRef from "../helpers/stripeLib";

  export default {
    name: "StripeUI",
    data() {
      return {
        stripe : null,
        client_secret : null,
        cardNumber : null,
        cardExpiry : null,
        cardCvc : null,
        cardNumberLabelText : "Card Number",
        cardExpiryLabelText : "Expiry",
        cardCvcLabelText : "CVC",
        stripeUIHideStyle: "display:none",
        stripeLabelMarginStyle: "ml-2 mb-1",
        stripeCardInfoStyle: "ml-3 mb-1"
      }
    },
    methods: {
      confirmCardSetup(callback) {
        let payment_method_obj = {
          card: this.cardNumber
        }

        this.stripe.confirmCardSetup(this.client_secret, {
          payment_method: payment_method_obj
        }).then(function (result) {
          //result.error.code and result.error.message
          callback(result)
        })
      },
      createToken(callback) {
        this.stripe.createToken(this.cardNumber).then(function(result) {
          let last4 = result.token.card.last4
          let exp_month = result.token.card.exp_month
          let exp_year = result.token.card.exp_year
          callback(last4, exp_month, exp_year)
        })
      },
      getClientSecret(client_secret) {
        this.client_secret = client_secret
      },
      prefillNumExpCvc(last4, exp_month, exp_year, cvc) {
        if (exp_month.length < 2) {
          exp_month = '0' + exp_month
        }
        exp_year = exp_year.substring(exp_year.length - 2, exp_year.length)

        this.cardNumberLabelText = "Card Number: ************" + last4
        this.cardExpiryLabelText = "Expiry: " + exp_month + "/" + exp_year
        this.cardCvcLabelText = "CVC: " + cvc
      },
      noCardSetupInit() {
        this.stripeUIHideStyle = "display:none"
        this.cardNumberLabelText = "Card Number"
        this.cardExpiryLabelText = "Expiry"
        this.cardCvcLabelText = "CVC"
      },
      cardSetupInit() {
        this.stripeUIHideStyle = "display:block"
        let self = this
        stripeRef.getIntance(function(instance) {
          self.initCardStripeUI(instance)
        })
      },
      prefillCardInit(last4, exp_month, exp_year, cvc) {
        this.stripeUIHideStyle = "display:block"
        this.prefillNumExpCvc(last4, exp_month, exp_year, cvc)
      },
      unmountCardElements() {
        document.getElementById('example2-card-number').innerHTML = '';
        document.getElementById('example2-card-expiry').innerHTML = '';
        document.getElementById('example2-card-cvc').innerHTML = '';
      },
      initCardStripeUI(stripe) {
        if (!this.stripe) {
          this.stripe = stripe
        }

        const elements = this.stripe.elements()
        let elementStyles = {
          base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
            fontSize: '15px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
              color: '#CFD7DF',
            },
            ':-webkit-autofill': {
              color: '#e39f48',
            },
          },
          invalid: {
            color: '#E25950',

            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        };

        let elementClasses = {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        };

        this.cardNumber = elements.create('cardNumber', {
          style: elementStyles,
          classes: elementClasses,
        });

        this.cardExpiry = elements.create('cardExpiry', {
          style: elementStyles,
          classes: elementClasses,
        });

        this.cardCvc = elements.create('cardCvc', {
          style: elementStyles,
          classes: elementClasses,
        });

        this.cardNumber.mount('#example2-card-number');
        this.cardExpiry.mount('#example2-card-expiry');
        this.cardCvc.mount('#example2-card-cvc');
      }
    }
  }
</script>
<style scoped>
  .card-number{
    border-radius: 8px 8px 0px 0px;
  }
  .card-expiry{
    border-radius: 0px 0px 0px 8px;
  }
  .card-cvc{
    border-radius: 0px  0px 8px 0px;
  }
  img {
    width: 200px;
    height: 100px;
  }
  .savebtnColor.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    background-color:#26C6DA !important;
    color:white!important;
  }
  .wrap-text {
    -webkit-line-clamp: unset !important;
  }
  .example.example2 {
    background-color: #fff;
  }

  .example.example2 * {
    font-family: Source Code Pro, Consolas, Menlo, monospace;
    font-size: 15px;
    font-weight: 500;
  }

  .example.example2 .row {
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px 10px;
  }

  .example.example2 .field {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border: 1px solid grey;
  }

  .example.example2 .field-card-number {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
    border-width:1px 1px 1px 1px
  }

  .example.example2 .field-card-expiry {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:transparent rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
    border-width:1px 1px 1px 1px
  }

  .example.example2 .field-card-cvc {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:transparent rgba(0,0,0,0.12) rgba(0,0,0,0.12) transparent;
    border-width:1px 1px 1px 1px
  }

  /* .example.example2 .field.half-width {
    width: 50%;
  } */

  .example.example2 .field.quarter-width {
    width: calc(25% - 10px);
  }

  /*.example.example2 .baseline {*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 1px;*/
  /*  left: 0;*/
  /*  bottom: 0;*/
  /*  background-color: #cfd7df;*/
  /*  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);*/
  /*}*/

  .example.example2 label {
    position: absolute;
    width: 90%;
    left: 4px;
    bottom: 8px;
    color: #cfd7df;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
  }

  .example.example2 .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .example.example2 .input.focused,
  .example.example2 .input:not(.empty) {
    opacity: 1;
  }

  .example.example2 .input.focused::-webkit-input-placeholder,
  .example.example2 .input:not(.empty)::-webkit-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused::-moz-placeholder,
  .example.example2 .input:not(.empty)::-moz-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused:-ms-input-placeholder,
  .example.example2 .input:not(.empty):-ms-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused + label,
  .example.example2 .input:not(.empty) + label {
    color: #aab7c4;
    transform: scale(0.75) translateY(-18px);
    cursor: default;
  }

  .example.example2 .input.focused + label {
    color: #26C6DA;
  }

  .example.example2 .input.invalid + label {
    color: #ffa27b;
  }

  .example.example2 .input.focused + label + .baseline {
    background-color: #26C6DA;
  }

  .example.example2 .input.focused.invalid + label + .baseline {
    background-color: #e25950;
  }

  .example.example2 input, .example.example2 button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .example.example2 input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .example.example2 input, .example.example2 button {
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 button {
    display: block;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #26C6DA;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
  }

  .example.example2 .error svg {
    margin-top: 0 !important;
  }

  .example.example2 .error svg .base {
    fill: #e25950;
  }

  .example.example2 .error svg .glyph {
    fill: #fff;
  }

  .example.example2 .error .message {
    color: #e25950;
  }

  .example.example2 .success .icon .border {
    stroke: #26C6DA;
  }

  .example.example2 .success .icon .checkmark {
    stroke: #24b47e;
  }

  .example.example2 .success .title {
    color: #32325d;
    font-size: 15px !important;
  }

  .example.example2 .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .example.example2 .success .reset path {
    fill: #24b47e;
  }
</style>
