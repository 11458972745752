import { render, staticRenderFns } from "./AddCollectionPoint.vue?vue&type=template&id=a397c692&scoped=true"
import script from "./AddCollectionPoint.vue?vue&type=script&lang=js"
export * from "./AddCollectionPoint.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a397c692",
  null
  
)

export default component.exports