<template>
  <nav>
    <v-app-bar
      app
      flat
      :clipped-left="clip"
    >
      <v-app-bar-nav-icon
        v-if="user.loggedIn"
        @click="onMenuButtonClick()"
      />
      <v-spacer />
      <v-toolbar-title class="text-uppercase black--text">
        <img src="./../assets/logo.png">
      </v-toolbar-title>
      <v-spacer />
      <div
        v-if="user.loggedIn"
      >
        <avatar
          v-if="userProfile.imageUrl != null"
          :image="userProfile.imageUrl"
        />
        <avatar
          v-else
          :fullname="user.data.displayName"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="this.$router.currentRoute.path.indexOf('dashboard') > -1"
      :expand-on-hover="expandOnHover"
      app
      :clipped="clip"
      @mouseover.native="expandOnHover=false"
      @mouseleave.native="expandOnHover=true"
    >
      <v-list dense>
        <v-list-item-group        
          color="clientHeaderBarAccent"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            route
            :to="item.route"
            link
            @click="expandOnHover=true"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAdmin"
            route
            to="/dashboard/managePayments"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Manage Payments</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div
          v-show="!expandOnHover"
          class="text--disabled"
        >
          <v-row justify="center">
            Version {{ appVersion }}
          </v-row>
        </div>
        <div class="pa-2">
          <v-btn
            v-show="!expandOnHover"
            block
            @click="signOut()"
          >
            Sign Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="this.$router.currentRoute.path.indexOf('donor') > -1"
      v-model="drawer"
      app
      :clipped="clip"
      stateless
    >
      <v-list dense>
        <v-list-item
          v-for="item in items2"
          :key="item.title"
          route
          :to="item.route"
          link
          @click="drawer=false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div
          class="text--disabled"
        >
          <v-row justify="center">
            Version {{ appVersion }}
          </v-row>
        </div>
        <div class="pa-2">
          <v-btn
            block
            @click="signOut()"
          >
            Sign Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";
import Avatar from "vue-avatar-component";
import firebase from 'firebase/app';
export default {
  components: { Avatar },
   data() {
    return {
      appVersion: process.env.VUE_APP_VERSION.substring(1, process.env.VUE_APP_VERSION.length - 1),
      mobile: isMobile,
      drawer: false,
      expandOnHover: true,
      drawerShown: false,
      clip: true,
      profile: "",
      items: [
        {
          title: "My Organisation",
          icon: "account_box",
          route: "/dashboard/clientProfile" ,            
        },
        {
          title: "Manage Campaigns",
          icon: "mdi-file-document",
          route: "/dashboard/manageCampaign"
        },
        {
          title: "Reporting",
          icon: "mdi-file-chart",
          route: "/dashboard/reports"
        }
      ],
      items2: [
        { title: "QR Scanner", icon: "mdi-barcode-scan", route: "/donor/qrScanner" },
        {
          title: "My Profile",
          icon: "face",
          route: "/donor/profile"
        },
        {title: "Favourites", icon: "favorite", route: "/donor/favourites"}
      ]
    };
  }
  ,
   computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
      userProfile: "userProfile",
      isAdmin: "isAdmin"
    })
  },
   methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "login",
          });
          this.$store.commit("SET_LOGGED_IN", false);
        });
    },
    onMenuButtonClick() {
      if (this.$router.currentRoute.path.indexOf('donor') > -1) {
        this.drawer= !this.drawer;
        this.expandOnHover= !this.expandOnHover;
      }
    }
  },
};
</script>

<style scoped>
.material-icons.md-36 {
  font-size: 36px;
}
img {
  height: 80px;
  vertical-align: middle;
}
</style>
