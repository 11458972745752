<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShowDialog"
      persistent
      class="d-flex"
    >
      <v-card class="badge_container">
        <v-list three-line class="pa-3">
          <template>
            <v-list-item>
              <v-list-item-action>
                <v-icon :color="emailIconColor" >{{emailIcon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-html="emailTitle" />
                <v-card-title
                  style="word-break: normal"
                  class="grey--text pa-0 description"
                >
                  {{emailDescription}}
                </v-card-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="pt-0" :color="crownIconColor">{{crownIcon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-html="crownTitle" />
                <v-card-title
                  style="word-break: normal"
                  class="grey--text pa-0 description"
                >
                  {{crownDescription}}
                </v-card-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <div class="pa-1"/>
        <v-btn
          class="pl-0 pr-0 ml-0 mr-0 close_button"
          text
          v-bind="attrs"
          color="cyan lighten-1 white--text"
          @click="hide"
        >
          Close
        </v-btn>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

  export default {
    data() {
      return {
        isShowDialog : false,
        crownIcon : "",
        crownIconColor : "",
        crownTitle : "Authenticated Organisation",
        crownDescription : "This badge means the organisation has provided valid Charities Registration and IRD numbers.",
        emailIcon : "",
        emailIconColor : "",
        emailTitle : "Transaction Receipts",
        emailDescription : "This badge means Giv2 is sending transaction receipts if you have opted to receive these. If not highlighted, the organisation has opted to send your receipt to you manually."
      }
    },
    methods: {
      show() {
        this.isShowDialog = true
      },
      initCrownIcon(icon, color) {
        this.crownIcon = icon
        this.crownIconColor = color
      },
      initEmailIcon(icon, color) {
        this.emailIcon = icon
        this.emailIconColor = color
      },
      hide() {
        this.isShowDialog = false
      }
    }
  }
</script>

<style scoped>
  .badge_container {
    position: relative;
  }
  .close_button {
    position: absolute;
    bottom: 0vh;
    right: 0.5vh;
  }
  .description {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
  }
</style>

