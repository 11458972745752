import localStorageConstant from "../constant/localStorageConstant";

let localStorage = {
  setUnAuthenticatedInfo : function (unAuthenticatedInfo) {
    if (unAuthenticatedInfo) {
      unAuthenticatedInfo.timestamp = parseInt((new Date().getTime() / 1000).toFixed(0))
      window.localStorage.setItem(localStorageConstant.UNAUTHENTICATED_INFO, JSON.stringify(unAuthenticatedInfo))
    } else {
      window.localStorage.setItem(localStorageConstant.UNAUTHENTICATED_INFO, "")
    }
  },
  getUnAuthenticatedInfo : function () {
    let unAuthenticatedInfoString = window.localStorage.getItem(localStorageConstant.UNAUTHENTICATED_INFO)
    if (!unAuthenticatedInfoString || unAuthenticatedInfoString.length <= 0) {
      return null
    }
    let unAuthenticatedInfo = JSON.parse(unAuthenticatedInfoString)
    let currentTimeStamp = parseInt((new Date().getTime() / 1000).toFixed(0))
    let duration = currentTimeStamp - unAuthenticatedInfo.timestamp
    if(duration > localStorageConstant.FIFTEEN_MINUTES) {
      return null
    }
    return unAuthenticatedInfo
  },
  setIsShowUnAuthenticatedSnackbar : function () {
    window.localStorage.setItem(localStorageConstant.IS_SHOW_UNAUTHENTICATED_SNACKBAR, "true")
  },
  getIsShowUnAuthenticatedSnackbar : function () {
    return window.localStorage.getItem(localStorageConstant.IS_SHOW_UNAUTHENTICATED_SNACKBAR)
  },
  setIsShowResultHint : function () {
    window.localStorage.setItem(localStorageConstant.IS_SHOW_RESULT_HINT, "true")
  },
  getIsShowResultHint : function () {
    return window.localStorage.getItem(localStorageConstant.IS_SHOW_RESULT_HINT)
  }
}

export default localStorage
