export const collections = {
  CLIENT_PROFILES: "clientProfiles",
  CAMPAIGNS: "campaigns",
  COLLECTION_POINTS: "collectionPoints",
  USER_PROFILES: "userProfiles",
  TRANSACTIONS: "transactions"
}

export const userProfilesFields = {
  PAYMENT_METHODS: "paymentMethods",
  PAYMENT_METHOD_ID : "paymentMethodId",
  PAYMENT_METHOD_LAST_FOUR_DIGITS : "paymentMethodLastFourDigits",
  PAYMENT_METHOD_EXPIRY_MONTH : "paymentMethodExpiryMonth",
  PAYMENT_METHOD_EXPIRY_YEAR : "paymentMethodExpiryYear",
  IS_TRANSACTION_RECEIPT_ENABLED : "isTransactionReceiptEnabled",
  IS_MARKETING_OPT_IN: "isMarketingOptIn",
  TERMS_AND_CONDITIONS: "termsAndConditionsInfo"
}

export const termsAndConditionInfo = {
  HAS_ACCEPTED_TERMS_AND_CONDITIONS : "hasAcceptedTermsAndConditions",
  ACCEPTED_ON : "acceptedOn"
}

export const paymentMethodsFields = {
  CARD_NUMBER : "cardNumber",
  EXPIRY_MONTH : "expiryMonth",
  EXPIRY_YEAR : "expiryYear",
  ID : "id",
  PLATFORM_ID : "platformId"
}

export const collectionPointFields = {
  SHORT_CODE : "shortCode",
  QR_CODE : "qrCode",
  IS_ARCHIVED: "isArchived",
}

export const campaignFields = {
  IS_ARCHIVED: "isArchived",
}

export const transactionsFields = {
  PAID_ON : "paidOn",
  CLIENT_ID : "clientId",
  CAMPAIGN_ID : "campaignId",
  AMOUNT_PAID : "amountPaid",
  UID : "uid"
}

export const operation = {
  DESCENDING : "desc"
}

