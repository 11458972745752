import firebase from 'firebase/app';
import {collections, paymentMethodsFields, userProfilesFields} from "../../constant/firebaseConstants";

let cardControl = {
  // save bank card to firebase
  saveCard:  (cardData, uid, callback) => {
    firebase.firestore().collection(collections.USER_PROFILES).doc(uid).set({
      [userProfilesFields.PAYMENT_METHOD_ID] : cardData.paymentMethodId,
      [userProfilesFields.PAYMENT_METHOD_LAST_FOUR_DIGITS] : cardData.paymentMethodLastFourDigits,
      [userProfilesFields.PAYMENT_METHOD_EXPIRY_MONTH] : cardData.paymentMethodExpiryMonth,
      [userProfilesFields.PAYMENT_METHOD_EXPIRY_YEAR] : cardData.paymentMethodExpiryYear
    }, {merge: true}).then(result => {
      callback(result)
    })
  },
  saveCardForStripe:  (cardData, uid, callback) => {

    firebase.firestore().collection(collections.USER_PROFILES).doc(uid).get().then(doc => {
      let {paymentMethods} =  doc.data();
      if (!paymentMethods || !Array.isArray(paymentMethods)) {
        paymentMethods = []
      }
      let isPlatformIdExist = false
      for (let i = 0; i < paymentMethods.length; i++) {
        if (cardData[paymentMethodsFields.ID] === paymentMethods[i][paymentMethodsFields.ID]) {
          isPlatformIdExist = true
          break
        }
      }
      if (!isPlatformIdExist) {
        paymentMethods.push(cardData);
        firebase.firestore().collection(collections.USER_PROFILES).doc(uid).set({
          [userProfilesFields.PAYMENT_METHODS] : paymentMethods
        }, {merge: true}).then(result => {
          callback(result)
        })
      } else {
        callback(null)
      }
    })
  },
  // remove bank card to firebase
  removeCard: (uid, callback) => {
    firebase.firestore().collection(collections.USER_PROFILES).doc(uid).update({
      [userProfilesFields.PAYMENT_METHOD_ID]: firebase.firestore.FieldValue.delete(),
      [userProfilesFields.PAYMENT_METHOD_LAST_FOUR_DIGITS]: firebase.firestore.FieldValue.delete(),
      [userProfilesFields.PAYMENT_METHOD_EXPIRY_MONTH]: firebase.firestore.FieldValue.delete(),
      [userProfilesFields.PAYMENT_METHOD_EXPIRY_YEAR]: firebase.firestore.FieldValue.delete()
    }).then(result => {
      callback(result)
    })
  },
  // fetch bank card from firebase
  fetchCard: (uid, hasMerchantId, callback) => {
    firebase.firestore().collection(collections.USER_PROFILES).doc(uid).get().then(doc => {
      const {
        customerId,
        paymentMethods,
      } = doc.data();

      if (!paymentMethods) {
        callback(null)
        return
      }

      const platformId = hasMerchantId ? 1 : 0;
      const paymentMethod = paymentMethods.find(pm => pm.platformId == platformId);
      if (!paymentMethod ||
        (paymentMethod.platformId === 0 && (!customerId || !paymentMethod.id)) ||
        (paymentMethod.platformId === 1 && !paymentMethod.id)) {
        callback(null)
      } else {
        callback({})
      }

    })
  }
}
export default cardControl