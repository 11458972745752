<template>
  <div id="app">
    <!-- <iframe v-if="source" :src="source" @load="onClickPaymentPageLoad"></iframe> -->
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate color="donorAccent1 white--text" />
    </v-overlay>
  </div>
</template>

<script>
import cloudFunction from "../../helpers/cloudFunction";
import {snackbarConstant} from "../../constant/snackbarConstant";

export default {
  name: "ClickSetup",
  props: {
    qrCode: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      source: "",
      overlay: true,
    };
  },
  mounted() {
    this.getPayMarkClickSaveCardUrl();
  },
  methods: {
    onClickPaymentPageLoad() {
      this.overlay = false;
    },
    showSnackbar(text, color) {
      this.$store.dispatch("pushSnackbarInfo", {
        text: text,
        color: color,
        isShow: true,
      });
    },
    getPayMarkClickSaveCardUrl() {
      let self = this
      const originUrl = window.location.origin;
      try {
        cloudFunction.getPayMarkClickSaveCardUrl(
          { origin: originUrl, qrCode: this.qrCode },
          function(result) {
            if (result) {
              if (result.success) {
                window.open(result.url, "_self");
              } else {
                self.showSnackbar(result.errorCode, snackbarConstant.color.ERROR);
                self.overlay = false
              }
            }
          }
        );
      } catch (e) {
        this.overlay = false;
      }
    },
  },
};
</script>

<style scoped>
#app,
#app iframe {
  height: 100vh;
  width: 100vw;
  border: none;
  box-sizing: border-box;
}
body {
  margin: 0;
}
</style>
