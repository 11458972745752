<template>
  <v-card
    v-if="load"
    class="pa-auto"
  >
    <v-toolbar flat>
      <v-toolbar-title
        class="font-weight-bold "
        dark  
      >
        Manage Campaigns
      </v-toolbar-title>
    </v-toolbar>
    <v-expansion-panels
      :focusable="focusable"
      :popout="popout"
    >
      <v-expansion-panel
        v-for="(campaign,indexc) in campaigns"
        :key="indexc"
      >
        <v-expansion-panel-header class="header">
          {{ campaign.campaign.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Start Section Campaign -->
          <EditCampaign
            :initial-campaign="campaign"
            @updateCampaign="showUpdated=true"
            @deletedImage="showDeleted=true"
          />
          <!-- Section Collection -->
          <v-container justify="center">
            <!--              v-for="collection in campaign.collection"-->
            <!--              :key="collection.title"-->
            <v-card raised>
              <v-data-table
                :headers="headers"
                :items="campaign.collection"
                :search="search"
                item-key="id"
                sort-by="internalIdentifier"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    color="white"
                  >
                    <v-toolbar-title
                      dark
                      class="header"
                    >
                      Collection Points
                    </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-spacer />
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      color="donorAccent1"
                    />
                    <v-spacer />
                    <v-dialog
                      v-model="dialog[indexc]"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="donorAccent1"
                          dark
                          class="mb-2"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>Add Collection Point
                        </v-btn>
                      </template>
                      <AddCollectionPoint
                        :initial-campaign="campaign"
                        :initial-index="indexc"
                        @click="CloseAddCollectionPoints"
                        @addedCollectionPoint="showAdded=true"
                      />
                    </v-dialog>
                  </v-toolbar>
                </template>
                <!-- Section Edit Internal Identify -->
                <template v-slot:item.identify="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.internalIdentifier"
                    large
                    @save="save(campaign)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div>
                      {{ props.item.internalIdentifier }}
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 title">
                        Update identify
                      </div>
                    </template>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.internalIdentifier"
                        :maxlength="30"
                        :rules="[max30chars]"
                        label="Edit"
                        counter
                        autofocus
                      />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- Section Edit Name -->
                <template v-slot:item.title="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.title"
                    large
                    @save="save(campaign)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div class="style-text">
                      {{ props.item.title }}
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 title">
                        Update Name
                      </div>
                    </template>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.title"
                        :maxlength="30"
                        :rules="[max30chars]"
                        label="Edit"
                        counter
                        autofocus
                      />
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- Section Edit Short Code -->
                <template v-slot:item.shortCode="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.shortCode"
                    large
                    @save="checkShortCode(props.item, campaign, indexc)"
                    @cancel="originalCode=''"
                    @open="openShortCodeEditing(props.item.shortCode)"
                    @close="close"
                  >
                    <div class="style-text">
                      {{ props.item.shortCode }}
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 title">
                        Update ShortCode
                      </div>
                    </template>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.shortCode"
                        :maxlength="8"
                        :rules="[max8chars]"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      />
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:items>
                  <v-text-field>{{ collections.shortCode }}</v-text-field>
                </template>
                <!-- Section Short Code Column -->
                <template v-slot:items>
                  <v-select
                    v-model="campaign.collection.paymentType"
                    :items="paymentItems"
                    placeholder="Choose Payment Type"
                    label="Payment Type"
                    color="donorAccent1"
                    class="donorAccent1--text"
                    outlined
                  />
                </template>
                <template v-slot:item.isVisible="{ item }">
                  <v-switch
                    v-model="item.isVisible"
                    color="donorAccent1"
                    @change="save(campaign)"
                  />
                </template>
                <!-- Section Active Column -->
                <template v-slot:item.active="{ item }">
                  <v-switch
                    v-model="item.isActive"
                    color="donorAccent1"
                    @change="save(campaign)"
                  />
                </template>
                <!-- Section Action Column -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                    class="mr-2"
                    @click="qrGenerator1(item)"
                  >
                    QR1
                  </v-btn>
                  <v-btn
                    small
                    class="mr-2"
                    @click="qrGenerator2(item)"
                  >
                    QR2
                  </v-btn>
                  <v-btn
                    v-clipboard="copyQr(item)"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"
                    small
                    class="mr-2"
                  >
                    LL
                  </v-btn>
                  <v-btn
                    v-clipboard="copyShortCode(item)"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"
                    small
                    class="mr-2"
                  >
                    SL
                  </v-btn>
                  <v-icon
                    small
                    color="red"
                    @click="deleteCollection(item, campaign)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Section Add Campaign -->
    <v-card-actions>
      <v-btn
        color="donorAccent1 white--text"
        class="mx-2"
        @click="switchNewCampaign()"
      >
        <v-icon>mdi-plus</v-icon>Add Campaign
      </v-btn>
    </v-card-actions>
    <CreateNewCampaign
      v-show="showCreate"
      :key="campKey"
      @click="switchNewCampaign"
      @updatedCampaign="showUpdated=true"
      @createdCampaign="showUpdated=true"
    />
  </v-card>
</template>

<script>
import { mapGetters} from "vuex";
import QRCode from "qrcode";
import EditCampaign from "../../components/EditCampaign";
import CreateNewCampaign from "../../components/CreateNewCampaign";
import AddCollectionPoint from "../../components/AddCollectionPoint";
import firebaseUpdate from "../../store/firebase/firebaseUpdate";
import firebaseQuery from "../../store/firebase/firebaseQuery";

export default {
  components: {
    EditCampaign,
    CreateNewCampaign,
    AddCollectionPoint,
  },
  data() {
    return {
      showDeleted:false,
      showUpdated:false,
      showAdded:false,
      loader2: false,
      showing:false,
      showingUpload:false,
      showWaring:false,
      load: false,
      dialog: [],
      originalCode: "",
      showCreate: false,
      focusable:true,
      popout:true,
      campKey:0,
      paymentItems: ["Donation", "Contribution"],
      notSpecified: " Not Specified",
      search: "",
      max8chars: v => v.length <= 8 || 'Input too long!',
      max30chars: v => v.length <= 30 || 'Input too long!', 
      headers: [
        {text: "Internal Identifier", align: "start", value: "identify", class: "clientLabel--text"},
        { text: "Name", value: "title",   class: "clientLabel--text" },
        { text: "Visible to Public", value: "isVisible", sortable: false,   class: "clientLabel--text" },
        { text: "Active Now", value: "active", sortable: false,   class: "clientLabel--text" },
        { text: "Short Code", value: "shortCode", sortable: false,   class: "clientLabel--text" },
        { text: "Payment Type", value: "paymentDes", sortable: false,   class: "clientLabel--text" },
        { text: "Actions", value: "actions", sortable: false,   class: "clientLabel--text" }
      ],
      collections: [],
      codeType: {
        SHORT_CODE : 0,
        QR_CODE : 1
      }
    };
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      userProfile: "userProfile"
    }),
    campaigns() {
      return this.$store.getters.campaigns;
    },
  },

  watch: {
    dialog: {
      handler(val) {
        val || this.close();
      },
      deep: true
    },
    loading(val) {
      if (!val) return;

      setTimeout(() => (this.loading = false), 3000);
    }
  },

  created() {
    if (this.user.data.id !== undefined) {
      this.$store.dispatch("campaigns");
      this.load = !this.load;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    save(cam) {
      this.updateCollection(cam);
    },
    cancel() {
    },
    open() {
    },
    close() {
    },

    openShortCodeEditing(code) {
      this.originalCode = code;
    },
    checkShortCode(item, cam, idx) {
      let code = item.shortCode
      firebaseQuery.queryCollectionPoint.queryByCheckingShortCode(
          code,(queriedRes) => {
            if (queriedRes === false) {
              this.save(cam)
            } else {
              this.showSnackbar("Short Code already exists.", "error")
              if (this.originalCode.length !== 0) {
                let collectionIndex = cam.collection.indexOf(item)
                this.campaigns[idx].collection[collectionIndex].shortCode = this.originalCode
              }
            }
            this.originalCode = "";
          },
      )
    },

    switchNewCampaign() {
      if (this.showCreate === true){
        this.showCreate = false;
      } else {
        this.forceRerender();
        this.showCreate = true;
      }
    },
    
    CloseAddCollectionPoints(index) {
      console.log(index)
      this.$set(this.dialog, index, false);
    },

    forceRerender() {
      this.campKey +=1;
    },
    showSnackbar(text, color){
      this.$store.dispatch('pushSnackbarInfo', {text: text, color: color, isShow: true})
    },
    deleteCollection(item, campaign) {
      if(confirm("Do you really want to delete?")) {
        let self = this
        firebaseUpdate.updateCollectionPoint.setCollectionPointIsArchived(this.user.data.id, campaign.id, item.id, () => {
          firebaseQuery.queryCollectionPoint.queryByUserIdAndCampaignId(self.user.data.id, campaign.id, (result) => {
            self.$store.dispatch("updateCollectionPointsByCampaignId", result)
          })
          self.showSnackbar("Success. You've deleted a collection point.", "success")
        })
      }
    },

    updateCollection(campaign) {
      let self = this
      self.$store.dispatch("updateCampaign", campaign).then(() => {
        self.showSnackbar("Success. You've updated a collection point.", "success")
      }).catch(() => {
        self.showSnackbar("Upload value of collection point error.", "error")
      });
    },

    getQrOrShortCodeUrl(code, type) {
      let url = `${window.location.origin}`
      url += (type === this.codeType.QR_CODE ? `/1/${code.qrCode}` : `/2/${code.shortCode}`)
      return url
    },

    // QR code with background and dark as opaque black and light as opaque white
    async qrGenerator1(code) {
      // Get URL to encode into QR
      const url = this.getQrOrShortCodeUrl(code,this.codeType.QR_CODE);

      // Load background image
      const qrBackgroundImage = new Image();
      qrBackgroundImage.src = require("@/assets/QRBackground.png");
      await qrBackgroundImage.decode();

      // Create drawing canvas
      const canvas = document.createElement("canvas");
      canvas.width = qrBackgroundImage.width;
      canvas.height = qrBackgroundImage.height;
      const ctx = canvas.getContext("2d");

      // Draw background on canvas
      ctx.drawImage(qrBackgroundImage, 0, 0);

      // Draw QR code on canvas
      const qrSize = 744.0;
      const qrXOffsetNudge = -8.0;
      const qrYOffset = 432.0;
      const qrCode = await QRCode.toCanvas(url, { 
        type: "image/png", 
        width: qrSize,
        color: {
          dark: "#000000FF",
          light: "FFFFFF00"
        }
      });
      ctx.drawImage(qrCode, (qrBackgroundImage.width - qrSize) / 2.0 + qrXOffsetNudge, qrYOffset, qrSize, qrSize);

      // Load font
      const kollektifFont = new FontFace('Kollektif-Regular', `url(${require('@/assets/fonts/Kollektif.ttf')})`);
      const loadedFont = await kollektifFont.load();
      document.fonts.add(loadedFont);
      
      // Draw short code text on canvas
      ctx.fillStyle = '#000000';
      ctx.font = '56px Kollektif-Regular';
      const shortCodeText = `short code: ${code.shortCode}`;
      const textWidth = ctx.measureText(shortCodeText).width;
      const textX = (canvas.width - textWidth) / 2;
      const textY = 1234.0;
      ctx.fillText(shortCodeText, textX, textY);

      // Download the QR code image
      const a = document.createElement("a");
      a.download = `${this.userProfile.title}_${code.shortCode}.png`;
      a.href = canvas.toDataURL();
      a.click();
    },

    // QR code with dark as opaque black and light as transparent white
    async qrGenerator2(code) {
      const url = this.getQrOrShortCodeUrl(code,this.codeType.QR_CODE);

      const gauge = 1000; // equal to the width of the frame in pixels. Change the value to adjust the resolution. Other lengths are based on it so as to facilitate scaling

      const width = gauge; // width of the frame, excluding shadow and margin
      const height = gauge;
      const margin = 0.00 * gauge; // white margin around the frame
      const canvas = document.createElement("canvas");
      canvas.width = width + 2 * margin;
      canvas.height = height + 2 * margin;
      const ctx = canvas.getContext("2d");

      // Draw the QR code
      const qrSide = 1.0 * gauge; // side length of the QR code square
      const xQr = margin + (width - qrSide) / 2; // x of the top-left vertex of the QR code square
      const yQr = 0.0 * gauge;
      const qrCode = await QRCode.toCanvas(url, {
        type: "image/png", 
        width: qrSide,
        color: {
          dark: "#000000FF",
          light: "FFFFFF00"
        }
      });
      ctx.drawImage(qrCode, xQr, yQr, qrSide, qrSide);

      // Download the QR code image
      const a = document.createElement("a");
      a.download = `${this.userProfile.title}_${code.shortCode}.png`;
      a.href = canvas.toDataURL();
      a.click();
    },

    copyQr(code) {
      return this.getQrOrShortCodeUrl(code, this.codeType.QR_CODE);
    },
    copyShortCode(code) {
      return this.getQrOrShortCodeUrl(code, this.codeType.SHORT_CODE);
    },
    clipboardSuccessHandler ({value}){
      this.showSnackbar("Success. The link has been copied to your clipboard.", "success")
      console.log('success', value);
    },
    clipboardErrorHandler ({value}) {
      this.showSnackbar("Failed to get the QR LINK. ", "error")
      console.log('error', value)
    },
  }
};
</script>
<style scoped>
img {
  height: 100px;
}
.lengthBox{
  max-width: 86%;
}
.length{
  min-width: 105px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-left:10px;
  margin-left:15px;
}
.second-row {
  height: 100px; 
}
@media screen and (min-width: 800px) {
  .column {
    flex: 1
  }
}
</style>