<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShowDialog"
      persistent
      class="d-flex"
    >
      <v-card class="mt-1">
        <v-card-title
          style="word-break: normal"
          class="headline justify-center text-center"
        >
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text
          style="word-break: normal"
          class="justify-center text-center"
        >
          <span>{{ dialogTextPrefix }}</span>
          <span  class="text-decoration-underline">{{ savedCardType }}</span>
          <span>{{ dialogTextSuffix }}</span>
          <span  class="text-decoration-underline">{{ setupCardType }}</span>
          <span>{{ dialogNewLineText }} </span>
        </v-card-text>

        <v-card-actions
          class="d-flex justify-space-around flex-row"
        >
          <v-btn
            class="ma-2"
            color="donorAccent1 white--text"
            @click="onRightBtnClick"
          >
            {{ rightBthText }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

  import popupConstant from "../constant/popupConstant";

  export default {
    data() {
      return {
        isShowDialog : false,
        dialogTitle: '',
        dialogTextPrefix: '',
        dialogTextSuffix: '',
        dialogNewLineText: '',
        rightBthText: '',
        hasMerchantId: false,
        savedCardType: "",
        setupCardType: ""
      }
    },
    methods: {
      showPaymentMethod(hasMerchantId, isSetupClick, isSetupStripe) {
        this.hasMerchantId = hasMerchantId

        this.dialogTitle = hasMerchantId ? popupConstant.PAYMARK_CLICK_TITLE : popupConstant.STRIPE_TITLE

        if (isSetupClick) {
          this.savedCardType = popupConstant.PAYMARK_CLICK_TITLE
          this.setupCardType = popupConstant.STRIPE_TITLE
          // this.dialogNewLineText = popupConstant.PAYMARK_CLICK_NEW_LINE_TEXT_PREFIX + popupConstant.STRIPE_TITLE + popupConstant.STRIPE_NEW_LINE_TEXT_SUFFIX
          this.dialogNewLineText = popupConstant.STRIPE_NEW_LINE_TEXT_SUFFIX
        }

        if (isSetupStripe) {
          this.savedCardType = popupConstant.STRIPE_TITLE
          this.setupCardType = popupConstant.PAYMARK_CLICK_TITLE
          // this.dialogNewLineText = popupConstant.STRIPE_NEW_LINE_TEXT_PREFIX + popupConstant.PAYMARK_CLICK_TITLE + popupConstant.PAYMARK_CLICK_NEW_LINE_TEXT_SUFFIX
          this.dialogNewLineText = popupConstant.PAYMARK_CLICK_NEW_LINE_TEXT_SUFFIX
        }

        if (!isSetupClick && !isSetupStripe) {
          this.dialogNewLineText = hasMerchantId ? popupConstant.PAYMARK_CLICK_NEW_LINE_TEXT_SUFFIX : popupConstant.STRIPE_NEW_LINE_TEXT_SUFFIX
        }

        this.dialogTextPrefix = hasMerchantId ? popupConstant.PAYMARK_CLICK_TEXT_PREFIX : popupConstant.STRIPE_TEXT_PREFIX
        this.dialogTextSuffix = hasMerchantId ? popupConstant.PAYMARK_CLICK_TEXT_SUFFIX : popupConstant.STRIPE_TEXT_SUFFIX
        this.isShowDialog = true
        this.rightBthText = popupConstant.BUTTON_TEXT_OK
      },
      hideDialog() {
        this.isShowDialog = false
        this.dialogTitle = ''
        this.dialogTextPrefix = ''
        this.dialogTextSuffix = ''
        this.dialogNewLineText = ''
        this.rightBthText = ''
      },
      onRightBtnClick() {
        this.hideDialog()
        this.$emit('goToPaymentPage', this.hasMerchantId);
      },
    }
  }
</script>
