<template>
  <v-card>
    <v-card-title>
      <span class="headline">New Collection Point</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              v-model="collection.internalIdentifier"
              label="Internal Identify"
              :maxlength="30"
              counter="30"
              color="donorAccent1"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              v-model="collection.title"
              label="Name"
              :maxlength="50"
              counter="50"
              color="donorAccent1"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                v-model="collection.shortCode"
                type="text"
                :maxlength="8"
                counter="8"
                label="Short Code"
                :rules="[
                  v => v.length > 0 ? ((allShortCodes.indexOf(v) > -1) ? 'Short Code already exists.' : true) :true]"
                color="donorAccent1"
              />
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-select
              v-model="paymentSelectedItem"
              :items="paymentItems"
              item-text="paymentDes"
              item-value="paymentType"
              placeholder="Choose Payment Type"
              label="Payment Type"
              color="donorAccent1"
              class="donorAccent1--text"
              return-object
              @change="onChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="clientAccent1"
        text
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="donorAccent1"
        text
        :disabled="collection.internalIdentifier === '' || collection.shortCode === '' || !paymentSelectedItem"
        @click="save"
      >
        Save
      </v-btn>
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Error
          </v-card-title>
          <v-card-text>Failed to save, please try again</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="donorAccent1"
              text
              @click="dialog = !dialog"
            >
              DISMISS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Vue from "vue";
  import firebaseQuery from "../store/firebase/firebaseQuery";

  export default {
    name: "AddCollectionPoint",
    // eslint-disable-next-line vue/require-prop-types
    props: ["initialCampaign","initialIndex"],

    data: function() {
      return {
        collection: {
          title: "",
          internalIdentifier: "",
          shortCode: "",
          isVisible: true,
          isActive: true,
          lazy: false,
          allShortCodes: []
        },
        paymentItems:[
          { paymentDes : "Donation", paymentType : 0 },
          { paymentDes : "Contribution", paymentType : 1 },
        ],
        paymentSelectedItem: null,
        dialog: false,
      };
    },
    computed: {
      campaign: function () {
        return this.initialCampaign
      },
      CampaignIndex: function() {
        return this.initialIndex
      },
    },
    mounted() {
      this.CheckSCRules()
    },
    methods :{
      CheckSCRules: function() {
        let self = this
        firebaseQuery.queryCollectionPoint.queryAllShortCode((queriedRes) => {
          self.allShortCodes = queriedRes
        })
      },
      onChange() {
        this.collection.paymentType = this.paymentSelectedItem.paymentType
        this.collection.paymentDes = this.paymentSelectedItem.paymentDes
      },
      validate () {
        return this.$refs.form.validate();
      },
      setCollectionDefaultValue() {
        this.collection.title = ""
        this.collection.internalIdentifier = ""
        this.collection.shortCode = ""
        this.collection.isVisible = true
        this.collection.isActive = true
        this.collection.paymentType = 0
        this.paymentSelectedItem = null
      },
      cancel() {
        this.$emit('click', this.CampaignIndex);
        this.setCollectionDefaultValue()
      },
      showSnackbar(text, color) {
        this.$store.dispatch('pushSnackbarInfo', {text: text, color: color, isShow: true})
      },
      save() {
        if (this.validate()) {
          this.campaign.collection.push(Vue.util.extend({}, this.collection));
          this.$store
              .dispatch("updateCampaign", this.campaign)
              .then(() => {
                this.$emit('click', this.CampaignIndex);
                this.showSnackbar("Success. You've added a collection point.", "success")
              })
              .catch(() => {
                this.showSnackbar("Failed to add a collection point.", "error")
              }).finally(()=>{
            this.setCollectionDefaultValue()
          })
        }else {
          this.showSnackbar("Short Code already exists.", "error")
        }
      },
    },
  }
</script>

<style scoped>

</style>