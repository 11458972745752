<template>
  <div>
    <navbar />
    <div style="margin: 56px auto; max-width: 1024px;">
      <router-view />
    </div>
  </div>
</template>

<script>
import navbar from "../../components/NavBar";
import { mapGetters } from "vuex";

export default {
  components: {
    navbar
    //profile
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
};
</script>