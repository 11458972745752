<template>
  <v-card>
    <v-snackbar
      v-model="isShow"
      :timeout="5000"
      top
      centered
      :color="color"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="pl-0 pr-0 ml-0 mr-0"
          text
          v-bind="attrs"
          @click="onClick"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {
      isShow: false,
      text: "",
      color: "",
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      snackbarList: 'snackbarList'
    })
  },
  mounted() {
    this.snackbarListener()
  },
  methods: {
    snackbarListener() {
      let self = this
      setInterval(() => {
        if (self.snackbarList.length > 0) {
          let snackbarInfo = self.snackbarList.pop()
          self.isShow = snackbarInfo.isShow
          self.text = snackbarInfo.text
          self.color = snackbarInfo.color
        }
      }, 1)
    },
    onClick() {
      this.isShow = false
    }
  }
}
</script>