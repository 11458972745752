<template>
  <v-container class="pa-0">
    <v-subheader>My Favourites</v-subheader>
    <v-layout
      align-center
      align-content-center
      justify-center
      text-center
      column
    >
      <v-col cols="12" sm="6" md="3" style="max-width: 90%">
        <v-autocomplete
          v-model="selectedOrganisation"
          auto-select-first
          clearable
          :items="allOrganisations"
          :search-input.sync="searchInput"
          label="Select an organisation"
          color="donorAccent1"
          dense
          item-text="title"
          return-object
          full-width="true"
          outlined
          :menu-props="menuProps"
          @change="checkDuplicate(selectedOrganisation)"
        />
      </v-col>

      <v-divider />
    </v-layout>
    <v-list-item
      style="display: block"
      v-for="item in favourites"
      :key="item.clientTitle"
      width="90vw"
    >
      <v-container style="display: flex;">
        <v-list-item-content>
          <v-list-item-title v-text="item.clientTitle" />
          <v-list-item-subtitle v-text="`Donor Number: ${item.donorNumber}`" />
          <v-list-item-subtitle v-text="`YTD Total: ${item.ytdTotal}`" />        
        </v-list-item-content>

        <v-list-item-action>
          <v-row>
            <v-icon class="mr-4" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon class="mr-2" @click="deleteFav(item)"> mdi-delete </v-icon>
          </v-row>
        </v-list-item-action>
      </v-container>
      <v-btn style="width:100%" class="mt-n6" color="donorAccent1 white--text" v-if = "item.donateNowQrCode" @click="onClick(item.donateNowQrCode)">
        Give Now
      </v-btn>
      <v-divider />
    </v-list-item>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title
          style="word-break: normal"
          class="justify-center text-center"
        >
          Add your Donor number for {{ selectedOrganisation }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="donorNumber"
            class="col-8 justify-center"
            label="Donor Number"
            type="text"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="donorAccent1 white--text" text @click="cancelInput()">
            Cancel
          </v-btn>

          <v-btn
            color="donorAccent1 white--text"
            text
            @click="donorNumberInput()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="290">
      <v-card>
        <v-card-title
          style="word-break: normal"
          class="justify-center text-center"
        >
          Change your Donor number for {{ editingItem.clientTitle }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="editedDonorNumber"
            class="col-8 justify-center"
            label="Donor Number"
            type="text"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn color="donorAccent1 white--text" text @click="dialog2 = false">
            Cancel
          </v-btn>

          <v-btn
            color="donorAccent1 white--text"
            text
            @click="donorNumberEdit(editedDonorNumber)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate color="donorAccent1 white--text" />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import firebaseQuery from "../../store/firebase/firebaseQuery";

export default {
  name: "Favourites",
  data() {
    return {
      selectedOrganisation: "",
      searchInput: null,
      dialog: false,
      dialog2: false,
      editingItem: "",
      selectedOrganId: "",
      donorNumber: "",
      editedDonorNumber: "",
      overlay: true,
      favourites: [],
      headerNames: [
        {
          text: "Organisation Name",
          align: "start",
          sortable: false,
          value: "clientTitle",
          class: "clientLabel--text",
        },
        {
          text: "YTD Total",
          value: "ytdTotal",
          sortable: false,
          class: "clientLabel--text",
        },
        {
          text: "Donor Number",
          value: "donorNumber",
          sortable: false,
          class: "clientLabel--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "clientLabel--text",
        },
      ],
    };
  },
  computed: {
    menuProps() {
      let res = { closeOnContentClick: true };
      if (!this.searchInput) {
        res.value = false;
      } else if (this.searchInput && this.searchInput.length < 3) {
        res.value = false;
      }
      return res;
    },
    ...mapGetters({
      user: "user",
      userProfile: "userProfile",
    }),
    allOrganisations() {
      return this.$store.getters.organisations;
    },
  },
  watch: {
    searchInput(val) {
      return val;
    },
  },
  created() {
    this.$store.dispatch("organisations");
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.allOrganisations && this.allOrganisations.length > 0) {
        this.loadFavouriteData(() => {
          this.changeLoadingState(false);
          clearInterval(this.timer);
        });
      }
    }, 1000);
  },
  methods: {
    onClick(donateNowQrCode) {
      
      this.$router.push(
        `/donor/result/${donateNowQrCode}`
        // === "/donor/result/" + donateNowQrCode
      );
    },
    changeLoadingState(isLoading) {
      this.overlay = isLoading;
    },
    loadFavouriteData(callback) {
      firebaseQuery.queryClientProfile.queryFavourite(
        this.user.data.id,
        this.allOrganisations,
        (result) => {
          this.favourites = result;
          callback();
        }
      );
    },

    showSnackbar(text, color) {
      this.$store.dispatch("pushSnackbarInfo", {
        text: text,
        color: color,
        isShow: true,
      });
    },
    cancelInput() {
      this.dialog = false;
      this.donorNumber = "";
      this.selectedOrganisation = "";
    },
    checkDuplicate(item) {
      if (typeof item === "undefined") {
        return;
      }
      this.selectedOrganId = item.clientId;
      // If no favourite organisations, show the add dialog directly.
      if (this.favourites && this.favourites.length === 0) {
        this.selectedOrganisation = item.title;

        this.dialog = true;
      } else {
        // Check the selected organisation is already in favourites
        let dupData = this.favourites.filter(
          (o) => o.clientId === this.selectedOrganId
        );
        // this.selectedOrganisation = item.organisation.title;
        this.selectedOrganisation = item.title;

        // if (typeof (dupData) === "undefined"){
        if (dupData.length === 0) {
          // Couldn't find this organisation in favourites, show add dialog
          this.dialog = true;
        } else {
          // already in favourites, show snackbar.
          this.cancelInput();
          this.showSnackbar(
            "You have already added this organisation.",
            "error"
          );
        }
      }
    },

    donorNumberInput() {
      let fav = {
        clientId: this.selectedOrganId,
        donorNumber: this.donorNumber,
      };

      this.$store
        .dispatch("createFavourite", fav)
        .then(() => {
          this.showSnackbar(
            "Success. You've added a favourite organisation.",
            "success"
          );
        })
        .catch(() => {
          this.showSnackbar(
            "Failed to add a new favourite organisation.",
            "error"
          );
        })
        .finally(() => {
          this.changeLoadingState(true);
          this.loadFavouriteData(() => {
            this.changeLoadingState(false);
          });
          this.cancelInput();
        });
    },

    deleteFav(item) {
      this.$store
        .dispatch("deleteFavourite", item)
        .then(() => {
          this.showSnackbar(
            "Success. You've deleted a favourite organisation.",
            "success"
          );
        })
        .catch(() => {
          this.showSnackbar(
            "Failed to delete a favourite organisation.",
            "error"
          );
        })
        .finally(() => {
          this.changeLoadingState(true);
          this.loadFavouriteData(() => {
            this.changeLoadingState(false);
          });
        });
    },
    editItem(item) {
      this.editingItem = item;
      this.editedDonorNumber = item.donorNumber;
      this.dialog2 = true;
    },
    donorNumberEdit(number) {
      this.editingItem.donorNumber = number;
      this.$store
        .dispatch("updateFavourite", this.editingItem)
        .then(() => {
          this.showSnackbar(
            "Success. You've edited the donor number.",
            "success"
          );
        })
        .catch(() => {
          this.showSnackbar("Failed to edit the donor number.", "error");
        })
        .finally(() => {
          this.changeLoadingState(true);
          this.loadFavouriteData(() => {
            this.changeLoadingState(false);
          });
        });
      this.dialog2 = false;
      this.editedDonorNumber = "";
    },
  },
};
</script>
<style scoped>
/*
.v-list-item {
  display: block;
}

.v-list-item .container {
  display: flex;
}

.v-list-item .v-btn {
  width: 100%;
}
*/
</style>