<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShowDialog"
      persistent
      class="d-flex"
    >
      <v-card class="mt-1">
        <v-card-title
          style="word-break: normal"
          class="headline justify-center text-center"
        >
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text
          id="popupTextId"
          style="word-break: normal"
        >
          <div class="text-center">By continuing you agree to Giv2’s standard T&C – Key things to know:</div>
          <ul>
            <li>Info you provide creates a secure Giv2 profile so you can make instant donations to any NZ Charity on Giv2.</li>
            <li>Giv2 does not store your credit card details but you agree to our payment processing partners (Stripe.com or Paymark.co.nz) securely collecting and storing these as per their standard T&Cs.</li>
            <li>You agree that in providing your credit card details and completing a donation (“Give”), you authorise Giv2 to process the payment from your card to the nominated NZ Charity.</li>
          </ul>
          <div class="text-center">For full T&Cs see our <a class="text-decoration-underline" href="https://www.giv2.co.nz/terms">website</a>.</div>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-space-around flex-row"
        >
          <v-btn
            id="popupLeftBtnId"
            color="grey lighten-1 black--text"
            @click="onLeftBtnClick"
          >
            {{ leftBtnText }}
          </v-btn>
          <v-btn
            id="popupRightBtnId"
            class="ma-2"
            color="donorAccent1 white--text"
            @click="onRightBtnClick"
          >
            {{ rightBthText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

  import popupConstant from "../constant/popupConstant";
  import firebaseUpdate from "../store/firebase/firebaseUpdate";

  export default {
    data() {
      return {
        isShowDialog : false,
        buttonLayout: "row",
        dialogTitle: '',
        dialogText: '',
        leftBtnText: '',
        rightBthText: '',
        hasMerchantId: '',
        userId: '',
      }
    },
    methods: {
      showTermsAndConditionPopup(userId) {
        this.userId = userId
        this.isShowDialog = true
        this.dialogTitle = popupConstant.TERMS_AND_CONDITION_TITLE
        this.dialogText  = popupConstant.TERMS_AND_CONDITION_TEXT
        this.leftBtnText = popupConstant.BUTTON_TEXT_CANCEL
        this.rightBthText = popupConstant.BUTTON_TEXT_ACCEPT
      },
      hideDialog() {
        this.userId = ''
        this.hasMerchantId = false
        this.dialogTitle = ''
        this.dialogText  = ''
        this.isShowDialog = false
        this.leftBtnText = ""
        this.rightBthText = ""
      },
      onLeftBtnClick() {
        this.$emit('cancelTermsAndCondition', "")
        this.hideDialog()
      },
      onRightBtnClick() {
        this.$emit('acceptTermsAndCondition', "")
        this.saveTermsAndConditionsAcceptInfo()
        this.hideDialog()
      },
      saveTermsAndConditionsAcceptInfo() {
        firebaseUpdate.updateUserProfile.setTermsAndConditionAcceptInfo(this.userId,function(){})
      }
    }
  }
</script>
