import Vue from 'vue';
import VueRouter from 'vue-router';
import ClientDashboard from '../views/client/Dashboard';
import Login from './../components/LoginPage';
import AdminLogin from './../components/AdminLogin';
import ClientProfile from "./../views/client/ClientProfile";
import ManageCampaign from './../views/client/ManageCampaign';
import Reporting from './../views/client/Reports';
import DonorDashboard from "../views/donor/Dashboard";
import QrScannerSearch from "./../views/donor/QrScannerSearch";
import StripeSetup from "./../views/donor/StripeSetup";
import ClickSetup from "./../views/donor/ClickSetup";
import MultipleCCProfile from "../views/donor/MultipleCCProfile";
import DonorTranscation from "./../views/donor/Transcation";
import PaymentMethods from "../views/donor/PaymentMethods";
import ClientCampaignCollectionPoint from "../views/donor/ClientCampaignCollectionPoint";
import ThankYou from "../views/donor/ThankYou";
import ManagePayments from "../views/admin/ManagePayments";
import store from "./../store/index"
import Favourites from "./../views/donor/Favourites";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/jjjkjjgiugufy56',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: "/1/:qrCode",
    props: true,
    redirect : { name: 'resultWithQrCode'},
    meta: { requiresAuth: false }
  },
  {
    path: "/2/:shortCode",
    props: true,
    redirect : { name: 'resultWithShortCode'},
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: ClientDashboard,
    children: [
      {
        path: 'clientProfile',
        name: 'ClientProfile',
        component: ClientProfile,
        meta: { requiresAuth: true }
      },      
      {
        path: 'manageCampaign',
        name: 'manageCampaign',
        component: ManageCampaign,
        meta: { requiresAuth: true }
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reporting,
        meta: { requiresAuth: true }
      },
      {
        path: 'managePayments',
        name: 'managePayments',
        component: ManagePayments,
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const routerAdminCheck = store.state.user.adminLoggedIn;
          if(routerAdminCheck){
            next();
          }else{
            next({ path: '/' })
          }
        }
      },
    ]
  },
  {
    path: '/donor',
    name: "donorDashboard",
    component: DonorDashboard,
    children: [
      {
        path: 'qrScanner',
        name: 'qrScanner',
        component: QrScannerSearch,
        meta: { requiresAuth: true }
      },
      {
        path: 'favourites',
        name: 'favourites',
        component: Favourites,
        meta: { requiresAuth: true }
      },
      {
        path: 'profile',
        name: 'profile',
        component: MultipleCCProfile,
        meta: { requiresAuth: true }
      },
      {
        path: 'stripeSetup',
        name: 'stripeSetup',
        component: StripeSetup,
        meta: { requiresAuth: true }
      },
      {
        path: 'clickSetup/:qrCode',
        name: 'clickSetup',
        component: ClickSetup,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'transcation',
        name: 'transcation',
        component: DonorTranscation,
        meta: { requiresAuth: true }
      },
      {
        path: 'paymentMethods/:amount',
        name: 'paymentMethods',
        component: PaymentMethods,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'result/:qrCode',
        name: 'resultWithQrCode',
        props: true,
        component: ClientCampaignCollectionPoint,
        meta: { requiresAuth: false }
      },
      {
        path: 'result/:shortCode',
        name: 'resultWithShortCode',
        props: true,
        component: ClientCampaignCollectionPoint,
        meta: { requiresAuth: false }
      },
      {
        path: 'result',
        component: ClientCampaignCollectionPoint,
        meta: { requiresAuth: true }
      },
      {
        path: 'thanks/:id',
        name: 'thanks',
        props: true,
        component: ThankYou,
        meta: { requiresAuth: true }
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next)=>{
  let routerAuthCheck = store.state.user.loggedIn;
  if (to.matched.some(record => record.meta.requiresAuth)) {
     if(routerAuthCheck){
       next();
     }else{
      next({
        path: '/',
      })
     }
  }
  else{
    next()
  }
})
export default router
