import Vue from 'vue'
import Vuetify, { colors } from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        clientLabel: colors.blueGrey.darken1,//#546E7A
        clientValue: colors.blueGrey.darken4,//#263238
        clientAccent1: colors.orange.darken1,//#FB8C00
        clientAccent2: colors.orange.lighten1,
        donorLabel: colors.blueGrey.darken1,//#546E7A
        donorAccent1: colors.cyan.lighten1,//#26C6DA
        donorValue: colors.cyan.darken4,//#006064
        clientHeaderBarAccent: colors.grey.lighten1,
      },
    },
  },
})
