<template>
  <div>
    <div
      v-show="isShowCharityInfo"
      id="container"
      :style="{ height: `${pageBodyHeight}px` }"
    >
      <v-container
        class="px-0 py-0"
        :loading="loading"
      >
        <v-card-title
          style="word-break: normal"
          :class="organisationTitleStyle"
        >
          {{ pageTitle() }}
        </v-card-title>

        <div class="image_container">
          <v-img
            class="background"
            :src="imageUrl"
            @load="onImageLoaded"
          />

          <div class="white_bg" />

          <v-btn
            icon
            class="float-right aboved_icon"
            color="donorAccent1"
            @click="onTriggerBadgePopup"
          >
            <v-icon color="donorAccent1">
              mdi-information
            </v-icon>
          </v-btn>
        </div>

        <v-card-title
          v-if="
          queryCollectionPoint.title !== '' &&
            queryCampaign.title !== '' &&
            queryCollectionPoint.isVisible
        "
          style="word-break: normal"
          :class="campaignAndCpTitleStyle"
        >
          {{ queryCampaign.title }} - {{ queryCollectionPoint.title }}
        </v-card-title>
        <v-card-title
          v-else-if="
          !queryCollectionPoint.title &&
            queryCampaign.title !== '' &&
            queryCollectionPoint.isVisible
        "
          style="word-break: normal"
          :class="campaignAndCpTitleStyle"
        >
          {{ queryCampaign.title }}
        </v-card-title>
        <v-card-title
          v-else-if="
          queryCollectionPoint.title !== '' &&
            !queryCampaign.title &&
            queryCollectionPoint.isVisible
        "
          style="word-break: normal"
          :class="campaignAndCpTitleStyle"
        >
          {{ queryCollectionPoint.title }}
        </v-card-title>
        <v-card-title
          v-else-if="
          queryCampaign.title !== '' && !queryCollectionPoint.isVisible
        "
          style="word-break: normal"
          :class="campaignAndCpTitleStyle"
        >
          {{ queryCampaign.title }}
        </v-card-title>

        <v-card-text
          style="word-break: normal"
          :class="organisationOrCampaignDescriptionStyle"
        >
          <div v-if="queryCampaign.description !== ''">
            {{ queryCampaign.description }}
          </div>
          <div v-else-if="queryClient.description !== ''">
            {{ queryClient.description }}
          </div>
        </v-card-text>

        <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
          <v-progress-circular
            indeterminate
            color="donorAccent1 white--text"
          />
        </v-overlay>
        <Popup
          ref="paymentConfirmPopup"
          @acceptPayment="acceptPayment"
          @refusePayment="refusePayment"
        />
        <BadgePopup ref="badgePopup" />
        <PaymentMethodPopup ref="paymentMethodPopup" @goToPaymentPage="goToClickOrStripePaymentPage"/>
      </v-container>
      <v-container id="donateNowContainer" class="py-0 stickToBottom">
        <v-card-title
          class="justify-center py-1 text-subtitle-1 font-weight-bold"
        >
          Donate Now
        </v-card-title>
        <v-row
          justify="center"
          no-gutters
          class="mb-n4"
        >
          <v-col
            v-for="(value, index) in values"
            :key="index"
            :cols="dollarButtonCol"
            align="center"
          >
            <v-btn
              fab
              :color="index === selectedIndex ? cyanColorStyle : greyColorStyle"
              @click="changeAmount(index)"
            >
              ${{ values[index] }}
            </v-btn>
          </v-col>
          <v-col
            :cols="4"
            justify="end"
          >
            <v-text-field
              v-model="inputtedAmount"
              label="Other"
              type="text"
              color="donorAccent1"
              class="ml-5 mr-0"
              outlined
              @click.once="clickOtherAmount"
              @click="clickAmount"
              @keypress="onInputAmount"
              @input="InputText"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col :cols="11">
            <v-btn
              block
              :disabled="buttonDisabled"
              :color="cyanColorStyle"
              @click="onGive"
            >
              GIVE
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-card-text
            style="word-break: normal"
            :class="organisationOrCampaignDescriptionStyle"
          >
            {{ declaration }}
          </v-card-text>
        </v-row>
      </v-container>
    </div>
    <ArchivedCPPopup ref="archivedCPPopup" @goToScannerPage="goToScannerPage"/>
    <TermsAndConditionPopup ref="termsAndConditionPopup" @acceptTermsAndCondition="afterClickTermsAndConditionsAccept" @cancelTermsAndCondition="cancelTermsAndCondition" />
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import cloudFunction from "../../helpers/cloudFunction.js";
import { punctuation } from "../../constant/punctuationConstant";
import firebaseQuery from "../../store/firebase/firebaseQuery";
import {collectionPointFields, termsAndConditionInfo} from "../../constant/firebaseConstants";
import Popup from "../../components/Popup";
import BadgePopup from "../../components/BadgePopup";
import PaymentMethodPopup from "../../components/PaymentMethodPopup";
import ArchivedCPPopup from "../../components/ArchivedCPPopup";
import TermsAndConditionPopup from "../../components/TermsAndConditionPopup";
import cardControl from "../../store/firebase/cardControl";
import { snackbarConstant } from "../../constant/snackbarConstant";
import localStorage from "../../helpers/localStorage";
import { errorMessageMap } from "../../constant/errorMessageConstants";
import popupConstant from "../../constant/popupConstant";
export default {
  components: {
    Popup,
    BadgePopup,
    PaymentMethodPopup,
    ArchivedCPPopup,
    TermsAndConditionPopup
  },
  props: {
    id: {
      type: String,
      default: () => {
        return "";
      },
    },
    qrCode: {
      type: String,
      default: () => {
        return "";
      },
    },
    shortCode: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      buttonDisabled: false,
      loading: false,
      selection: 1,
      selectedIndex: 0,
      greyColorStyle: "grey lighten-1 black--text",
      cyanColorStyle: "cyan lighten-1 white--text",
      values: [5, 10, 20],
      inputtedAmount: "",
      defaultImage: require("@/assets/charity_jar.jpg"),
      imageUrl: "",
      imageWidth: 0,
      imageHeight: 0,
      overlay: true,
      unAuthenticatedInfo: null,
      pageBodyHeight: 0,
      organisationTitleStyle:
        "justify-center text-center grey lighten-4 blue-grey--text py-1 px-2 title",
      campaignAndCpTitleStyle:
        "justify-center text-center blue-grey lighten-1 white--text py-1 px-2 title",
      organisationOrCampaignDescriptionStyle:
        "justify-center text-center grey--text px-2 py-2 description",
      declaration: "* Amounts are in NZ Dollars",
      dollarButtonCol: 2.67,
      isShowCharityInfo : true
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      queryCollectionPoint: "queryCollectionPoint",
      queryCampaign: "queryCampaign",
      queryClient: "queryClient",
    }),
  },
  mounted() {
    let self = this;
    self.queryByShortCodeOrQrCode(() => {
      self.paymentConfirm();
      self.resizeImage();
      self.emailAndCrownCheck();
      self.initDollarAmountOnButtons();
    });

    this.getPageBodyHeight();
  },
  beforeDestroy() {
    this.clearClientCampaignCollectionPoint();
  },
  methods: {
    initDollarAmountOnButtons() {
      if (this.queryCollectionPoint && this.queryCollectionPoint.dollarButtonAmounts) {
        this.values = this.queryCollectionPoint.dollarButtonAmounts || [];
        let dollarButtonAmountsLength =
          this.values.length == 0 ? 3 : this.values.length;
        this.dollarButtonCol = 8 / dollarButtonAmountsLength;
      } else {
        this.dollarButtonCol = 8 / 3;
      }
    },
    emailAndCrownCheck() {
      let self = this;
      firebaseQuery.queryClientProfile.queryEmailAndCrownForResultBadge(
        this.queryClient.uid,
        function(result) {
          if (result.isDonorTransactionReceiptOverridden) {
            self.$refs.badgePopup.initEmailIcon("mdi-email", "grey");
          } else {
            self.$refs.badgePopup.initEmailIcon("mdi-email", "donorAccent1");
          }

          if (result.irdNumber && result.charityNumber) {
            self.$refs.badgePopup.initCrownIcon(
              "mdi-crown-outline",
              "donorAccent1"
            );
          } else {
            self.$refs.badgePopup.initCrownIcon("mdi-crown", "grey lighten-1");
          }
        }
      );
    },
    onTriggerBadgePopup() {
      this.$refs.badgePopup.show();
    },
    clearClientCampaignCollectionPoint() {
      this.$store.dispatch("queryCollectionPointByShortcodeOrQrCode", {
        client: {},
        campaign: {},
        collectionPoint: {},
      });
    },
    getCurrentShortCodeOrQrCode() {
      let resultMap = {};
      let unAuthenticatedInfo = localStorage.getUnAuthenticatedInfo()
      let qrCode;
      let shortCode;
      if (unAuthenticatedInfo) {
        qrCode = unAuthenticatedInfo[collectionPointFields.QR_CODE];
        shortCode = unAuthenticatedInfo[collectionPointFields.SHORT_CODE];
      } else if (this.qrCode) {
        qrCode = this.qrCode
      } else if (this.shortCode) {
        shortCode = this.shortCode
      }
      if (qrCode) {
        resultMap.fieldName = collectionPointFields.QR_CODE;
        resultMap.fieldValue = qrCode;
      } else if (shortCode) {
        resultMap.fieldName = collectionPointFields.SHORT_CODE;
        resultMap.fieldValue = shortCode;
      } else {
        this.clearClientCampaignCollectionPoint();
      }

      return resultMap;
    },
    setCodeToId(code) {
      this.id = code;
    },
    queryByShortCodeOrQrCode(callback) {
      let self = this;
      let codeObject = this.getCurrentShortCodeOrQrCode();
      firebaseQuery.queryCollectionPoint.queryByShortCodeOrQrCode(
        codeObject.fieldName,
        codeObject.fieldValue,
        (queriedInfo) => {
          if (queriedInfo) {
            self.setCodeToId(queriedInfo.collectionPoint.qrCode);
            self.$store.dispatch(
              "queryCollectionPointByShortcodeOrQrCode",
              queriedInfo
            );
            if (queriedInfo.collectionPoint.isArchived) {
              self.isShowCharityInfo = false
              self.$refs.archivedCPPopup.showPopup()
            }
          }
          callback();
        }
      );
    },
    refusePayment() {
      this.overlay = false;
      this.user.data.navGoBack = false;
      localStorage.setUnAuthenticatedInfo(null);
    },
    acceptPayment() {
      let qrCode = this.unAuthenticatedInfo.qrCode;
      let amount = this.unAuthenticatedInfo.amount;
      let ids = qrCode.split(punctuation.HYPHEN);
      let data = {
        clientId: ids[0],
        campaignId: ids[1],
        collectionPointId: ids[2],
        amount: amount,
      };
      let self = this;
      self.overlay = true;
      cloudFunction.give(data, function(error, result) {
        self.overlay = false;
        if (error) {
          if (error.message) {
            self.goToPaymentPage(error.message);
          }
        } else {
          self.$router.push(
            `/donor/thanks/${result.transactionId}${punctuation.AND}${qrCode}`
          );
        }
      });
    },
    paymentConfirm() {
      this.unAuthenticatedInfo = localStorage.getUnAuthenticatedInfo();
      if (this.unAuthenticatedInfo) {
        if (!this.user.loggedIn) {
          this.$router.push(`/`);
          return;
        }

        let self = this;
        firebaseQuery.queryUserProfile.queryAcceptTermsAndCondition(self.user.data.id, function(termsAndCondition){
          if (!termsAndCondition || !termsAndCondition[termsAndConditionInfo.HAS_ACCEPTED_TERMS_AND_CONDITIONS]) {
            self.$refs.termsAndConditionPopup.showTermsAndConditionPopup(self.user.data.id);
          } else {
            self.hasMerchantId(function(hasMerchantId) {
              cardControl.fetchCard(self.user.data.id, hasMerchantId, (result) => {
                // check whether current user have set up credit card
                if (result) {
                  self.$refs.paymentConfirmPopup.showPayConfirm(
                    self.unAuthenticatedInfo.amount
                  );
                } else {
                  self.doPayment()
                }
              });
            });
          }
        })
      }
    },
    resizeImage() {
      let imgUrl = "";
      if (this.queryCampaign.imageUrl) {
        imgUrl = this.queryCampaign.imageUrl;
      } else if (this.queryClient.imageUrl) {
        imgUrl = this.queryClient.imageUrl;
      } else {
        imgUrl = this.defaultImage;
      }
      let self = this;
      let img = new Image();
      img.src = imgUrl;
      img.onerror = () => {
        self.imageUrl = self.defaultImage;
      }
      img.onload = () => {
        self.imageUrl = imgUrl;
      };
    },
    showSnackbar(text, color) {
      this.$store.dispatch("pushSnackbarInfo", {
        text: text,
        color: color,
        isShow: true,
      });
    },
    afterClickTermsAndConditionsAccept() {
      this.unAuthenticatedInfo = localStorage.getUnAuthenticatedInfo();
      if (this.unAuthenticatedInfo) {
        if (!this.user.loggedIn) {
          this.$router.push(`/`);
          return;
        }

        let self = this;
        self.hasMerchantId(function(hasMerchantId) {
          cardControl.fetchCard(self.user.data.id, hasMerchantId, (result) => {
            // check whether current user have set up credit card
            if (result) {
              self.$refs.paymentConfirmPopup.showPayConfirm(
                self.unAuthenticatedInfo.amount
              );
            } else {
              self.doPayment()
            }
          });
        });
      } else {
        this.doPayment()
      }
    },
    doPayment() {
      let self = this;
      let amount = 0;
      if (self.inputtedAmount.length > 0) {
        let amountFloat = parseFloat(self.inputtedAmount);
        if (amountFloat < 2) {
          // if donated amount under $2, show snackbar
          self.showSnackbar(snackbarConstant.message.MINIMUM_AMOUNT, snackbarConstant.color.WARNING)
          return;
        }
        amount = parseInt((amountFloat * 100).toFixed(0));
      } else {
        amount = self.values[self.selectedIndex] * 100;
      }

      this.unAuthenticatedInfo = localStorage.getUnAuthenticatedInfo();
      if (this.unAuthenticatedInfo) {
        amount = self.unAuthenticatedInfo.amount
      }

      self.overlay = true;
      self.buttonDisabled = true;
      this.$analytics.logEvent("select_item", {
        item_list_name: "give",
        value: amount,
      });

      let qrCode =
        self.queryClient.uid +
        punctuation.HYPHEN +
        self.queryCampaign.id +
        punctuation.HYPHEN +
        self.queryCollectionPoint.id;

      let data = {
        clientId: self.queryClient.uid,
        campaignId: self.queryCampaign.id,
        collectionPointId: self.queryCollectionPoint.id,
        amount: amount,
      };

      if (this.$route.name === 'resultWithShortCode') {
        localStorage.setUnAuthenticatedInfo({ shortCode: this.shortCode, amount: amount });
      } else if (this.$route.name === 'resultWithQrCode') {
        localStorage.setUnAuthenticatedInfo({ qrCode: qrCode, amount: amount });
      }


      if (!self.user.loggedIn) {
        self.$router.push(`/`);
        return;
      }

      cloudFunction.give(data, function(error, result) {
        self.buttonDisabled = false;
        self.overlay = false;
        if (error) {
          self.user.data.navGoBack = true;
          if (error.message) {
            self.goToPaymentPage(error.message);
          }
        } else {
          localStorage.setUnAuthenticatedInfo(null);
          self.$analytics.logEvent("donate_success", { value: amount });
          self.$router.push(
            `/donor/thanks/${result.transactionId}${punctuation.AND}${qrCode}`
          );
        }
      });
    },
    cancelTermsAndCondition() {
      this.overlay = false
      localStorage.setUnAuthenticatedInfo(null)
    },
    checkTermsAndConditionBeforeGive() {
      let self = this

      if (self.user.loggedIn) {
        firebaseQuery.queryUserProfile.queryAcceptTermsAndCondition(self.user.data.id, function(termsAndCondition){
          if (!termsAndCondition || !termsAndCondition[termsAndConditionInfo.HAS_ACCEPTED_TERMS_AND_CONDITIONS]) {
            self.$refs.termsAndConditionPopup.showTermsAndConditionPopup(self.user.data.id);
          } else {
            self.doPayment()
          }
        })
      } else {
        self.doPayment()
      }
    },
    onGive() {
      this.checkTermsAndConditionBeforeGive()
    },
    hasMerchantId(callback) {
      firebaseQuery.queryClientProfile.hasMerchantId(
        this.queryClient.uid,
        function(hasMerchantId) {
          callback(hasMerchantId);
        }
      );
    },
    showPaymentMethodPopup(hasMerchantId) {
      let self = this
      firebaseQuery.queryUserProfile.queryPaymentMethods(self.user.data.id,function(paymentMethods){
        paymentMethods = paymentMethods || []
        if (paymentMethods.length > 0) { // if no paymentMethod, do not need to show this popup
          const isSetupClick = !!paymentMethods.find(pm => pm.platformId == popupConstant.CLICK_PLATFORM_ID)
          const isSetupStripe = !!paymentMethods.find(pm => pm.platformId == popupConstant.STRIPE_PLATFORM_ID)
          self.$refs.paymentMethodPopup.showPaymentMethod(hasMerchantId, isSetupClick, isSetupStripe);
        } else {
          self.goToClickOrStripePaymentPage(hasMerchantId)
        }
      })
    },
    goToClickOrStripePaymentPage(hasMerchantId) {
      if (hasMerchantId) {
        this.$router.push(`/donor/clickSetup/${this.id}`);
      } else {
        this.$router.push(`/donor/stripeSetup`);
      }
    },
    showSnackbarWithSpecificErrorMessage(errorMessage) {
      if (errorMessageMap[errorMessage]) {
        errorMessage = errorMessageMap[errorMessage];
      }
      this.showSnackbar(errorMessage, snackbarConstant.color.ERROR);
    },
    goToScannerPage() {
      this.$router.push(`/donor/qrScanner`);
    },
    goToPaymentPage(errorMessage) {
      let self = this;
      if (errorMessage === "NO_PAYMENT_SETUP") {
        self.hasMerchantId(function(hasMerchantId) {
          self.showPaymentMethodPopup(hasMerchantId);
        });
      }
      this.showSnackbarWithSpecificErrorMessage(errorMessage)
    },
    pageTitle() {
      return this.queryClient.title || "";
    },
    changeAmount(index) {
      localStorage.setUnAuthenticatedInfo(null)
      this.inputtedAmount = "";
      this.selectedIndex = index;
      this.$analytics.logEvent("select_item", {
        item_list_name: "$" + this.values[index],
      });
      this.buttonDisabled = false;
    },
    onInputAmount($event) {
      localStorage.setUnAuthenticatedInfo(null)
      // set $5,10,20 button gray
      this.selectedIndex = -1;

      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.inputtedAmount.indexOf(".") !== -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.inputtedAmount != null &&
        this.inputtedAmount.indexOf(".") > -1 &&
        this.inputtedAmount.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    InputText() {
      this.$nextTick(() => {
        let inputtedAmountLength = this.inputtedAmount.length || 0;
        for (let i = 0; i < inputtedAmountLength; i++) {
          if (!/^\d+(\.\d{0,2})?$/.test(this.inputtedAmount)) {
            this.inputtedAmount = this.inputtedAmount.slice(
              0,
              this.inputtedAmount.length - 1
            );
          }
        }
        this.buttonDisabled = this.inputtedAmount.length === 0;
      });
    },
    onImageLoaded() {
      this.overlay = false;
      if (!this.unAuthenticatedInfo) {
        if (!this.user.loggedIn) {
          let showResultHint = localStorage.getIsShowResultHint();
          if (!showResultHint) {
            localStorage.setIsShowResultHint();
            this.$refs.paymentConfirmPopup.showUnAuthResultNotification();
          }
        }
      }
    },
    clickOtherAmount() {
      this.$analytics.logEvent("select_item", { item_list_name: "$other" });
    },
    clickAmount() {
      this.selectedIndex = -1;
      this.buttonDisabled = true;
    },
    getPageBodyHeight() {
      // get the page height without the header height
      const browserHeight = window.innerHeight;
      const headerHeight = document.getElementsByTagName("HEADER")[0]
        .offsetHeight;
      const donateNowContainerHeight = document.getElementById('donateNowContainer').clientHeight;
      this.pageBodyHeight = browserHeight + headerHeight + donateNowContainerHeight;
    },
  },
};
</script>

<style scoped>
#container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.stickToBottom {
  position: sticky;
  bottom: 0;
  background-color: #f5f5f5;
}
.title {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 22px;
}
.description {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
}
.image_container {
  position: relative;
}
.background {
  z-index: 0;
}
.white_bg {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 1;
  bottom: 0.5vh;
  right: 0.5vh;
}
.aboved_icon {
  position: absolute;
  z-index: 2;
  bottom: 0.5vh;
  right: 0.5vh;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  /*box-shadow: 0 3px 5px -1px red, 0 6px 10px 0 red, 0 1px 18px 0 red;*/
  /*-webkit-box-shadow: 0 3px 5px -1px red, 0 6px 10px 0 red, 0 1px 18px 0 red;*/
}
</style>
