<template>
  <v-container class="pa-0">
    <v-subheader>My profile</v-subheader>
    <v-card-text class="pt-0">
      <v-row no-gutters>
        <v-card-text
          style="word-break: normal"
          class="ma-0 pa-0"
        >
          <div>{{ decodeHtml(user.data.displayName) }}</div>
        </v-card-text>
      </v-row>
      <v-row no-gutters>
        <v-card-text
          style="word-break: normal"
          class="ma-0 pa-0"
        >
          <div>{{ user.data.email }}</div>
        </v-card-text>
      </v-row>
    </v-card-text>
    <v-divider />

    <v-subheader>My saved cards</v-subheader>
    <SavedCreditCards />

    <v-divider />

    <v-list three-line>
      <template>
        <!-- <v-list-item>
          <v-list-item-action>
            <v-checkbox
              id="marketingOptInCheckbox"
              v-model="isMarketingOptIn"
              :input-value="isMarketingOptIn"
              color="donorAccent1"
              @change="toggleMarketingOptIn"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-html="marketingToggleTitle" />
            <v-list-item-subtitle
              class="wrap-text"
              v-html="marketingToggleContent"
            />
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              id="transactionReceiptEnabledCheckbox"
              v-model="isTransactionReceiptEnabled"
              :input-value="isTransactionReceiptEnabled"
              color="donorAccent1"
              @change="toggleTransactionReceipt"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-html="notificationTitle" />
            <v-list-item-subtitle
              class="wrap-text"
              v-html="notificationContent"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-divider />

    <v-subheader>My recent giving</v-subheader>
    <div v-if="paymentsList && paymentsList.length > 0">
      <v-list-item
        v-for="item in paymentsList"
        :key="item.clientTitle"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.clientTitle" />
          <v-list-item-subtitle v-text="item.paidDate" />
        </v-list-item-content>

        <v-list-item-action>
          <span>{{ item.amountPaid }}</span>
        </v-list-item-action>
      </v-list-item>
    </div>
    <div v-else>
      <v-card-text>No transactions yet.</v-card-text>
    </div>

  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import firebaseQuery from "../../store/firebase/firebaseQuery";
import firebaseUpdate from "../../store/firebase/firebaseUpdate";
import SavedCreditCards from "../../components/SavedCreditCards";

export default {
  components: {
    SavedCreditCards,
  },
  data() {
    return {
      isEditing: null,
      imageUrl: null,
      picture: "",
      orangeColorStyle: "orange accent-4 white--text",
      unAuthenticatedInfo: null,
      headers: [
        { text: "Date", value: "paidDate" },
        { text: "Organisation", value: "clientTitle" },
        { text: "Amount", value: "amountPaid" },
      ],
      paymentsList: [],
      isMarketingOptIn: false,
      isTransactionReceiptEnabled: false,
      notificationTitle: "Transaction Receipts",
      notificationContent:
        "Email me a receipt for each donation I make. Untick to receive less emails and you can download a transaction history report later.",
      marketingToggleTitle: "Marketing Opt-in",
      marketingToggleContent:
        "Tick to share your email address with the organisations you donate to. You acknowledge you give them permission to contact you.",
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      userProfile: "userProfile",
    }),
  },
  mounted() {
    this.init();
    this.queryPayments();
  },
  beforeDestroy() {
    try {
      this.user.data.navGoBack = false;
      this.paymentsList = [];
    } catch (e) {
      console.log("beforeDestory e " + e);
    }
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    init() {
      let self = this;

      firebaseQuery.queryUserProfile.queryIsTransactionReceiptEnabled(
        self.user.data.id,
        (isTransactionReceiptEnabled) => {
          self.isTransactionReceiptEnabled = !!isTransactionReceiptEnabled;
        }
      );

      firebaseQuery.queryUserProfile.queryIsMarketingOptIn(
        self.user.data.id,
        (isMarketingOptIn) => {
          self.isMarketingOptIn = !!isMarketingOptIn;
        }
      );
    },
    queryPayments() {
      let self = this;
      firebaseQuery.queryTransactions.queryLastFiveTransactionsByUserId(
        this.user.data.id,
        (results) => {
          if (results && results.length > 0) {
            self.paymentsList = results;
          }
        }
      );
    },
    toggleMarketingOptIn() {
      let self = this;
      firebaseUpdate.updateUserProfile.setIsMarketingOptIn(
        self.user.data.id,
        self.isMarketingOptIn,
        () => {}
      );
    },
    toggleTransactionReceipt() {
      let self = this;
      firebaseUpdate.updateUserProfile.setIsTransactionReceiptEnabled(
        self.user.data.id,
        self.isTransactionReceiptEnabled,
        () => {}
      );
    },
  },
};
</script>
<style scoped>
img {
  width: 200px;
  height: 100px;
}
.savebtnColor.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: #26c6da !important;
  color: white !important;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
}
</style>

