<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShowDialog"
      persistent
    >
      <v-card class="mt-1 pt-8">
        <v-card-text
          style="word-break: normal"
          class="justify-center text-center"
        >
          <div class="text"> The QR code </div>
          <div class="text"> you scanned </div>
          <div class="text"> is no longer </div>
          <div class="text"> in use.</div>
        </v-card-text>

        <v-card-actions
          class="d-flex justify-space-around flex-row"
        >
          <v-btn
            class="buttonSize my-8"
            color="donorAccent1 white--text"
            @click="onRightBtnClick"
          >
            {{ rightBthText }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

  import popupConstant from "../constant/popupConstant";

  export default {
    data() {
      return {
        isShowDialog : false,
        rightBthText: '',
      }
    },
    methods: {
      showPopup() {
        this.isShowDialog = true
        this.rightBthText = popupConstant.BUTTON_TEXT_OK
      },
      hideDialog() {
        this.isShowDialog = false
        this.rightBthText = ''
      },
      onRightBtnClick() {
        this.hideDialog()
        this.$emit('goToScannerPage', "");
      },
    }
  }
</script>

<style scoped>
  .text {
    font-family: "Roboto", sans-serif !important;
    font-size: 80px !important;
    font-weight: bold;
    line-height: 100px;
    white-space: pre-line
  }
  .buttonSize {
    height : 150px;
    font-size: 40px !important;
  }
</style>
