<template>
  <div>
    <v-card class="overflow-hidden">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selectedPayouts"
              :headers="payoutHeaders"
              :items="payoutsUnpaidToClients"
              :items-per-page="10"
              item-key="clientId"
              show-select
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-toolbar-title>Payouts Unpaid to Clients</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-btn
                    color="donorAccent1 white--text"
                    @click="fetchPayoutsUnpaidToClients"
                  >
                    Fetch
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="donorAccent1 white--text"
              @click="payClients"
            >
              Pay Clients
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col>
            <v-data-table
              :headers="csvHeaders"
              :items="csvFiles"
              :items-per-page="10"
              sort-by="calories"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-toolbar-title>CSV Files</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-btn
                    color="donorAccent1 white--text"
                    @click="fetchCSVs"
                  >
                    Fetch
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      :href="encodedUri"
                      :download="item.name"
                      :ref="item.name"
                    ></a>
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadCSV(item)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download this CSV file</span>
                </v-tooltip>
                <v-tooltip
                  v-if="item.downloaded"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleCSVdownloadStatus(item.name, false)"
                    >
                      mdi-toggle-switch
                    </v-icon>
                  </template>
                  <span>Toggle the download status</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  name: 'ManagePayments',
  data() {
    return {
      loading: false,
      selectedPayouts: [],
      payoutHeaders: [
        { text: 'Client', value: 'clientName' },
        { text: 'Gross/$', value: 'gross' },
        { text: 'Giv2 Fee/$', value: 'giv2Fee' },
        { text: 'Stripe Fee/$', value: 'stripeFee' },
        { text: 'Net/$', value: 'net' }
      ],
      payoutsUnpaidToClients: [],
      payoutsPaidToClients: [],
      csvFiles: [],
      csvHeaders: [
        { text: 'File Name', value: 'name' },
        { text: 'Downloaded', value: 'downloaded' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      csvContent: ''
    };
  },
  computed: {
    encodedUri () {
      const metadata = "data:text/csv;charset=utf-8,";
      const content = metadata + this.csvContent;
      return encodeURI(content);
    }
  },
  methods: {
    fetchPayoutsUnpaidToClients() {
      this.loading = true;
      const adminGetPayoutsUnpaidToClients = firebase.functions().httpsCallable('admin-getPayoutsUnpaidToClients');
      adminGetPayoutsUnpaidToClients({})
      .then((result) => {
        if (result.data.code) throw new Error(result.data.message);
        if (result.data && result.data.length) {
          const payouts = result.data;
          payouts.forEach(payout => {
            payout.gross = (payout.totalPaid / 100).toFixed(2);
            payout.stripeFee = (payout.totalStripeFee / 100).toFixed(2);
            payout.giv2Fee  = (payout.totalGiv2Fee / 100).toFixed(2);
            payout.net = (payout.totalDonated / 100).toFixed(2);
          })
          this.payoutsUnpaidToClients = payouts;
        }
      })
      .catch(error => console.log('--Error fetching payouts unpaid to clients--', error))
      .finally(() => this.loading = false);
    },
    payClients() {
      if (!this.selectedPayouts.length) return alert('Please select a row to proceed.');
        
      this.loading = true;
      let selectedClientIds = [];
      this.selectedPayouts.forEach(payout => selectedClientIds.push(payout.clientId));

      const adminConfirmPaymentsToClients = firebase.functions().httpsCallable('admin-confirmPaymentsToClients');
      adminConfirmPaymentsToClients({ paymentsToClients: this.selectedPayouts })
      .then((result) => {
        if (result.data.code) throw new Error(result.data.message);
        if (!result.data.success) return alert('Confirmation Failed, please try again.');
          
        const payoutsUnpaidToClients = [];
        const payoutsPaidToClients = [];
        this.payoutsUnpaidToClients.forEach(d => {
          if (selectedClientIds.includes(d.clientId)) {
            payoutsPaidToClients.push(d);
          } else {
            payoutsUnpaidToClients.push(d);
          }
        })
        this.payoutsUnpaidToClients = payoutsUnpaidToClients;
        this.payoutsPaidToClients = payoutsPaidToClients;
        this.selectedPayouts = [];
        alert('Payment Successful.\nNow loading CSVs, please wait.');

        this.fetchCSVs();
      })
      .catch(error => console.log('--Error confirming payments to clients--', error))
      .finally(() => this.loading = false);
    },
    fetchCSVs() {
      this.loading = true;
      const adminGetCSVs = firebase.functions().httpsCallable('admin-getCSVs');
      adminGetCSVs({})
      .then((result) => {
        if (result.data.code) throw new Error(result.data.message);
        if (result.data) this.csvFiles = result.data.reverse();
      })
      .catch(error => console.log('--Error fetching CSV files--', error))
      .finally(() => this.loading = false);
    },
    downloadCSV(item) {
      const unwatch = this.$watch('csvContent', function () {
        this.$refs[item.name].click();
        if (!item.downloaded) this.toggleCSVdownloadStatus(item.name, true);
      });

      this.loading = true;
      const adminGetCSVcontent = firebase.functions().httpsCallable('admin-getCSVcontent');
      adminGetCSVcontent({ filePath: item.path })
      .then((result) => {
        if (result.data.code) throw new Error(result.data.message);
        if (result.data) this.csvContent = result.data;
      })
      .catch(error => console.log('--Error fetching CSV content--', error))
      .finally(() => {
        unwatch(); // Cancel the watcher, otherwise there would be more and more watchers that watch 'csvContent' and the callback would be fired more and more times.
        this.loading = false;
        this.csvContent = ''; // Clear the content, otherwise you can't download the same file consecutively because the watcher wouldn't fire the callback if the watched value is the same.
      });
    },
    toggleCSVdownloadStatus(name, downloaded) {
      this.loading = true;
      const toggleCSVdownloaded = firebase.functions().httpsCallable('admin-toggleCSVdownloaded');
      toggleCSVdownloaded({ name, downloaded })
      .then((result) => {
        if (result.data.code) throw new Error(result.data.message);
        if (result.data.success) {
          const files = this.csvFiles.map(csv => {
            if (csv.name === name) csv.downloaded = downloaded;
            return csv;
          });
          this.csvFiles = files;
        }
      })
      .catch(error => console.log('--Error toggling CSV download status--', error))
      .finally(() => this.loading = false);
    }
  }
}
</script>
