export const snackbarConstant = {
  message : {
    CARD_SETUP_SUCCESS : "Success! You have added your credit card.",
    CARD_SETUP_ERROR : "Credit card could not setup. Please try again",
    CARD_DELETE : "Success! You have deleted your credit card.",
    CARD_SETUP_NOTIFICATION : "Please setup your credit card.",
    MINIMUM_AMOUNT : "The minimum amount that can be processed is $2.00",
    UNAUTHENTICATED_SIGN_IN : "Please sign in to secure your Giv2 account",
    CARD_DELETE_ERROR : "Sorry, something went wrong. Please try again."
  },
  color : {
    SUCCESS : "success",
    WARNING : "warning",
    ERROR : "error",
    INFO : "donorAccent1"
  }
}