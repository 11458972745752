import firebase from 'firebase/app';
import {collections} from "../../constant/firebaseConstants";

let firebaseCreate = {
  campaign: {
    addCampaign: (uid, campaign, callback) => {
      firebase.firestore().collection(collections.CLIENT_PROFILES).doc(uid)
        .collection(collections.CAMPAIGNS)
        .add(campaign)
        .then((campaign) => {
          let camId = campaign.id
          console.log("Campaign ID:", camId)
          callback(camId)
        })
        .catch((error) => {
          console.log('--Error creating a campaign', error);
          callback("")
        });
    }
  }
}


export default firebaseCreate