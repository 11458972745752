<template>
  <v-container class="pa-0">
    <div v-if="paymentMethods && paymentMethods.length > 0">
      <div
        v-for="(item, index) in paymentMethods"
        :key="index"
        id="example-2"
        class="cell example example2"
      >
        <v-container class="pa-4 py-0">
          <v-row>
            <v-col cols="11" class="pa-0 py-0">
              <v-card-text
                style="word-break: normal"
                class="ma-0 pa-0 py-0"
              >
                <div><span class="paymentMethodType">{{item.creditCardType}}</span></div>
              </v-card-text>
            </v-col>
            <v-col cols="1" class="pa-0 py-0">
              <v-icon
                color="grey"
                class="ma-0 my-0 py-0 trash-bin"
                @click="deleteCreditCard(item)"
              >
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12" class="pa-0">
              <v-row no-gutters class="my-0 py-0 mx-n1">
                <div class="field-card-number card-number">
                  <div
                    :class="stripeCardInfoStyle"
                    id="example2-card-number"
                    class="input empty"
                  />
                  <label
                    :class="stripeLabelMarginStyle"
                    for="example2-card-number"
                    data-tid="elements_examples.form.card_number_label"
                  >Card Number: {{item.cardNumber}}</label>
                  <div class="baseline" />
                </div>
              </v-row>
              <v-row no-gutters class="my-0 py-0  mx-n1">
                <v-col cols="6">
                  <div class="field-card-expiry half-width card-expiry">
                    <div
                      :class="stripeCardInfoStyle"
                      id="example2-card-expiry"
                      class="input empty"
                    />
                    <label
                      :class="stripeLabelMarginStyle"
                      for="example2-card-expiry"
                      data-tid="elements_examples.form.card_expiry_label"
                    >Expiry: {{item.expiryDate}}</label>
                    <div class="baseline" />
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="field-card-cvc half-width card-cvc">
                    <div
                      :class="stripeCardInfoStyle"
                      id="example2-card-cvc"
                      class="input empty"
                    />
                    <label
                      :class="stripeLabelMarginStyle"
                      for="example2-card-cvc"
                      data-tid="elements_examples.form.card_cvc_label"
                    >CVC: ***</label>
                    <div class="baseline" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-if="paymentMethods && paymentMethods.length > 1">
      <v-subheader class="my-15">Giv2 does not store your credit card details. We use secure online credit card processing providers. Charities on Giv2 can choose which they want to use. Having your details listed with both lets you donate to any charity in 3 clicks next time.</v-subheader>
    </div>
    <div v-if="paymentMethods && paymentMethods.length <= 0">
      <v-card-text>No saved cards.</v-card-text>
    </div>


    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="donorAccent1 white--text"
      />
    </v-overlay>
  </v-container>
</template>

<script>

  import firebaseQuery from "../store/firebase/firebaseQuery";
  import cloudFunction from "../helpers/cloudFunction";
  import {snackbarConstant} from "../constant/snackbarConstant";
  import { mapGetters } from 'vuex'

  export default {
    name: "CreditCards",
    // components: {
    //   StripeUI
    // },
    data() {
      return {
        paymentMethods : [],
        overlay: true,
        stripeLabelMarginStyle: "ml-2 mb-1",
        stripeCardInfoStyle: "ml-3 mb-1"
      }
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      })
    },
    mounted() {
      this.init()
    },
    methods: {
      showSnackbar(text, color) {
        this.$store.dispatch("pushSnackbarInfo", {
          text: text,
          color: color,
          isShow: true,
        });
      },
      deleteCreditCard(paymentMethodInfo) {
        let self = this
        this.overlay = true
        if (paymentMethodInfo) {
          cloudFunction.deletePaymentMethod({
            paymentMethodId : paymentMethodInfo.id
          }, function (response){
            if (response) {
              if (response.success) {
                firebaseQuery.queryUserProfile.queryPaymentMethods(self.user.data.id, (paymentMethods) => {
                  self.hideOverlay()
                  self.processPaymentMethods(paymentMethods)
                  self.showSnackbar(snackbarConstant.message.CARD_DELETE, snackbarConstant.color.SUCCESS)
                })
              } else {
                self.hideOverlay()
                self.showSnackbar(snackbarConstant.message.CARD_DELETE_ERROR, snackbarConstant.color.ERROR)
              }
            }
          })
        }
      },
      processPaymentMethods(paymentMethods) {
        paymentMethods = paymentMethods || []
        for(let i in paymentMethods) {
          let item = paymentMethods[i]
          let yearLastTwoDigits = "" + item.expiryYear || ""
          let expiryYearStringLength = yearLastTwoDigits.length
          if (expiryYearStringLength > 2) {
            yearLastTwoDigits = yearLastTwoDigits.substring(expiryYearStringLength - 2, expiryYearStringLength)
          }
          paymentMethods[i].expiryDate = item.expiryMonth + "/" + yearLastTwoDigits
          if (item.platformId) {
            paymentMethods[i].creditCardType = "Paymark - Click"
          } else {
            paymentMethods[i].creditCardType = "Stripe"
          }
        }
        this.paymentMethods = paymentMethods;
      },
      hideOverlay() {
        this.overlay = false
      },
      init() {
        let self = this
        firebaseQuery.queryUserProfile.queryPaymentMethods(self.user.data.id, (paymentMethods) => {
          self.hideOverlay()
          self.processPaymentMethods(paymentMethods)
        })
      }
    }
  }
</script>

<style scoped>
  .paymentMethodType {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .trash-bin {
    background : transparent !important;
    background-color: transparent !important;
    width: 24px !important;
    height: 24px !important;
  }
  .card-number{
    border-radius: 8px 8px 0px 0px;
  }
  .card-expiry{
    border-radius: 0px 0px 0px 8px;
  }
  .card-cvc{
    border-radius: 0px  0px 8px 0px;
  }
  img {
    width: 200px;
    height: 100px;
  }
  .savebtnColor.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    background-color:#26C6DA !important;
    color:white!important;
  }
  .wrap-text {
    -webkit-line-clamp: unset !important;
  }
  .example.example2 {
    background-color: #fff;
  }

  .example.example2 * {
    font-family: Source Code Pro, Consolas, Menlo, monospace;
    font-size: 15px;
    font-weight: 500;
  }

  .example.example2 .row {
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px 10px;
  }

  .example.example2 .field {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border: 1px solid grey;
  }

  .example.example2 .field-card-number {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
    border-width:1px 1px 1px 1px
  }

  .example.example2 .field-card-expiry {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:transparent rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
    border-width:1px 1px 1px 1px
  }

  .example.example2 .field-card-cvc {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding:8px;
    border-style:solid solid solid solid;
    border-color:transparent rgba(0,0,0,0.12) rgba(0,0,0,0.12) transparent;
    border-width:1px 1px 1px 1px
  }

  /* .example.example2 .field.half-width {
    width: 50%;
  } */

  .example.example2 .field.quarter-width {
    width: calc(25% - 10px);
  }

  /*.example.example2 .baseline {*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 1px;*/
  /*  left: 0;*/
  /*  bottom: 0;*/
  /*  background-color: #cfd7df;*/
  /*  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);*/
  /*}*/

  .example.example2 label {
    position: absolute;
    width: 90%;
    left: 4px;
    bottom: 8px;
    color: #cfd7df;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
  }

  .example.example2 .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .example.example2 .input.focused,
  .example.example2 .input:not(.empty) {
    opacity: 1;
  }

  .example.example2 .input.focused::-webkit-input-placeholder,
  .example.example2 .input:not(.empty)::-webkit-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused::-moz-placeholder,
  .example.example2 .input:not(.empty)::-moz-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused:-ms-input-placeholder,
  .example.example2 .input:not(.empty):-ms-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused + label,
  .example.example2 .input:not(.empty) + label {
    color: #aab7c4;
    transform: scale(0.75) translateY(-18px);
    cursor: default;
  }

  .example.example2 .input.focused + label {
    color: #26C6DA;
  }

  .example.example2 .input.invalid + label {
    color: #ffa27b;
  }

  .example.example2 .input.focused + label + .baseline {
    background-color: #26C6DA;
  }

  .example.example2 .input.focused.invalid + label + .baseline {
    background-color: #e25950;
  }

  .example.example2 input, .example.example2 button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .example.example2 input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .example.example2 input, .example.example2 button {
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 button {
    display: block;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #26C6DA;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
  }

  .example.example2 .error svg {
    margin-top: 0 !important;
  }

  .example.example2 .error svg .base {
    fill: #e25950;
  }

  .example.example2 .error svg .glyph {
    fill: #fff;
  }

  .example.example2 .error .message {
    color: #e25950;
  }

  .example.example2 .success .icon .border {
    stroke: #26C6DA;
  }

  .example.example2 .success .icon .checkmark {
    stroke: #24b47e;
  }

  .example.example2 .success .title {
    color: #32325d;
    font-size: 15px !important;
  }

  .example.example2 .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .example.example2 .success .reset path {
    fill: #24b47e;
  }
</style>

