<template>
  <v-card>
    <v-card-text>
      <v-card-title dark>
        Create Campaign
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeCreate()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div>
        <div class="flex-campaign">
          <div class="row">
            <div class="column">
              <v-text-field
                v-model="title"
                rows="1"
                clearable
                label="Campaign Name"
                class="lengthBox"
                counter="30"
                :maxlength="30"
                :rules="[v => v.length <= 30 || 'Max 30 characters']"
                outlined
                color="donorAccent1"
              />

              <v-textarea
                v-model="description"
                rows="1"
                auto-grow
                clearable
                counter="500"
                :maxlength="500"
                :rules="[v => v.length <= 500 || 'Max 500 characters']"
                label="Description"
                class="lengthBox"
                outlined
                color="donorAccent1"
              />

              <v-textarea
                v-model="donorReceiptInfo"
                rows="1"
                auto-grow
                clearable
                :maxlength="500"
                counter="500"
                label="Donor Receipt Info"
                class="lengthBox"
                outlined
                color="donorAccent1"
              />
            </div>
            <div class="column">
              <div>
                <div
                  class="subheading mb-1"
                >
                  Campaign Logo
                </div>
                <img
                  :src="picUrl"
                >
                <v-text-field
                  v-model="uploadFileName"
                  label="Upload Image"
                  prepend-icon="mdi-camera"
                  color="donorAccent1"
                  @click="pickFile"
                />
                <input
                  ref="image"
                  type="file"
                  style="display: none"
                  accept="image/*"
                  @change="onFileSelect"
                >
                <v-btn
                  :disabled="uploadFileName===null"
                  :loading="loaderUpload"
                  color="donorAccent1 white--text"
                  @click="updateImage"
                >
                  Upload
                </v-btn>
                <v-btn
                  class="ma-2"
                  :disabled="picUrl===''"
                  color="donorAccent1 white--text"
                  :loading="loaderRemove"
                  @click="deleteImage"
                >
                  Remove
                </v-btn>
                <ImageCropper
                  ref="cropper"
                  @cropImage="cropImage"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <v-menu
                v-model="menu_cam_start"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-textarea
                    v-model="startDate"
                    rows="1"
                    prepend-inner-icon="event"
                    readonly
                    label="Start Date"
                    color="donorAccent1"
                    outlined
                    class="lengthBox"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu_cam_start=false"
                />
              </v-menu>
            </div>
            <div class="column">
              <v-menu
                v-model="menu_cam_end"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="150px"
              >
                <template v-slot:activator="{ on }">
                  <v-textarea
                    v-model="endDate"
                    rows="1"
                    label="End Date"
                    outlined
                    color="donorAccent1"
                    prepend-inner-icon="event"
                    class="lengthBox"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu_cam_end = false"
                />
              </v-menu>
            </div>
          </div>
        </div>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="donorAccent1"
                :loading="loaderSave"
                :disabled="loaderSave"
                dark
                @click="createCampaign"
              >
                Save
              </v-btn>
              <v-dialog
                v-model="dialog"
                max-width="290"
              >
                <v-card>
                  <v-card-title class="headline">
                    Error
                  </v-card-title>
                  <v-card-text>Failed to save, please try again</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="donorAccent1"
                      text
                      @click="dialog = false"
                    >
                      DISMISS
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters} from "vuex";
  import fileStorage from "../store/firebase/fileStorage";

  import firebaseCreate from "@/store/firebase/firebaseCreate";
  import ImageCropper from "./ImageCropperPopup";
  export default {
    name: "CreateNewCampaign",
    components: {
      ImageCropper
    },
    data: function() {
      return {
        title: "",
        picUrl: "",
        description: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date(new Date().getTime() + 31536000000).toISOString().substr(0, 10),
        menu_cam_start: false,
        menu_cam_end: false,
        donorReceiptInfo: null,
        loaderUpload: false,
        loaderSave: false,
        loaderRemove: false,
        oldDeleteImg: "",
        uploadFileName: null,
        imageUrl: null,
        dialog: false,
      };
    },
    computed: {
      ...mapGetters({
        user: "user",
      }),
    },
    methods: {
      showSnackbar(text, color){
        this.$store.dispatch('pushSnackbarInfo', {text: text, color: color, isShow: true})
      },
      closeCreate() {
        if (this.picUrl.length >0) {
          this.deleteImage();
        }

        this.$emit('click');
      },
      pickFile () {
        this.$refs.image.click ()
      },
      onFileSelect(event) {
        // this.imageUrl = event;
        if (this.picUrl){
          this.oldDeleteImg = this.picUrl;
        }
        let self = this
        let input = event.target
        if (input.files && input.files[0]) {
          let reader = new FileReader()
          reader.onload = e => {
            let uploadedImage = new Image();
            uploadedImage.src = e.target.result;
            uploadedImage.onload = () => {
              self.$refs.cropper.show(e.target.result, input.files[0].name, true)
            }
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      cropImage(data) {
        let self = this
        self.loader = !self.loader;
        fileStorage.uploadImageByBlobUrl(data.blob, data.imageName, function (error, result) {
          if(error){
            self.showSnackbar(error.message, "error")
          } else {
            console.log("this is cropImage " + result)
            self.picUrl = result;
            self.$refs.cropper.show(null, "", false)
            if (self.oldDeleteImg && self.oldDeleteImg.length > 0){
              self.removeImage(self.oldDeleteImg);
              self.oldDeleteImg = "";
            }
          }
        })
      },
      updateImage() {
        if (this.imageUrl.length === 0) {
          return;
        }
        this.loaderUpFile =!this.loaderUpFile;
        let self = this;
        fileStorage.uploadImage(this.imageUrl, (error, url)=>{
          if(error) {
            console.log(error.message);
          } else {
            self.picUrl = url;
            if (self.oldDeleteImg && self.oldDeleteImg.length > 0){
              self.removeImage(self.oldDeleteImg);
              self.oldDeleteImg = "";
            }
          }
          self.loaderUpFile =!self.loaderUpFile;
        })
      },
      deleteImage() {
        this.loaderRemove =! this.loaderRemove;
        this.removeImage(this.picUrl);
        this.imageUrl = null;
        this.picUrl = "";
        this.uploadFileName = null;
        this.oldDeleteImg = "";
        this.loaderRemove =! this.loaderRemove;
      },
      removeImage(url) {
        let self = this;
        fileStorage.removeImage(url,(error,result)=>{
          if(error){
            console.log(`this is removeImage image delete error occurred: ${error.message}`)
            self.showSnackbar("image delete error occurred: " + error.message, "error")
          } else {
            console.log("this is remove Image result:" + result.message)
            self.updateImgUrl("");
          }
        })
      },

      createCampaign() {
        this.loaderSave = !this.loaderSave;

        if (this.donorReceiptInfo != null && this.donorReceiptInfo.length === 0){
          this.userProfile.donorReceiptInfo = null;
        }
        let camp = {
          title: this.title,
          imageUrl: this.picUrl,
          description: this.description,
          donorReceiptInfo: this.donorReceiptInfo,
          startdate: this.startDate,
          endDate: this.endDate
        };
        firebaseCreate.campaign.addCampaign(this.user.data.id, camp, (id)=>{
          console.log("this is create Campaign " + id)
          this.$emit('click', id);
        })
      }
    },
  }
</script>

<style scoped>
  img {
    height: 100px;
  }
  .lengthBox{
    max-width: 86%;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

  }
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding-left:10px;
    margin-left:15px;
  }
  .second-row {
    height: 100px;
  }

  @media screen and (min-width: 800px) {
    .column {
      flex: 1
    }
  }
</style>
