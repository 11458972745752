<template>
  <v-container class="pa-0">
    <v-card-title
      style="word-break: normal"
      :class="organisationTitleStyle"
    >
      Thanks for Supporting
    </v-card-title>
    <v-img
      :src="imgUrl"
      contain
    />
    <v-divider />
    <v-layout
      align-center
      align-content-center
      justify-center
      text-center
      column
      class="pt-6"
    >
      <v-card-text
        class="font-weight-bold pt-12"
        dark
      >
        Please rate Giv2
      </v-card-text>
      <v-rating
        v-model="rating"
        class="pt-0 pb-0"
        background-color="donorAccent1 white--text"
        color="donorAccent1 white--text"
        size="35"
      />
      
      <v-textarea
        v-model="feedback"
        class="pt-1 pb-0"
        rows="2"
        type="text"
        outlined
        auto-grow
        clearable
        label="Please give us feedback."
        color="donorAccent1"
        style="width: 70vw;"
      />
      <v-col>
        <v-btn
          class="my-2 pb-0"
          color="donorAccent1 white--text"
          @click="done"
        >
          DONE
        </v-btn>
      </v-col>
    </v-layout>
    <Popup
      ref="ThanksPopup"
      @goToProfilePage="goToProfilePage"
      @goToQrScannerPage="goToQrScannerPage"
      @goToMainPage="goToMainPage"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app';
import firebaseQuery from "../../store/firebase/firebaseQuery";
import {collectionPointFields} from "../../constant/firebaseConstants";
import {punctuation} from "../../constant/punctuationConstant";
import Popup from "../../components/Popup";
import localStorage from "../../helpers/localStorage";
export default {
  components: {
    Popup
  },
  props: {
    id: {
      type: String,
      default: () => {
        return {
          id: '',
        }
      }
    }
  },
  data() {
    return {
      rating: 0,
      feedback : "",
      imgUrl: "",
      defaultImage: require('@/assets/charity_jar.jpg'),
      transactionId : "",
      qrCode : "",
      organisationTitleStyle : "justify-center text-center grey lighten-4 blue-grey--text py-1 px-2 title",
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      queryCollectionPoint: 'queryCollectionPoint',
      queryClient: 'queryClient'
    })
  },
  mounted() {
    this.initTransactionIdAndQrCode()
    this.queryImageUrlAndShowImage()
    localStorage.setUnAuthenticatedInfo(null)
  },
  destroyed: function () {
    if(this.rating <= 0 && this.feedback.length <= 0)
    {
      this.$analytics.logEvent("select_item")
    }
  },
  methods: {
    goToQrScannerPage() {
    //  left button
      this.$router.push(`/donor/qrScanner`)
    },
    goToProfilePage() {
    //  right button
      this.$router.push(`/donor/profile`)
    },
    goToMainPage() {
    //  exit button
      let addr = this.queryClient.websiteUrl
      if (addr === undefined || addr == null || addr === ""){
        addr = "http://www.giv2.co.nz"
      }
      
      if(addr.substr(0,7).toLowerCase() !== "http://" && addr.substr(0,8).toLowerCase() !== "https://"){
        addr = "http://" + addr;
      }
      window.location.href = addr
    },
    initTransactionIdAndQrCode() {
      let array = this.id.split(punctuation.AND)
      this.transactionId = array[0]
      this.qrCode = array[1]
    },
    queryImageUrlAndShowImage() {
      let self = this
      firebaseQuery.queryCollectionPoint.queryByShortCodeOrQrCode(collectionPointFields.QR_CODE, this.qrCode, (queriedInfo) => {
        if(queriedInfo) {
          self.$store.dispatch('queryCollectionPointByShortcodeOrQrCode', queriedInfo)
          if (queriedInfo.campaign.imageUrl) {
            self.imgUrl = queriedInfo.campaign.imageUrl
          }
          else if(queriedInfo.client.imageUrl) {
            self.imgUrl = queriedInfo.client.imageUrl
          } else {
            self.imgUrl = self.defaultImage
          }
        } else {
          self.imgUrl = self.defaultImage
        }
      })
    },
    done() {
      this.$analytics.logEvent("select_item", {item_list_name : "rating", value : this.rating})
      this.$analytics.logEvent("select_item", {item_list_name : "feedback", value : this.feedback})
      this.addFeedback();
      this.$refs.ThanksPopup.thanksPopupResult();
    },
    addFeedback () {
      if ( this.rating > 0 || this.feedback.length > 0) {
        let self = this
        firebase.firestore().collection("donorFeedback")
          .add({
            description: self.feedback,
            starRating: self.rating,
            transactionId: self.transactionId
          })
          .catch(function (error) {
            console.error("Error writing document:", error);
          })
      }
    }
  }
};
</script>
<style scoped>
.title {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 22px;
}
</style>