const popupConstant = {
  CANNOT_FIND_SHORTCODE_TITLE: 'NOT FOUND',
  CANNOT_FIND_SHORTCODE_TEXT: "Oops! We didn't recognise that. Please try again.",

  UNAUTHENTICATED_PAYMENT_CONFIRM_PREFIX: "You are about to give $",
  UNAUTHENTICATED_PAYMENT_CONFIRM_SUFFIX: ". Do you want to continue?",
  UNAUTHENTICATED_NOTIFICATION: "Please choose the $-amount and click give. We'll create your Giv2 account and complete your donation, this only takes a minute. Next time you donate it'll take just 3 clicks.",
  BUTTON_TEXT_TRY_AGAIN: "TRY AGAIN",
  BUTTON_TEXT_NO: "NO",
  BUTTON_TEXT_YES: "YES",
  BUTTON_TEXT_OK: "OK",
  BUTTON_TEXT_CANCEL: "Go Back",
  BUTTON_TEXT_ACCEPT: "Proceed",

  THANKS_TEXT: "Thank you for using Giv2!",
  THANKS_LEFT: "Scan and donate again",
  THANKS_RIGHT: "See my recent donations",
  THANKS_EXIT: "EXIT",

  CLICK_PLATFORM_ID : "1",
  STRIPE_PLATFORM_ID : "0",

  PAYMARK_CLICK_TITLE : "Paymark Click",
  PAYMARK_CLICK_TEXT_PREFIX : "Giv2 let’s organisations choose which secure credit card processing provider to use. You’re already setup with ",
  PAYMARK_CLICK_TEXT_SUFFIX : " but this organisation uses ",
  PAYMARK_CLICK_NEW_LINE_TEXT_PREFIX : "",
  PAYMARK_CLICK_NEW_LINE_TEXT_SUFFIX : ". Let’s get you set up.",

  STRIPE_TITLE : "Stripe",
  STRIPE_TEXT_PREFIX : "Giv2 let’s organisations choose which secure credit card processing provider to use. You’re already setup with ",
  STRIPE_TEXT_SUFFIX : " but this organisation uses ",
  STRIPE_NEW_LINE_TEXT_PREFIX : "",
  STRIPE_NEW_LINE_TEXT_SUFFIX : ". Let’s get you set up.",

  TERMS_AND_CONDITION_TITLE : "Terms and Condition",
  TERMS_AND_CONDITION_TEXT : "Terms and Condition Text",
}
export default popupConstant