import firebase from 'firebase/app';
import {collections, collectionPointFields, campaignFields, userProfilesFields, termsAndConditionInfo} from "../../constant/firebaseConstants";

let firebaseUpdate = {
  updateCollectionPoint : {
    setCollectionPointIsArchived: (uid, campaignId, collectionId, callback) => {
      let collectionPoint = {}
      collectionPoint[collectionPointFields.IS_ARCHIVED] = true
      firebase.firestore()
        .collection(collections.CLIENT_PROFILES).doc(uid)
        .collection(collections.CAMPAIGNS).doc(campaignId)
        .collection(collections.COLLECTION_POINTS).doc(collectionId)
        .set(collectionPoint, {merge: true}).then(result => {
        callback(result)
      })
    }
  },
  updateCampaign : {
    setCampaignIsArchived: (uid, campaign,campaignId, callback) => {
      campaign[campaignFields.IS_ARCHIVED] = true
      firebase.firestore()
        .collection(collections.CLIENT_PROFILES).doc(uid)
        .collection(collections.CAMPAIGNS).doc(campaignId)
        .update(campaign).then(() => {
        callback()
      })
    }
  },
  updateUserProfile : {
    setIsTransactionReceiptEnabled: (uid, isTransactionReceiptEnabled, callback) => {
      firebase.firestore().collection(collections.USER_PROFILES).doc(uid).set({
        [userProfilesFields.IS_TRANSACTION_RECEIPT_ENABLED]: isTransactionReceiptEnabled
      }, {merge: true}).then(result => {
        callback(result)
      })
    },
    setIsMarketingOptIn: (uid, isMarketingOptIn, callback) => {
      firebase.firestore().collection(collections.USER_PROFILES).doc(uid).set({
        [userProfilesFields.IS_MARKETING_OPT_IN]: isMarketingOptIn
      }, {merge: true}).then(result => {
        callback(result)
      })
    },
    setTermsAndConditionAcceptInfo: (uid, callback) => {
      firebase.firestore().collection(collections.USER_PROFILES).doc(uid).set({
        [userProfilesFields.TERMS_AND_CONDITIONS]: {
          [termsAndConditionInfo.HAS_ACCEPTED_TERMS_AND_CONDITIONS] : true,
          [termsAndConditionInfo.ACCEPTED_ON] : Math.floor(Date.now() / 1000)
        }
      }, {merge: true}).then(result => {
        callback(result)
      })
    }
  }
}


export default firebaseUpdate