<template>
  <v-card class="mx-auto">
    <v-toolbar flat>
      <v-row>
        <v-col
          cols="5"
          class="pr-0"
        >
          <v-toolbar-title
            class="font-weight-bold"
            dark
          >
            My Organisation
          </v-toolbar-title>
        </v-col>

        <!--      <v-spacer />-->
        <v-col
          cols="1"
          class="pl-12"
        >
          <v-btn
            v-if="isEditing"
            icon
            small
            @click="checkClose"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            small
            @click="isEditing = !isEditing"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col
          cols="5"
          class="ml-11"
        >
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-text-field
              id="titleid"
              v-model="userProfile.title"
              :disabled="!isEditing"
              counter="50"
              :maxlength="50"
              label="Our Name"
              outlined
              color="donorAccent1"
              :rules="[v => !!v || 'This is a mandatory field.']"
            />
            <v-textarea
              v-model="userProfile.description"
              rows="1"
              auto-grow
              clearable
              :maxlength="500"
              counter="500"
              :rules="[v => (v && v.length) <= 500 || 'Max 500 characters']"
              :disabled="!isEditing"
              label="Description / Our Mission"
              class="py-2 my-4"
              outlined
              color="donorAccent1"
            />
            <v-text-field
              v-model="userProfile.charityNumber"
              class="py-2 my-4"
              :disabled="!isEditing"
              label="Charity Number"
              outlined
              color="donorAccent1"
              :rules="[v => !!v || 'This is a mandatory field.']"
            />
            <v-spacer />
            <v-text-field
              v-model="userProfile.irdNumber"
              class="py-2 my-4"
              type="number"
              :rules="[
                v => !!v || 'This is a mandatory field.',
                v => /[0-9]+([-][0-9]+)?/.test(v) || 'This is a mandatory field.',]"
              :disabled="!isEditing"
              label="IRD Number"
              outlined
              color="donorAccent1"
              required
            />
            <v-text-field
              v-model="userProfile.bankAccountName"
              class="py-2 my-4"
              :disabled="!isEditing"
              label="Bank Account Name"
              outlined
              color="donorAccent1"
              :rules="[v => !!v || 'This is a mandatory field.']"
            />
            <v-text-field
              v-model="userProfile.bankAccountNumber"
              class="py-2 my-4"
              type="number"
              outlined
              :rules="[
                v => !!v || 'This is a mandatory field.',
                v => /[0-9]+([-][0-9]+)?/.test(v) || 'This is a mandatory field.',]"
              :disabled="!isEditing"
              label="Bank Account Number"
              color="donorAccent1"
              required
            />
            <v-text-field
              v-model="userProfile.websiteUrl"
              class="py-2 my-4"
              outlined
              :rules="[
                v => /(^$)|((www|http:|https:)+[^\s]+[\w])/.test(v) || 'URL must be valid']"
              :disabled="!isEditing"
              label="Organisation Website"
              color="donorAccent1"
              required
            />
            <v-text-field
              v-model="userProfile.donateNowQrCode"
              class="py-2 my-4"
              :disabled="!isEditing"
              label="QRcode"
              outlined
              color="donorAccent1"
              
            />
            <v-text-field
              v-model="userProfile.merchantId"
              class="py-2 my-4"
              outlined
              :rules="[
                v => /(^$)|^[A-Za-z0-9]+$/.test(v) || 'Merchant ID must be valid']"
              :disabled="!isEditing"
              label="Merchant ID"
              color="donorAccent1"
            />
            <v-textarea
              v-model="userProfile.donorReceiptInfo"
              rows="1"
              auto-grow
              clearable
              :maxlength="500"
              counter="500"
              :disabled="!isEditing"
              label="Donor Receipt Info"
              class="py-2 my-4"
              outlined
              color="donorAccent1"
            />
          </v-form>
        </v-col>
        <v-spacer />
        <v-col
          cols="5"
          class="mr-11 d-flex flex-column"
        >
          <div>
            <div
              class="subheading mb-1"
              :disabled="!isEditing"
            >
              Our Logo
            </div>
            <div
              class="croppedImageDiv"
              :style="imageColumnDivStyle"
            >
              <img
                :style="imageStyle"
                :src="orgImageUrl"
              >
            </div>

            <v-text-field
              v-model="uploadFileName"
              label="Upload Image"
              prepend-icon="mdi-camera"
              color="donorAccent1"
              @click="pickFile"
            />
            <input
              ref="image"
              type="file"
              style="display: none"
              accept="image/*"
              @change="onFileSelect"
            >
            <v-btn
              :disabled="uploadFileName===null"
              :loading="loader"
              color="donorAccent1 white--text"
              @click="uploadImage()"
            >
              Upload
            </v-btn>
            <v-btn
              :disabled="userProfile.imageUrl===''"
              class="ma-2"
              color="donorAccent1 white--text"
              :loading="loader2"
              @click="removeClick()"
            >
              Remove
            </v-btn>
            <ImageCropper
              ref="cropper"
              @cropImage="cropImage"
            />
            <v-divider />
            <v-space />
            <v-list three-line>
              <template>
                <v-list-item
                  class="mb-1 mt-13"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="userProfile.isTransactionReceiptEnabled"
                      :input-value="userProfile.isTransactionReceiptEnabled"
                      color="donorAccent1"
                      :disabled="!isEditing"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-html="transTitle" />
                    <v-list-item-subtitle
                      class="wrap-text"
                      v-html="transContent"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="user.adminLoggedIn"
                  class="mb-1 mt-13"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="userProfile.isDonorTransactionReceiptOverridden"
                      :input-value="userProfile.isDonorTransactionReceiptOverridden"
                      color="donorAccent1"
                      :disabled="!isEditing"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-html="overrideTitle" />
                    <v-list-item-subtitle
                      class="wrap-text"
                      v-html="overrideContent"
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
          <div
            v-if="manageCampaignsBtnVisible"
            class="d-flex flex-column justify-center align-center pa-12"
          >
            <p>You can manage campaigns by going to the navigation -> Manage Campaign or clicking the button below.</p>
            <v-btn
              class="mt-2 mb-5"
              color="donorAccent1 white--text"
              @click="goToCampaigns()"
            >
              Manage Campaigns
            </v-btn>
          </div>
        </v-col>
        <v-spacer />
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        :loading="loader1"
        :disabled="!isEditing"
        color="donorAccent1 white--text"
        @click="save"
      >
        Save
      </v-btn>
      <v-spacer />
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Error
          </v-card-title>
          <v-card-text class="title">
            Failed to save, please try again
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="donorAccent1"
              text
              @click="dialog = false"
            >
              DISMISS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog1"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Warning
          </v-card-title>
          <v-card-text class="title">
            Do you want to delete this image ?
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="donorAccent1"
              text
              @click="dialog1 = false"
            >
              No
            </v-btn>
            <v-btn
              color="donorAccent1"
              text
              @click="deleteImage"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog2"
        max-width="320"
      >
        <v-card>
          <v-card-title class="headline">
            Warning
          </v-card-title>
          <v-card-text class="title">
            You have unsaved changes.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="donorAccent1"
              text
              @click="save()"
            >
              Save Changes
            </v-btn>
            <v-btn
              color="donorAccent1"
              text
              @click="discardChange()"
            >
              Discard
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>
<script>
  import { mapGetters } from "vuex";
  import firebase from "firebase/app";
  import fileStorage from "../../store/firebase/fileStorage";
  import ImageCropper from "../../components/ImageCropperPopup";
  import firebaseQuery from "../../store/firebase/firebaseQuery";

  export default {
    components: {
      ImageCropper
    },
    data() {
      return {
        showingUpload:false,
        showWaring:false,
        showError:false,
        loader: false,
        loader1: false,
        loader2: false,
        isEditing: null,
        dialog: false,
        dialog1:false,
        dialog2: false,
        uploadFileName: null,
        imageUrl: null,
        lazy: false,
        ifChanged: false,
        oldDeleteImg: false,
        manageCampaignsBtnVisible: false,
        transTitle: "Transaction details",
        transContent: "Receive an email with the transaction details, each time someone supports one of your campaigns.",
        imageColumnDivStyle : {
          width : '0px',
          height : '0px'
        },
        imageStyle : {
          width : '0px',
          height : '0px'
        },
        orgImageUrl: "",
        overrideTitle: "Override automatic donor receipts",
        overrideContent: "Tick this to stop Giv2 from sending donation receipts or transaction confirmations to donors."

      };
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user",
        userProfile: "userProfile",
      }),
    },

    created() {
      this.$store.dispatch("getProfile");
    },
    mounted() {
      let self = this
      firebaseQuery.queryClientProfile.queryClientImage(this.user.data.id, (userProfile) => {
        console.log(JSON.stringify(userProfile))
        if(userProfile && userProfile.imageUrl) {
          self.loadOrgImage(userProfile.imageUrl)
        }
      })
    },
    methods: {
      reload () {
        location.reload();
      },
      removeClick () {
        if (this.userProfile.imageUrl.length > 0) {
          this.dialog1 = true;
        }
      },
      backToViewing () {
        this.isEditing = !this.isEditing;
      },
      validate () {
        return this.$refs.form.validate();
      },
      diffObject: function (obj1, obj2){
        let o1 = obj1 instanceof Object;
        let o2 = obj2 instanceof Object;
        if (!o1 || !o2) {
          return obj1 === obj2;
        }
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
          return false;
        }
        for (let o in obj1) {
          let t1 = obj1[o] instanceof Object;
          let t2 = obj2[o] instanceof Object;
          if (t1 && t2) {
            return this.diffObject(obj1[o], obj2[o]);
          } else if (obj1[o] !== obj2[o]) {
            return false;
          }
        }
        return true;
      },
      checkClose () {
        firebase.firestore().collection("clientProfiles").doc(this.user.data.id)
            .get().then((data) => {
          this.ifChanged = !this.diffObject(this.userProfile, data.data());
          if (this.ifChanged) {
            this.dialog2 = !this.dialog2;
          } else {
            this.backToViewing();
          }
        })
      },
      discardChange () {
        this.dialog2 = !this.dialog2;
        this.backToViewing();
        this.reload();
      },
      showSnackbar(text, color){
        this.$store.dispatch('pushSnackbarInfo', {text: text, color: color, isShow: true})
      },
      save () {
        if (this.dialog2 === true) {
          this.dialog2 = false;
        }
        if (this.validate()) {
          this.loader1 = !this.loader1;

          if ((this.userProfile.hasOwnProperty('merchantId') && (this.userProfile.merchantId != null) && this.userProfile.merchantId.length === 0) ||
              (!this.userProfile.hasOwnProperty('merchantId'))) {
            this.userProfile.merchantId = null;
          }
          if ((this.userProfile.hasOwnProperty('donorReceiptInfo') && (this.userProfile.donorReceiptInfo != null) && this.userProfile.donorReceiptInfo.length === 0) ||
              (!this.userProfile.hasOwnProperty('donorReceiptInfo'))) {
            this.userProfile.donorReceiptInfo = null;
          }
          if ((this.userProfile.donateNowQrCode!= null) && (this.userProfile.donateNowQrCode.length === 0)){
            this.userProfile.donateNowQrCode= null;
          }
          let self = this
          this.$store.dispatch("updateProfile", this.userProfile)
              .then(() => {
                self.showSnackbar("Success. You've saved your profile", "success")
                this.loader1 = !this.loader1;
                this.manageCampaignsBtnVisible = true;
                this.backToViewing();
              })
              .catch(() => {
                self.showSnackbar("Failed to save your profile", "error")
                this.loader1 = !this.loader1;
              });
        } else {
          this.showSnackbar("Please complete the mandatory field(s)", "warning")
        }
      },
      uploadImage() {
        if (this.imageUrl.length === 0) {
          return;
        }
        this.loader = !this.loader;
        let self = this
        fileStorage.uploadImage(this.imageUrl, (error, url)=>{
          if(error){
            console.log(error.message);
            self.showSnackbar(error.message, "error")
          } else {
            self.userProfile.imageUrl = url;
            self.loader = !this.loader;
            self.updateImgUrl(url);
            //  delete old img
            if (self.oldDeleteImg && self.oldDeleteImg.length >0){
              self.removeImage2(self.oldDeleteImg);
            }
          }
        })
      },
      updateImgUrl(url){
        let self = this
        this.$store.dispatch("updateProfileLogo", url)
            .then(() => {
              if (url.length > 0) {
                self.showSnackbar("Success. You've uploaded your image", "success")
              } else {
                self.showSnackbar("Success. You've deleted your image", "success")
              }
            })
            .catch((error) => {
              self.showSnackbar(error.message, "error")
            })
      },
      deleteImage() {
        if (this.dialog1 === true) {
          this.dialog1 = false;
        }
        this.loader2 = !this.loader2;
        this.removeImage(this.userProfile.imageUrl);

        this.userProfile.imageUrl = "";
        this.imageUrl = null;
        this.uploadFileName = null;
        this.loader2 = !this.loader2;
      },
      removeImage(url) {
        let self = this
        fileStorage.removeImage(url,(error,result)=>{
          if(error){
            console.log(`this is removeImage image delete error occurred: ${error.message}`)
            self.showSnackbar("image delete error occurred: " + error.message, "error")
          } else {
            console.log("this is removeImage result " + result.message)
            self.updateImgUrl("");
          }
        })
      },
      removeImage2(url) {
        fileStorage.removeImage(url, (error, result)=>{
          if (error) {
            console.log("this is removeImage2 error " + JSON.stringify(error))
          } else {
            console.log("this is removeImage2 result " + JSON.stringify(result))
          }
        })
      },
      pickFile () {
        this.$refs.image.click ()
      },
      onFileSelect(event) {
        this.oldDeleteImg = this.userProfile.imageUrl;
        let input = event.target
        if (input.files && input.files[0]) {
          let reader = new FileReader()
          reader.onload = e => {
            let self = this
            let uploadedImage = new Image();
            uploadedImage.src = e.target.result;
            uploadedImage.onload = () => {
              self.$refs.cropper.show(e.target.result, input.files[0].name, true)
            }
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      goToCampaigns() {
        this.$router.push('/dashboard/manageCampaign');
      },
      loadOrgImage(imageUrl) {
        let self = this
        let img = new Image()
        img.src = imageUrl
        img.onload = () => {
          let imageWidth = img.width
          let imageHeight = img.height
          console.log("this is img.onload imgWidth " + imageWidth + " imgHeight " + imageHeight)
          self.orgImageUrl= imageUrl
          let newImageWidth = ""
          let newImageHeight = ""

          if (imageWidth > imageHeight) {
            newImageWidth = "200px"
            newImageHeight = imageHeight / imageWidth * 200 + "px"
          } else {
            newImageWidth = imageWidth / imageHeight * 100 + "px"
            newImageHeight = "100px"
          }

          let newDivWidth = "200px"
          let newDivHeight = "100px"

          self.imageStyle = {
            width: newImageWidth,
            height: newImageHeight
          }

          self.imageColumnDivStyle = {
            width: newDivWidth,
            height: newDivHeight
          }
        }
      },
      cropImage(data) {
        let self = this
        self.loader = !self.loader;
        fileStorage.uploadImageByBlobUrl(data.blob, data.imageName, function (error, result) {
          if(error){
            self.showSnackbar(error.message, "error")
          } else {
            self.userProfile.imageUrl = result;
            self.loadOrgImage(result)
            self.loader = !self.loader;
            self.updateImgUrl(result);
            self.$refs.cropper.show(null, "", false)
            //  delete old img
            if (self.oldDeleteImg && self.oldDeleteImg.length >0){
              self.removeImage2(self.oldDeleteImg);
            }
          }
        })
      }
    },
  };
  </script>
  <style scoped>
  img {
    height: 100px;
  }
  .croppedImageDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgrey;
  }
  </style>