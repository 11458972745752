<template>
  <div id="login-page">
    <div v-if="!isMobile">
      <div class="row">
        <div
          class="
            column
            pl-3
            pb-3
            leftside
            align-center
            justify-center
            client-ui
            fill-height
          "
        >
          <div class="row py-2">
            <div class="column py-2">
              <h1 class="text-center pa-2">Contactless / Cashless</h1>
              <h2 class="text-center">Donations to Fundraising Groups</h2>
              <div class="column pa-4 align-center justify-center">
                <div class="pa-4 ma-4 first-card card">
                  <h3 class="font-weight-bold">How it works</h3>
                  <ul v-for="(item, i) in details" :key="i">
                    <li class="pa-2">
                      {{ item.text }}
                    </li>
                  </ul>
                  <br />
                  <h3 class="font-weight-bold pa-1">
                    Anyone can donate on the spot
                  </h3>
                  <ul v-for="(item, j) in donorItems" :key="j">
                    <li class="pa-2">
                      {{ item.text }}
                    </li>
                  </ul>
                  <v-card-text class="font-weight-bold text-center">
                    (with an existing Giv2 profile a donation takes only
                    3-clicks)
                  </v-card-text>
                  <div class="column">
                    <div class="center py-2 image-position">
                      <img
                        src="./../assets/Try Giv2 Demo.png"
                        class="center image-card"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            column
            rightside
            justify-center
            align-center
            fill-height
            bg-image
          "
          :style="`background-image: url(${require('./../assets/charity_jar_2.png')})`"
        >
          <div class="row">
            <div class="column">
              <div
                class="logo-card mt-3 logo-position justify-center align-center"
              >
                <img src="./../assets/logo.png" class="center pa-3" />
              </div>
              <div class="column align-center justify-center">
                <div
                  v-show="!isSiteLive"
                  class="pa-4 ma-6 card card-length justify-center align-center"
                >
                  <h3 class="font-weight-bold">Request a Giv2 account</h3>
                  <h3 class="font-weight-light pa-2 ma-4">
                    Contact us to get your organisation setup <br />- Get your
                    unique Giv2-QR code(s)
                  </h3>

                  <v-btn class="donorAccent1 getRequest">
                    <a
                      class="white--text textDecoration"
                      href="https://www.giv2.co.nz/request-an-account"
                    >
                      Request your account</a
                    >
                  </v-btn>
                </div>
                <div v-show="isSiteLive" class="pa-4 ma-6">
                  <section id="firebaseui-auth-container" class="py-12 my-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-container
      v-if="isMobile"
      id="body"
      fluid
      class="fill-height stripe containerLength"
    >
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="8" md="4">
          <div class="logo-card logo-position centre">
            <img src="./../assets/logo.png" class="center pa-3" />
          </div>

          <h3 class="justify-center white--text py-4">
            Sign in once<br />Donate anywhere in 3 clicks
          </h3>

          <section id="firebaseui-auth-container" class="bgSection" />

          <div class="text-center">
            <h3
              class="termColour"
              style="text-decoration: underline"
              @click="snackbar = true"
            >
              What is Giv2?
            </h3>

            <v-snackbar
              v-model="snackbar"
              centered="true"
              :multi-line="multiLine"
              :vertical="vertical"
              :timeout="timeout"
              color="white grey--text"
            >
              <li
                class="pa-0"
                style="list-style: none; text-align: center; font-weight: bold"
              >
                {{ snackbarText1 }}
              </li>
              <li class="pa-0" style="list-style: none; text-align: center">
                {{ snackbarText2 }}
              </li>
              <li class="pa-5" style="list-style: none; text-align: center">
                {{ snackbarText3 }}
              </li>
              <li class="pa-0" style="list-style: none; text-align: center">
                {{ snackbarText4 }}
              </li>
              <div class="text-center">
                <p>
                  Learn more:
                  <a href="https://www.giv2.co.nz" target="_blank"
                    >giv2.co.nz</a
                  >
                </p>
              </div>

              <template v-slot:action="{ attrs }">
                <v-btn
                  class="close_button"
                  color="cyan lighten-1 white--text"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <h3 class="text-center center py-2">
            Cashless / Contactless
            <br />Donations to Fundraising Groups
          </h3>
          <p class="textSize font-weight-light text-center">
            By continuing you agree to our <br />
            <a
              class="termsText"
              href="https://www.giv2.co.nz/terms"
              target="_blank"
              >Terms and Conditions</a
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { isMobile } from "mobile-device-detect";
import { snackbarConstant } from "../constant/snackbarConstant";
import localStorage from "../helpers/localStorage";

export default {
  name: "Login",
  data() {
    return {
      contactFormData: {
        name: "",
        email: "",
        phone: "",
      },
      timeout: 9000,
      success: false,
      unAuthenticatedInfo: null,
      isMobile: isMobile,
      isSiteLive: firebase.remoteConfig().getBoolean("isSiteLive"),
      email: "",
      password: "",
      error: "",
      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },
      donorItems: [
        {
          icon: "mdi-cellphone",
          text: "www.Giv2.net",
        },
        {
          icon: "mdi-qrcode",
          text: "Scan the QR code",
        },

        {
          icon: "mdi-numeric-3-circle",
          text: "Land on your campaign page",
        },
        {
          icon: "mdi-credit-card",
          text: "Donate from a Credit Card",
        },
      ],
      loginDetails: [
        { text: "Secure donations whenever you see a Giv2-QR code" },
        { text: "All your giving tracked in one place" },
        { text: "Donate in just 3 clicks" },
      ],
      items: [
        { text: "Login" },
        {
          text: "Create your organisation",
        },
        {
          text: "Create a campaign & collection point QR",
        },
      ],
      details: [
        { text: "Add a Giv2-QR code to any of your fundraising material" },
        {
          text: "Everything becomes a giving station (flyers, posters, emails, website, TV ads, etc.)",
        },
      ],
      giv2: [
        { text: "One account that lets you donate to any fundraising group" },
        { text: "Secure and lets you see all your giving in one place" },
        { text: "Donate in just 3 clicks" },
      ],
      snackbar: false,
      multiLine: true,
      snackbarTexts: [
        {
          text: "Create your Giv2 account once – then donate anywhere in 3 clicks. No more online forms. Just: Scan, Choose $-amount, and click Give. All your donations in one place. We keep your details secure.",
        },
      ],
      snackbarText1: "Giv2 is NZ's QR code donation system.",
      snackbarText2:
        "Sign in to create your secure account once - then donate to any fundraising group with a Giv2 QR code in just 3 clicks:",
      snackbarText3: "SCAN, CHOOSE $-amount, and click GIVE.",
      snackbarText4:
        "No more online donations forms. All your donations in one secure place.",
      text: "Donation Done",
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    formIsValid() {
      return (
        this.contactFormData.name !== "" &&
        this.contactFormData.email !== "" &&
        this.contactFormData.phone !== ""
      );
    },
  },
  created() {
    this.unAuthenticatedInfo = localStorage.getUnAuthenticatedInfo();
    if (this.unAuthenticatedInfo) {
      this.isMobile = true;
    }
    if (this.isSiteLive) return;
    firebase
      .remoteConfig()
      .fetchAndActivate()
      .then(() => {
        this.isSiteLive = firebase.remoteConfig().getBoolean("isSiteLive");
      })
      .catch((err) => {
        console.error(err);
      });
  },
  mounted() {
    if (this.user.loggedIn === true) {
      if (this.isMobile) {
        this.$router.push("/donor/qrScanner");
      } else {
        this.$router.push("/dashboard/clientProfile");
      }
    } else {
      if (this.isMobile) {
        let uiConfigMobile = {
          signInSuccessUrl: "/donor/qrScanner",
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
          ],
        };

        let uiMobile = new firebaseui.auth.AuthUI(firebase.auth());
        if (this.unAuthenticatedInfo) {
          let showUnAuthenticatedSnackbar =
            localStorage.getIsShowUnAuthenticatedSnackbar();
          if (!showUnAuthenticatedSnackbar) {
            localStorage.setIsShowUnAuthenticatedSnackbar();
            this.$store.dispatch("pushSnackbarInfo", {
              text: snackbarConstant.message.UNAUTHENTICATED_SIGN_IN,
              color: snackbarConstant.color.WARNING,
              isShow: true,
            });
          }
          uiConfigMobile.signInSuccessUrl = `/donor/result/${this.unAuthenticatedInfo.qrCode}`;
        }
        uiMobile.start("#firebaseui-auth-container", uiConfigMobile);
      } else {
        let uiConfig = {
          signInSuccessUrl: "/dashboard/clientProfile",
          signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          ],
        };
        let ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);
      }
    }
  },
  show() {
    this.snackbar = true;
  },
};
</script>

<style scoped>
#login-page {
  background-color: white;
}
.bgSection {
  background-color: white;
  margin-top: 15px;
  max-width: 245px;
  padding-top: 5px;
}
.containerLength {
  height: fit-content;
}

.card-length {
  max-width: 400px;
  width: 80%;
}
h4 {
  white-space: normal;
  word-break: normal;
}
h3 {
  text-align: center;
}
h1 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: auto;
}
.content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.qrDisplay {
  margin: 10px 30px;
  height: 70px;
}
.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: fit-content;
}
.borderStyle {
  border-style: solid;
  border-color: #26c6da;
}

.group {
  display: flex;
  flex: 1;
  justify-content: space-around;
  width: 50%;
}
#icon-card {
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 15px;
  width: 50%;
  min-width: 250px;
}
.mld.firebaseui-card-content.firebaseui-card-content,
.firebaseui-card-footer {
  padding: 0;
}
#firebaseui-auth-container {
  width: 80%;
  margin: auto;
  min-width: 250px;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.leftside,
.rightside {
  height: 100vh;
  width: 100%;
}
.leftside {
  height: 100vh;
  width: 100%;
  min-width: 350px;
}

.rightside {
  height: 100vh;
  width: 100%;
}
.card {
  background-color: white;
  border-radius: 1em;
  min-width: 200px;
  margin: auto;
  padding: auto;
}
.image-card {
  height: 100px;
}

.stripe {
  border-color: white transparent transparent transparent;
  background: linear-gradient(15deg, white 45%, #26c6da 30%);
}
.client-ui {
  background: linear-gradient(to top, #e0f7fa 40%, #26c6da 90%);
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: auto;
  position: relative;
}
.first-card {
  padding-left: 40px;
  max-width: 90%;
}
@media screen and (min-width: 600px) {
  .column {
    flex: 1;
  }
  .rightside {
    height: 100vh;
  }
}

.logo-card {
  border-radius: 1em;
  background-color: white;
  min-width: 100px;
  max-width: 100px;
  width: 23%;
}
.border {
  border: 1px solid #fb8c00;
  max-height: 50px;
  margin: 5px 20px;
  padding: 10px;
  border-radius: 0.5em;
  width: 90%;
}
.icon-height {
  width: 40px;
  height: 40px;
}
.icon-group {
  display: flex;
  justify-content: center;
}
.getRequest {
  width: 100%;
}

.image-position {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-text-field {
  width: 80%;
}
.logo-position {
  padding: auto;
  margin: auto;
}
.textDecoration {
  text-decoration: none;
}
.close_button {
  position: absolute;
  bottom: 0vh;
  right: 0.5vh;
}
.termColour {
  color: #26c6da !important;
}
</style>