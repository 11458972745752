import Vue from 'vue'
import Vuex from 'vuex'
import { isMobile } from 'mobile-device-detect';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  computed: {

  },
  state: {
    user: {
      loggedIn: false,
      adminLoggedIn: false,
      data: null
    },
    campaigns: [],
    campaign: {},
    profile: [],
    queryCollectionPoint: {},
    queryCampaign: {},
    queryClient: {},
    snackbarList: [],
    organisations: [],
    favourites: [],
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAdmin(state) {
      return state.user.adminLoggedIn;
    },
    campaign(state) {
      return state.campaign;
    },
    userProfile(state) {
      return state.profile || {};
    },
    organisations(state) {
      return state.organisations;
    },
    queryCollectionPoint(state) {
      return state.queryCollectionPoint;
    },
    queryCampaign(state) {
      return state.queryCampaign;
    },
    queryClient(state) {
      return state.queryClient;
    },
    campaigns(state) {
      return state.campaigns
    },
    snackbarList(state) {
      return state.snackbarList
    }
  },
  mutations: {
    // add mutations needed to keep your store state in
    // sync with the remote database
    ...vuexfireMutations,
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_ADMIN_LOGGED_IN(state, value) {
      state.user.adminLoggedIn = value
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    getProfile(state, payload) {
      state.profile = payload;
    },
    organisations(state, payload) {
      state.organisations = payload;
    },
    queryCollectionPoint(state, payload) {
      state.queryCollectionPoint = payload;
    },
    queryCampaign(state, payload) {
      state.queryCampaign = payload;
    },
    queryClient(state, payload) {
      state.queryClient = payload;
    },
    campaigns(state, payload) {
      state.campaigns = payload
    },
    setCampaign(state, payload) {
      state.campaign = payload
    },
    pushSnackInfo(state, payload) {
      state.snackbarList = payload
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_ADMIN_LOGGED_IN", user.isAdmin);
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          id: user.uid,
          photoUrl: user.photoURL,
        });
        const userProfile = {
          uid: user.uid,
        };
        if (isMobile === false) {
          firebase.firestore().collection("clientProfiles").doc(user.uid)
            .set(userProfile, { merge: true });
        } else {
          firebase.firestore().collection("userProfiles").doc(user.uid)
            .set(userProfile, { merge: true });
        }
      } else {
        commit("SET_USER", null);
        commit("SET_ADMIN_LOGGED_IN", false);
      }
    },
    campaigns({ commit, getters }) {
      commit("campaigns", []);
      let ref = firebase.firestore().collection(`clientProfiles`)
        .doc(getters.user.data.id).collection("campaigns");

      ref.onSnapshot(function (querySnapshot) {
        let campaigns = [];
        querySnapshot.forEach(function (doc) {
          const campaign = {
            campaign: doc.data(),
            collection: [],
            id: doc.id
          };
          if (!campaign.campaign.isArchived) {
            campaigns.push(campaign);
          }
          ref.doc(doc.id).collection("collectionPoints").onSnapshot(function (querySnapshot) {
            let collectionPoints = [];
            querySnapshot.forEach(function (point) {
              const data = {
                ...point.data(),
                id: point.id,
              };
              if (!data.isArchived) {
                if (data.paymentType === 0) {
                  data.paymentDes = "Donation"
                } else if (data.paymentType === 1) {
                  data.paymentDes = "Contribution"
                }
                collectionPoints.push(data)
              }
            });
            campaign.collection = collectionPoints;
          });
        });
        commit("campaigns", campaigns);
      });
    },
    campaign({ commit, getters }, documentID) {
      let ref =
        firebase.firestore().collection(`clientProfiles`)
          .doc(getters.user.data.id).collection("campaigns").doc(documentID)
      ref.get().then((doc) => {
        let collectionPoints = [];
        ref.collection("collectionPoints").onSnapshot(function (querySnapshot) {
          querySnapshot.forEach(function (point) {
            const data = {
              ...point.data(),
              id: point.id,
            };
            if (!data.isArchived) {
              collectionPoints.push(data)
            }
          });
        });
        const campaign = {
          campaign: doc.data(),
          collection: collectionPoints,
          id: doc.id,
        }
        commit("setCampaign", campaign);
      })
    },
    async updateCampaign({ getters }, data) {
      const ref = firebase.firestore()
        .collection(`clientProfiles`).doc(getters.user.data.id)
        .collection("campaigns").doc(data.id);
      
      try {
        await ref.update(data.campaign);
      } catch (error) {
        console.log('--Error updating the campaign', error);
      }

      data.collection.forEach(collection => {
        delete collection["paymentDes"]//Avoid field paymentDes be written into firebase
        if (collection.id) { // updates an existent collection point
          const { isActive, activePeriods } = collection;
          if (!activePeriods) { // in case of old versions of collection point objects
            console.log('Field activePeriods does not exist, and will be created now.');
            collection.activePeriods = isActive ? [new Date().getTime().toString()] : [];
          } else {
            const lastIndex = activePeriods.length - 1;
            const lastPeriod = activePeriods[lastIndex];
            const isActiveNow = lastPeriod && !lastPeriod.includes('-'); // if the last element contains only one timestamp, it means the cp is active; if two (sep. by '-') or nothing in active periods, then inactive;
            const newTimestamp = new Date().getTime().toString();
            if (!isActiveNow && isActive) activePeriods.push(newTimestamp);
            if (isActiveNow && !isActive) activePeriods[lastIndex] = `${lastPeriod}-${newTimestamp}`;
          }

          ref.collection("collectionPoints").doc(collection.id)
            .set(collection)
            .catch(error => console.log('--Error updating the collection point--', error));
        } else { // creates a new collection point
          collection.activePeriods = collection.isActive ? [new Date().getTime().toString()] : [];

          ref.collection("collectionPoints")
            .add(collection)
            .then((collection) => {
              const qrCode = { qrCode: `${getters.user.data.id}-${data.id}-${collection.id}`, id: collection.id};
              ref.collection("collectionPoints").doc(collection.id)
                .update(qrCode)
                .catch(error => console.log('--Error updating the QR code--', error));
            })
            .catch(error => console.log('--Error creating the collection point--', error));
        }
      })
    },
    //data contain campaignId and new collectionPoints
    updateCollectionPointsByCampaignId({commit, getters }, data){
      let campaigns = getters.campaigns || []
      if (campaigns.length <= 0) {
        return
      }

      let campaignId = data.campaignId
      let collectionPoints = data.collectionPoints
      for (let campaign in campaigns) {
        if (campaign.id === campaignId) {
          campaign.collection = collectionPoints
          break
        }
      }
      commit("campaigns", campaigns)
    },
    updateCampaignLogo: firestoreAction(({getters }, data) => {
      let imgUrl = {imageUrl: data.imgUrl};
      firebase.firestore().collection(`clientProfiles`)
        .doc(getters.user.data.id).collection("campaigns").doc(data.id)
        .update(imgUrl)
        .then(
        );
    }),
    updateProfileLogo: firestoreAction(({getters }, data) => {
      const imgUrl = {imageUrl: data};
      firebase.firestore().collection('clientProfiles').doc(getters.user.data.id)
        .update(imgUrl)
    }),

    updateProfile: firestoreAction(({ getters }, data) => {
      firebase.firestore().collection('clientProfiles')
        .doc(getters.user.data.id)
        .update(data)
    }),
    getProfile: firestoreAction(({ bindFirestoreRef, getters }) => {
      return bindFirestoreRef(
        'profile', firebase.firestore().collection('clientProfiles').doc(getters.user.data.id)
      )
    }),

    organisations({commit}) {
      commit("organisations", []);

      let orgRef = firebase.firestore().collection('clientProfiles')

      orgRef.onSnapshot(function (querySnapshot) {
        let organisations = [];
        querySnapshot.forEach(function (doc) {
          if (doc.data().title && doc.data().title.trim()){
            const organisation = {
              title: doc.data().title,
              clientId: doc.id,
            };
            organisations.push(organisation);
          }
        });
        commit("organisations", organisations);
      })
    },

    createFavourite({ getters }, payload) {
      const favourite = {
        clientId: payload.clientId,
        donorNumber: payload.donorNumber,
      };
      firebase.firestore().collection("userProfiles").doc(getters.user.data.id)
        .collection("favouriteClients")
        .add(favourite)
        .catch((error) => {
          console.log('-- Error creating a new favourite organisation', error);
        });
    },

    deleteFavourite({ getters }, data) {
     firebase.firestore().collection(`userProfiles`)
        .doc(getters.user.data.id).collection("favouriteClients").doc(data.favouriteId)
        .delete().then(() => {
          console.log("delete favourite ")
        }).catch((error) => {
          console.log(error)
        })
    },

    updateFavourite({ getters }, data) {
      firebase.firestore().collection(`userProfiles`)
        .doc(getters.user.data.id).collection("favouriteClients").doc(data.favouriteId)
        .update({donorNumber: data.donorNumber});
    },

    pushSnackbarInfo({commit, getters}, snackbarInfo) {
      let list = getters.snackbarList
      list.push(snackbarInfo)
      commit("pushSnackbarInfo", list)
    },
    queryCollectionPointByShortcodeOrQrCode({commit}, object) {
      commit("queryClient", object.client);
      commit("queryCampaign", object.campaign);
      commit("queryCollectionPoint", object.collectionPoint);
    }
  },
  plugins: [createPersistedState()]
})
