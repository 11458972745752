<template>
  <v-app id="app">
    <Snackbar/>

    <main>
      <router-view />
    </main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
export default {
  name: "App",
  components: {Snackbar}
};
</script>

<style>
  #app {
    font-family: 'Roboto', sans-serif !important;
    background-color: white !important;

  }

  .header {
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    font-weight: bold;
    color: #546E7A !important;;
  }

  .styled-text label {
    font-family: 'Roboto', sans-serif !important;
    font-size: 22px !important;
    font-weight: bold;
    color: #546E7A !important;

  }

  .style-text {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    color: #263238;
  }

  .input-text label {
    color: #263238 !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;

  }

  .card-title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px !important;
    color: #546E7A !important;
    font-weight: bold !important;
  }

  .card-subtitle {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    color: #263238;
  }
  .firebaseui-card-content, .firebaseui-card-footer {
    padding: 0;   
}
    .ul.firebaseui-idp-list{
      padding: 10px;  
    }
    .v-application section ol, .v-application  section ul {
    padding: 10px;
    }
.firebaseui-idp-list, .firebaseui-tenant-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item {
    margin-bottom: 10px;
    text-align: center;
}
.termsText{
  color: #26C6DA !important;
  font-size:12px;
}
.textSize{
  font-size:12px;
}


</style>
