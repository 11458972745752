import firebase from 'firebase/app';
import {collections} from "../../constant/firebaseConstants";
import {punctuation} from "../../constant/punctuationConstant";

let analytics = {
  isClientIdValidInQrCode: (qrCode, callback) => {
    let ids = qrCode.split(punctuation.HYPHEN)
    const path = ids[0]
    firebase.firestore().collection(collections.CLIENT_PROFILES).doc(path).get().then(doc => {
      let result = {
        isValid : doc.data() ? true : false,
        clientId : ids[0]
      }
      callback(result)
    })
  },
  isCampaignIdValidInQrCode: (qrCode, callback) => {
    let ids = qrCode.split(punctuation.HYPHEN)
    const path = ids[0] + punctuation.SLASH + collections.CAMPAIGNS + punctuation.SLASH + ids[1]
    firebase.firestore().collection(collections.CLIENT_PROFILES).doc(path).get().then(doc => {
      let result = {
        isValid : doc.data() ? true : false,
        campaignId : ids[1]
      }
      callback(result)
    })
  },
  isCollectionPointIdValidInQrCode: (qrCode, callback) => {
    let ids = qrCode.split(punctuation.HYPHEN)
    const path = ids[0] + punctuation.SLASH + collections.CAMPAIGNS + punctuation.SLASH + ids[1] + punctuation.SLASH + collections.COLLECTION_POINTS + punctuation.SLASH + ids[2]
    firebase.firestore().collection(collections.CLIENT_PROFILES).doc(path).get().then(doc => {
      let result = {
        isValid : doc.data() ? true : false,
        collectionPointId : ids[2]
      }
      callback(result)
    })
  }
}
export default analytics